import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../PaymentForm/PaymentForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY_TEST);

export default function Payment({ listingType }) {
  const [clientSecret, setClientSecret] = useState('');
  const [amount, setAmount] = useState('');

  useEffect(() => {
    const FetchPaymentIntent = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-payment-intent`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ listingType }),
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setClientSecret(data.clientSecret);   
          switch (data.paymentAmount) {
            case 25000:
              setAmount('$250');
              break;
            case 87500:
              setAmount('$875');
              break;
            case 9999:
              setAmount('$99.99');
              break;
            default:
              // console.log('No Amount Selected')
              break;
          }
        } else {
          throw new Error('Failed to fetch payment intent');
        }
      } catch (error) {
        console.error('Error fetching payment intent:', error);
      }
    };

    FetchPaymentIntent();
  }, [listingType]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentForm clientSecret={clientSecret} listingType={listingType} amount={amount} />
        </Elements>
      )}
    </>
  );
}
