import React, { useState, useEffect } from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import ReactSlider from 'react-slider';
import { SetupPage2Image, SingleFamilyIcon, CondoIcon, ApartmentIcon, TownhouseIcon, ProfileArrow } from '../../assets/icons/icons';
import './priceRangeSlider.css';

// Progress Bar
const BuyerSetupProgressBar = ({ currentStep }) => {
    const totalSteps = 10;  // Adjusted based on the number of setup pages
    return (
        <div className='buyersetup-dash-container'>
            {[...Array(totalSteps)].map((_, index) => (
                <div
                    key={index}
                    className={index > currentStep ? 'buyersetup-dash-false' : 'buyersetup-dash-true'}
                ></div>
            ))}
        </div>
    );
};

// Setup Page 1
function SetupPage1({ formData, setFormData, onSubmit }) {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleNext = (event) => {
        event.preventDefault();
        onSubmit();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page1-title'>What area do you want to live in?</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <h2 className='setup-input-title'>City</h2>
                    <input type="text" name="city" maxLength={50} value={formData.city} onChange={handleChange} placeholder="City" className="setup-input" required />
                    <h2 className='setup-input-title'>State (ex: TX)</h2>
                    <input type="text" name="state" maxLength={2} value={formData.state} onChange={handleChange} placeholder="State" className="setup-input" required />
                    <h2 className='setup-input-title'>ZIP Code</h2>
                    <input type="number" name="zipcode" min={0} max={99999} maxLength={5} value={formData.zipcode} onChange={handleChange} placeholder="ZIP Code" className="setup-input-number" required />
                    <div className='setup-page-button-container'>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

// Setup Page 2
function SetupPage2({ formData, setFormData, onSubmit, goBack }) {
    const [selectedStatus, setSelectedStatus] = useState(formData.listing_status || '');

    const handleStatusChange = (status) => {
        setSelectedStatus(status);
    };

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            listing_status: selectedStatus
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page2-title'>Are you looking to buy or rent?</h1>
                <form onSubmit={handleNext} className='setup-page-form-listing-type'>
                    <div className='setup-page-status-container'>
                        <button
                            type="button"
                            className={`setup-status-btn ${selectedStatus === 'for_sale' ? 'active' : ''}`}
                            onClick={() => handleStatusChange('for_sale')}
                        >
                            Buying
                        </button>
                        <button
                            type="button"
                            className={`setup-status-btn ${selectedStatus === 'for_rent' ? 'active' : ''}`}
                            onClick={() => handleStatusChange('for_rent')}
                        >
                            Renting
                        </button>
                    </div>
                    <SetupPage2Image/>
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

// Setup Page 3
function SetupPage3({ formData, setFormData, onSubmit, goBack }) {
    const [selectedPropertyTypes, setSelectedPropertyTypes] = useState(formData.property_type || []);

    const handlePropertyTypeChange = (value) => {
        setSelectedPropertyTypes(prevSelected => {
            if (prevSelected.includes(value)) {
                return prevSelected.filter(type => type !== value);
            } else {
                return [...prevSelected, value];
            }
        });
    };

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            property_type: selectedPropertyTypes
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h2 className='setup-page3-title'>What type of home are you interested in?</h2>
                <form onSubmit={handleNext} className='setup-page-form-property-type'>
                    <div className='setup-page-property-select-container'>
                        <div className='setup-page3-selections-row'>
                            <div
                                className={`setup-page3-boxes ${selectedPropertyTypes.includes('Single Family') ? 'active' : ''}`}
                                onClick={() => handlePropertyTypeChange('Single Family')}
                            >
                                <SingleFamilyIcon />
                                <h2 className='setup-page3-boxes-title'>Single Family</h2>
                            </div>
                            <div
                                className={`setup-page3-boxes ${selectedPropertyTypes.includes('Condo') ? 'active' : ''}`}
                                onClick={() => handlePropertyTypeChange('Condo')}
                            >
                                <CondoIcon />
                                <h2 className='setup-page3-boxes-title'>Condo</h2>
                            </div>
                        </div>
                        <div className='setup-page3-selections-row'>
                            <div
                                className={`setup-page3-boxes ${selectedPropertyTypes.includes('Apartment') ? 'active' : ''}`}
                                onClick={() => handlePropertyTypeChange('Apartment')}
                            >
                                <ApartmentIcon />
                                <h2 className='setup-page3-boxes-title'>Apartment</h2>
                            </div>
                            <div
                                className={`setup-page3-boxes ${selectedPropertyTypes.includes('TownHome') ? 'active' : ''}`}
                                onClick={() => handlePropertyTypeChange('TownHome')}
                            >
                                <TownhouseIcon />
                                <h2 className='setup-page3-boxes-title'>TownHome</h2>
                            </div>
                        </div>
                    </div>
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

// Setup Page 4
function SetupPage4({ formData, setFormData, onSubmit, goBack }) {
    const [selectedBedrooms, setSelectedBedrooms] = useState(formData.bedrooms || 'any');
    const [selectedBathrooms, setSelectedBathrooms] = useState(formData.bathrooms || 'any');

    const handleBedroomChange = (value) => {
        setSelectedBedrooms(value);
        setFormData(prevFormData => ({
            ...prevFormData,
            bedrooms: value
        }));
    };

    const handleBathroomChange = (value) => {
        setSelectedBathrooms(value);
        setFormData(prevFormData => ({
            ...prevFormData,
            bathrooms: value
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleNext = (event) => {
        event.preventDefault();
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main setup-page-content'>
            <div className='setup-page4-title'>What's your ideal<br />criteria?</div>
            <div className='setup-page4-criteria-container'>
                <form onSubmit={handleNext} className='setup-page-criteria-form'>
                    <div className='setup-page4-criteria-container'>
                        <div className='setup-page4-criteria-title'>Bedrooms</div>
                        <div className='setup-page4-criteria-selections'>
                            {['Any', '1+', '2+', '3+', '4+', '5+'].map((bedroom, index) => (
                                <div
                                    key={index}
                                    className={`setup-page-criteria-number ${bedroom === 'Any' ? 'any' : ''} ${selectedBedrooms === bedroom ? 'active' : bedroom === 'Any' && selectedBedrooms === 'any' ? 'active': ''}`}
                                    onClick={() => handleBedroomChange(bedroom)}
                                >
                                    {bedroom}
                                </div>
                            ))}
                        </div>
                        <div className='setup-page4-criteria-title'>Bathrooms</div>
                        <div className='setup-page4-criteria-selections'>
                            {['Any', '1+', '2+', '3+', '4+', '5+'].map((bathroom, index) => (
                                <div
                                    key={index}
                                    className={`setup-page-criteria-number ${bathroom === 'Any' ? 'any' : ''} ${selectedBathrooms === bathroom ? 'active' : bathroom === 'Any' && selectedBathrooms === 'any' ? 'active': ''}`}
                                    onClick={() => handleBathroomChange(bathroom)}
                                >
                                    {bathroom}
                                </div>
                            ))}
                        </div>
                        <div className='setup-page4-criteria-title'>Square Footage</div>
                        <div className='setup-page4-min-max-label'>
                            <p>Min*</p>
                            <p>Max</p>
                        </div>
                        <div className='budget-input-container'>
                            <input
                                className='setup-page4-btn-highlight'
                                type="number"
                                name="min_sqft"
                                min={0}
                                max={999999}
                                maxLength={6}
                                value={formData.min_sqft}
                                onChange={handleChange}
                                placeholder="0"
                                required
                            />
                            <input
                                className='setup-page4-btn'
                                type="number"
                                name="max_sqft"
                                min={0}
                                max={999999}
                                maxLength={6}
                                value={formData.max_sqft}
                                onChange={handleChange}
                                placeholder="No Max"
                            />
                        </div>
                    </div>
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

// Setup Page 5
function SetupPage5({ formData, setFormData, onSubmit, goBack }) {
    const [selectedDen, setSelectedDen] = useState(formData.den || 'any');
    const [selectedStoriesLevel, setSelectedStoriesLevel] = useState(formData.stories_level || 'any');
    const [selectedGarage, setSelectedGarage] = useState(formData.garage || 'any');
    const [selectedGarageSpaces, setSelectedGarageSpaces] = useState(formData.garage_spaces || '');
    const [minYear, setMinYear] = useState(formData.min_year || '');
    const [maxYear, setMaxYear] = useState(formData.max_year || '');

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            den: selectedDen,
            stories_level: selectedStoriesLevel,
            garage: selectedGarage,
            garage_spaces: selectedGarageSpaces,
            min_year: minYear,
            max_year: maxYear
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>What's your ideal criteria?</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <div className='setup-page4-criteria-container'>
                        <div className='setup-page4-criteria-title'>Den</div>
                        <div className='setup-page4-criteria-selections'>
                            {['Any', '1+', '2+', '3+'].map((den, index) => (
                                <div
                                    key={index}
                                    className={`setup-page-criteria-number ${den === 'Any' ? 'any' : ''} ${selectedDen === den ? 'active' : den === 'Any' && selectedDen === 'any' ? 'active': ''}`}
                                    onClick={() => setSelectedDen(den)}
                                >
                                    {den}
                                </div>
                            ))}
                        </div>
                        <div className='setup-page4-criteria-title'>Stories/Level</div>
                        <div className='setup-page4-criteria-selections'>
                            {['Any', '1', '2+', '3+'].map((level, index) => (
                                <div
                                    key={index}
                                    className={`setup-page-criteria-number ${level === 'Any' ? 'any' : ''} ${selectedStoriesLevel === level ? 'active' : level === 'Any' && selectedStoriesLevel === 'any' ? 'active': ''}`}
                                    onClick={() => setSelectedStoriesLevel(level)}
                                >
                                    {level}
                                </div>
                            ))}
                        </div>
                        <div className='setup-page4-criteria-title'>Garage</div>
                        <div className='setup-page4-criteria-selections'>
                            <button
                                type="button"
                                className={`setup-toggle-button ${selectedGarage === 'any' ? 'active' : ''}`}
                                onClick={() => setSelectedGarage('any')}
                            >
                                Any
                            </button>
                            <button
                                type="button"
                                className={`setup-toggle-button ${selectedGarage === 'no'? 'active' : ''}`}
                                onClick={() => setSelectedGarage('no')}
                            >
                                No
                            </button>
                            <button
                                type="button"
                                className={`setup-toggle-button ${selectedGarage === 'yes' ? 'active' : ''}`}
                                onClick={() => setSelectedGarage('yes')}
                            >
                                Yes
                            </button>
                        </div>
                        {selectedGarage === 'yes' && (
                            <div className='setup-page4-criteria-title'>Garage Spaces</div>
                        )}
                        {selectedGarage === 'yes' && (
                            <div className='setup-page4-criteria-selections'>
                                {['1+', '2+', '3+', '4+'].map((spaces, index) => (
                                    <div
                                        key={index}
                                        className={`setup-page-criteria-number ${selectedGarageSpaces === spaces ? 'active' : ''}`}
                                        onClick={() => setSelectedGarageSpaces(spaces)}
                                    >
                                        {spaces}
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className='setup-page4-criteria-title'>Year Built</div>
                        <div className='setup-page4-min-max-label'>
                            <p>Min</p>
                            <p>Max</p>
                        </div>
                        <div className='budget-input-container'>
                            <input
                                className='setup-page4-btn'
                                type="number"
                                name="min_year"
                                min={0}
                                max={2024}
                                maxLength={4}
                                value={minYear}
                                onChange={(e) => setMinYear(e.target.value)}
                                placeholder="No Min"
                            />
                            <input
                                className='setup-page4-btn'
                                type="number"
                                name="max_year"
                                value={maxYear}
                                onChange={(e) => setMaxYear(e.target.value)}
                                placeholder="No Max"
                                min={0}
                                max={2024}
                                maxLength={4}
                            />
                        </div>
                    </div>
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

// Setup Page 6
function SetupPage6({ formData, setFormData, onSubmit, goBack }) {
    const [selectedLotSizeMin, setSelectedLotSizeMin] = useState(formData.min_lot_size || '');
    const [selectedLotSizeMax, setSelectedLotSizeMax] = useState(formData.max_lot_size || '');
    const [selectedPool, setSelectedPool] = useState(formData.pool || 'any');
    const [selectedWaterfront, setSelectedWaterfront] = useState(formData.waterfront || 'any');
    const [selectedGulfAccess, setSelectedGulfAccess] = useState(formData.gulf_access || 'any');
    const [selectedSpa, setSelectedSpa] = useState(formData.spa || 'any');
    const [selectedViews, setSelectedViews] = useState(formData.view || []);

    // Open states
    const [isViewOpen, setIsViewOpen] = useState(false)

    const handleViewChange = (view) => {
        setSelectedViews(prevSelected => {
            if (prevSelected.includes(view)) {
                return prevSelected.filter(item => item !== view);
            } else {
                return [...prevSelected, view];
            }
        });
    };

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            min_lot_size: selectedLotSizeMin,
            max_lot_size: selectedLotSizeMax,
            pool: selectedPool,
            waterfront: selectedWaterfront,
            gulf_access: selectedGulfAccess,
            spa: selectedSpa,
            view: selectedViews
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>Property Features</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <div className='setup-page4-criteria-title'>Lot Size</div>
                    <div className='setup-page4-min-max-label'>
                        <p>Min</p>
                        <p>Max</p>
                    </div>
                    <div className='budget-input-container'>
                        <select
                            className='setup-page4-btn'
                            name="min_lot_size"
                            value={selectedLotSizeMin}
                            onChange={(e) => setSelectedLotSizeMin(e.target.value)}
                            required
                        >
                            <option value={0}>No Min</option>
                            <option value={10890}>1/4 acre</option>
                            <option value={21780}>1/2 acre</option>
                            <option value={43560}>1 acre</option>
                            <option value={87120}>2 acres</option>
                            <option value={217800}>5 acres</option>
                            <option value={435600}>10 acres</option>
                            <option value={871200}>20 acres</option>
                            <option value={2178000} >50 acres</option>
                        </select>
                        <select
                            className='setup-page4-btn'
                            name="max_lot_size"
                            value={selectedLotSizeMax}
                            onChange={(e) => setSelectedLotSizeMax(e.target.value)}
                            required
                        >
                            <option value={999999999999}>No Max</option>
                            <option value={10890}>1/4 acre</option>
                            <option value={21780}>1/2 acre</option>
                            <option value={43560}>1 acre</option>
                            <option value={87120}>2 acres</option>
                            <option value={217800}>5 acres</option>
                            <option value={435600}>10 acres</option>
                            <option value={871200}>20 acres</option>
                            <option value={2178000} >50 acres</option>
                        </select>
                    </div>
                    <div className='setup-page4-criteria-title'>Pool</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedPool === 'any' ? 'active' : ''}`}
                            onClick={() => setSelectedPool('any')}
                        >
                            Any
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedPool === 'no' ? 'active' : ''}`}
                            onClick={() => setSelectedPool('no')}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedPool === 'yes' ? 'active' : ''}`}
                            onClick={() => setSelectedPool('yes')}
                        >
                            Yes
                        </button>
                    </div>
                    <div className='setup-page4-criteria-title'>Waterfront</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedWaterfront === 'any' ? 'active' : ''}`}
                            onClick={() => setSelectedWaterfront('any')}
                        >
                            Any
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedWaterfront === 'no' ? 'active' : ''}`}
                            onClick={() => setSelectedWaterfront('no')}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedWaterfront === 'yes' ? 'active' : ''}`}
                            onClick={() => setSelectedWaterfront('yes')}
                        >
                            Yes
                        </button>
                    </div>
                    <div className='setup-page4-criteria-title'>Gulf Access</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedGulfAccess === 'any' ? 'active' : ''}`}
                            onClick={() => setSelectedGulfAccess('any')}
                        >
                            Any
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedGulfAccess === 'no' ? 'active' : ''}`}
                            onClick={() => setSelectedGulfAccess('no')}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedGulfAccess === 'yes' ? 'active' : ''}`}
                            onClick={() => setSelectedGulfAccess('yes')}
                        >
                            Yes
                        </button>
                    </div>
                    <div className='setup-page4-criteria-title'>Spa</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedSpa === 'any' ? 'active' : ''}`}
                            onClick={() => setSelectedSpa('any')}
                        >
                            Any
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedSpa === 'no' ? 'active' : ''}`}
                            onClick={() => setSelectedSpa('no')}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedSpa === 'yes' ? 'active' : ''}`}
                            onClick={() => setSelectedSpa('yes')}
                        >
                            Yes
                        </button>
                    </div>
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>View</div>
                        <p className='setup-dropdown-selected-count'>{selectedViews.length > 0 ? selectedViews.length + ' selected' : ''}</p>
                        { isViewOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsViewOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsViewOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isViewOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Bay', 'Canal', 'City', 'Creek', 'Golf Course', 'Intersecting Canal', 'Landscaped Area',
                                    'Mangroves', 'Pond', 'Pool/Club', 'Preserve', 'Privacy Wall', 'River', 'Tennis Courts',
                                    'Water', 'Water Feature', 'Wooded Area'
                                ].map((view, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedViews.includes(view) ? 'active' : ''}`}
                                        onClick={() => handleViewChange(view)}
                                    >
                                        {view}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

// Setup Page 7
function SetupPage7({ formData, setFormData, onSubmit, goBack }) {
    const [selectedExteriorFeatures, setSelectedExteriorFeatures] = useState(formData.exterior_features || []);
    const [selectedInteriorFeatures, setSelectedInteriorFeatures] = useState(formData.interior_features || []);
    const [selectedFlooring, setSelectedFlooring] = useState(formData.flooring || []);
    const [selectedAppliances, setSelectedAppliances] = useState(formData.appliances || []);

    // Open state variables
    const [isExteriorOpen , setIsExteriorOpen] = useState(false)
    const [isInteriorOpen , setIsInteriorOpen] = useState(false)
    const [isFlooringOpen , setIsFlooringOpen] = useState(false)
    const [isAppliancesOpen , setIsAppliancesOpen] = useState(false)


    const handleFeatureChange = (feature, setSelectedFeatures, selectedFeatures) => {
        setSelectedFeatures(prevSelected => {
            if (prevSelected.includes(feature)) {
                return prevSelected.filter(item => item !== feature);
            } else {
                return [...prevSelected, feature];
            }
        });
    };

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            exterior_features: selectedExteriorFeatures,
            interior_features: selectedInteriorFeatures,
            flooring: selectedFlooring,
            appliances: selectedAppliances
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>Property Features (cont.)</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    {/* EXTERIOR FEATURES SELECTION */}
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>Exterior Features</div>
                        <p className='setup-dropdown-selected-count'>{selectedExteriorFeatures.length > 0 ? selectedExteriorFeatures.length + ' selected' : ''}</p>
                        { isExteriorOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsExteriorOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsExteriorOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isExteriorOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Barn', 'Gas Fire Pit', 'Courtyard', 'Deck', 'Fence', 'Fruit Trees', 'Gazebo', 'Grill', 'Outdoor Fireplace',
                                    'Outdoor Kitchen', 'Outdoor Shower', 'Patio', 'Pond', 'Privacy Hedges', 'Room for Pool', 'Sprinkler system',
                                    'Storage', 'Tennis Court', 'Warehouse'
                                ].map((feature, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedExteriorFeatures.includes(feature) ? 'active' : ''}`}
                                        onClick={() => handleFeatureChange(feature, setSelectedExteriorFeatures, selectedExteriorFeatures)}
                                    >
                                        {feature}
                                    </div>
                                ))}
                            </div>      
                        </>
                    )}
                    {/* INTERIOR FEATURES SELECTION */}
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>Interior Features</div>
                        <p className='setup-dropdown-selected-count'>{selectedInteriorFeatures.length > 0 ? selectedInteriorFeatures.length + ' selected' : ''}</p>
                        { isInteriorOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsInteriorOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsInteriorOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isInteriorOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Bar', 'Closets: Built in shelves', 'Custom Mirrors', 'Disability Equipped', 'Exclusions', 'Fireplace', 'Foyer',
                                    'French Doors', 'Pantry', 'Pocket sliders', 'Surround Sound Wired', 'Volume Ceiling', 'Walk-In Closet', 'Wet Bar',
                                    'Wheelchair Access', 'Window Coverings', 'Zero/Corner Door Sliders'
                                ].map((feature, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedInteriorFeatures.includes(feature) ? 'active' : ''}`}
                                        onClick={() => handleFeatureChange(feature, setSelectedInteriorFeatures, selectedInteriorFeatures)}
                                    >
                                        {feature}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    {/* FLOORING FEATURES SELECTION */}
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>Flooring</div>
                        <p className='setup-dropdown-selected-count'>{selectedFlooring.length > 0 ? selectedFlooring.length + ' selected' : ''}</p>
                        { isFlooringOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsFlooringOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsFlooringOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isFlooringOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {['Brick', 'Carpet', 'Concrete', 'Laminate', 'Marble', 'Tile', 'Vinyl', 'Wood', 'Other'].map((floor, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedFlooring.includes(floor) ? 'active' : ''}`}
                                        onClick={() => handleFeatureChange(floor, setSelectedFlooring, selectedFlooring)}
                                    >
                                        {floor}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    {/* APPLIANCES FEATURES SELECTION */}
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>Appliances</div>
                        <p className='setup-dropdown-selected-count'>{selectedAppliances.length > 0 ? selectedAppliances.length + ' selected' : ''}</p>
                        { isAppliancesOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsAppliancesOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsAppliancesOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isAppliancesOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Auto Garage Door', 'Cooktop - Electric', 'Cooktop - Gas', 'Dishwasher', 'Disposal', 'Double Oven', 'Dryer', 'Freezer',
                                    'Generator', 'Grill - Gas', 'Home Automation', 'Ice Maker - Stand Alone', 'Microwave', 'Pot Filler', 'Range', 'Refrigerator',
                                    'Refrigerator/Freezer', 'Reverse Osmosis', 'Security System', 'Solar Panels', 'Steam Oven', 'Tankless Water Heater', 'Wall Oven',
                                    'Washer', 'Water Treatment', 'Wine Cooler', 'Other'
                                ].map((appliance, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedAppliances.includes(appliance) ? 'active' : ''}`}
                                        onClick={() => handleFeatureChange(appliance, setSelectedAppliances, selectedAppliances)}
                                    >
                                        {appliance}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

// Setup Page 8
function SetupPage8({ formData, setFormData, onSubmit, goBack }) {
    const [selectedHOA, setSelectedHOA] = useState(formData.hoa || 'any');
    const [selectedHOAAmenities, setSelectedHOAAmenities] = useState(formData.hoa_amenities || []);
    const [maxHOA, setMaxHOA] = useState(formData.max_hoa || '');
    const [feeFrequency, setFeeFrequency] = useState(formData.fee_frequency || '');

    // Open State Variables
    const [isAmenitiesOpen, setIsAmenitiesOpen] = useState(false)

    const handleHOAChange = (amenity) => {
        setSelectedHOAAmenities(prevSelected => {
            if (prevSelected.includes(amenity)) {
                return prevSelected.filter(item => item !== amenity);
            } else {
                return [...prevSelected, amenity];
            }
        });
    };

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            hoa: selectedHOA,
            fee_frequency: feeFrequency,
            hoa_amenities: selectedHOAAmenities,
            max_hoa: maxHOA
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>Do you want an HOA?</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <div className='setup-page4-criteria-title'>HOA</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedHOA === 'any' ? 'active' : ''}`}
                            onClick={() => setSelectedHOA('any')}
                        >
                            Any
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedHOA === 'no' ? 'active' : ''}`}
                            onClick={() => setSelectedHOA('no')}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedHOA === 'yes' ? 'active' : ''}`}
                            onClick={() => setSelectedHOA('yes')}
                        >
                            Yes
                        </button>
                    </div>
                    {selectedHOA === 'yes' && (
                        <>
                            <div className='setup-page4-criteria-title'>Max HOA Fee(s)</div>
                            <div className='budget-input-container'>
                                <input
                                    className='setup-page4-btn'
                                    type="number"
                                    name="max_hoa"
                                    value={maxHOA}
                                    onChange={(e) => setMaxHOA(e.target.value)}
                                    placeholder="Max HOA"
                                    min={0}
                                    max={999999}
                                    maxLength={6}
                                />
                            </div>
                            <div className='setup-page-single-input-container'>
                                <h2 className='setup-page-single-input-title'>Fee Frequency</h2>
                                <select
                                    className='setup-page4-btn'
                                    name="fee_frequency"
                                    value={feeFrequency}
                                    onChange={(e) => setFeeFrequency(e.target.value)}
                                    required
                                >
                                    <option value="">Select Frequency</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Bi-Annually">Bi-Annually</option>
                                    <option value="Annually">Annually</option>
                                </select>
                            </div>
                        </>
                    )}
                    {selectedHOA === 'yes' && (
                        <>
                            <div className='profile-selection-container'>
                                <div className='setup-page4-criteria-title'>HOA Amenities</div>
                                <p className='setup-dropdown-selected-count'>{selectedHOAAmenities.length > 0 ? selectedHOAAmenities.length + ' selected' : ''}</p>
                                { isAmenitiesOpen ? (
                                    <>
                                        <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsAmenitiesOpen(false)}/>
                                    </>
                                ) : (
                                    <>
                                        <ProfileArrow className='profile-arrow' onClick={(e) => setIsAmenitiesOpen(true)}/>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    {isAmenitiesOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Assisted Living Available', 'Basketball', 'BBQ - Picnic', 'Beach Access', 'Beach Club Available', 'Beach Club Included',
                                    'Beauty Salon', 'Bike And Jog Path', 'Bike Storage', 'Billiards', 'Boat Storage', 'Bocce Court', 'Business Center', 'Cabana',
                                    'Clubhouse', 'Common Laundry', 'Community Boat Dock', 'Community Boat Lift', 'Community Boat Ramp', 'Community Boat Slip',
                                    'Community Gulf Boat Access', 'Community Park', 'Community Pool', 'Community Spa/Hot tub', 'Concierge Services', 'Dog Park',
                                    'Electric Vehicle Charging', 'Exercise Room', 'Extra Storage', 'Fish Cleaning Station', 'Fishing Pier', 'Fitness Center Attended',
                                    'Full Service Spa', 'Golf Course', 'Guest Room', 'Hobby Room', 'Horses OK', 'Internet Access', 'Lakefront Beach', 'Lap Pool',
                                    'Library', 'Marina', 'None', 'Other', 'Pickleball', 'Play Area', 'Private Beach Pavilion', 'Private Membership', 'Putting Green',
                                    'Racquetball', 'Restaurant', 'Sauna', 'Shuffleboard', 'Sidewalk', 'Stable - Horse', 'Tennis Court', 'Theater', 'Trash Chute',
                                    'Vehicle Wash Area', 'Volleyball', 'Water Skiing'
                                ].map((amenity, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedHOAAmenities.includes(amenity) ? 'active' : ''}`}
                                        onClick={() => handleHOAChange(amenity)}
                                    >
                                        {amenity}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

////////// Price Slider Functions \\\\\\\\\\

// Function to format numbers with commas and add "+" if it's the maximum value
const formatNumber = (value, max = false, suffix = '') => {
    const formattedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return max && value >= 20000000 ? `$${formattedValue}+` : `$${formattedValue}${suffix}`;
};

// Custom scale transformation
const transformValueToCustomScale = (value) => {
    if (value <= 1000000) {
        return (value / 1000000) * 50;
    } else {
        return 50 + ((value - 1000000) / (20000000 - 1000000)) * 50;
    }
};

// Custom scale inverse transformation
const transformValueFromCustomScale = (value) => {
    if (value <= 50) {
        return Math.round((value / 50) * 1000000);
    } else {
        return Math.round(1000000 + ((value - 50) / 50) * (20000000 - 1000000));
    }
};

// Function to calculate the step based on the current value
const stepCalculation = (value) => {
    // console.log(`This is the value: ${value}`)
    if (value <= 500000) {
        return 0.5;
    } else if (value > 500000 && value <= 1000000) {
        return 1.25;
    } else if (value > 1000000 && value <= 5000000) {
        return 0.75;
    } else if (value > 5000000 && value <= 10000000){
        return 2;
    } else {
        return 2.5;
    }
};

/////////////////////////////////////////

// Setup Page 9
function SetupPage9({ formData, setFormData, onSubmit, goBack }) {
    // const [minPrice, setMinPrice] = useState(formData.min_budget || '');
    // const [maxPrice, setMaxPrice] = useState(formData.max_budget || '');
    const [selectedPurchaseTerms, setSelectedPurchaseTerms] = useState(formData.purchase_terms || []);
    const [preApproved, setPreApproved] = useState(formData.pre_approved || false);
    const [proofOfFunds, setProofOfFunds] = useState(formData.proof_of_funds || false);

    // Price Slider States
    const [minBudget, setMinBudget] = useState(0);
    const [maxBudget, setMaxBudget] = useState(20000000);
    const [step, setStep] = useState(10000);
    const [activeThumb, setActiveThumb] = useState(0);

    // Open State Variables
    const [isPurchaseOpen, setIsPurchaseOpen] = useState(false)

    useEffect(() => {
        if (formData.min_budget === 0) setMinBudget(0);
        if (formData.max_budget === 0) setMaxBudget(20000000);
    }, [formData.min_budget, formData.max_budget]);

    useEffect(() => {
        if (activeThumb === 0) {
            setStep(stepCalculation(minBudget));
        } else {
            setStep(stepCalculation(maxBudget));
        }
    }, [minBudget, maxBudget, activeThumb]);

    const handleSliderChange = (value) => {
        const [min, max] = value.map(transformValueFromCustomScale);

        setMinBudget(min);
        setMaxBudget(max);

        setFormData(prevFormData => ({
            ...prevFormData,
            min_budget: min,
            max_budget: max >= 20000000 ? 20000000 : max
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const numValue = Number(value.replace(/[^0-9]/g, '')); // Remove non-numeric characters for internal state
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: numValue
        }));
        if (name === 'min_budget') setMinBudget(numValue);
        if (name === 'max_budget') setMaxBudget(numValue);
    };

    const handlePurchaseTermsChange = (term) => {
        setSelectedPurchaseTerms(prevSelected => {
            if (prevSelected.includes(term)) {
                return prevSelected.filter(item => item !== term);
            } else {
                return [...prevSelected, term];
            }
        });
    };

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            // min_budget: minPrice,
            // max_budget: maxPrice,
            purchase_terms: selectedPurchaseTerms,
            pre_approved: preApproved,
            proof_of_funds: proofOfFunds
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page5-title'>Budget and Financing</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <div className='setup-page4-criteria-title'>What's your price range?</div>
                    <div className='setup-page4-min-max-label'>
                        <p>Min</p>
                        <p>Max</p>
                    </div>
                    <div className='budget-input-container'>
                        <input
                            className='setup-page4-btn'
                            type="text"
                            name="min_budget"
                            value={minBudget === 0 ? 'Any' : formatNumber(minBudget)}
                            onChange={handleInputChange}
                            placeholder="Minimum"
                            required
                            min={0}
                            max={999999999}
                            maxLength={10}
                        />
                        <input
                            className='setup-page4-btn'
                            type="text"
                            name="max_budget"
                            value={maxBudget === 20000000 ? 'Any' : formatNumber(maxBudget)}
                            onChange={handleInputChange}
                            placeholder="Maximum"
                            required
                            min={0}
                            max={999999999}
                            maxLength={10}
                        />
                    </div>
                    <ReactSlider
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        defaultValue={[transformValueToCustomScale(0), transformValueToCustomScale(20000000)]}
                        ariaLabel={['Lower thumb', 'Upper thumb']}
                        ariaValuetext={state => `Thumb value ${state.valueNow}`}
                        onBeforeChange={(value, index) => setActiveThumb(index)}
                        onChange={handleSliderChange}
                        min={0}
                        max={100}
                        value={[transformValueToCustomScale(minBudget), transformValueToCustomScale(maxBudget)]}
                        step={step}
                    />
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>Purchase Terms</div>
                        <p className='setup-dropdown-selected-count'>{selectedPurchaseTerms.length > 0 ? selectedPurchaseTerms.length + ' selected' : ''}</p>
                        { isPurchaseOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsPurchaseOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsPurchaseOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isPurchaseOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Cash Only', 'Financing/Cash', 'Seller Financing', 'FHA Mortgage', 'VA Mortgage', 'Other'
                                ].map((term, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedPurchaseTerms.includes(term) ? 'active' : ''}`}
                                        onClick={() => handlePurchaseTermsChange(term)}
                                    >
                                        {term}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className='setup-page4-criteria-title'>Pre-Approval Status</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${preApproved === false ? 'active' : ''}`}
                            onClick={() => setPreApproved(false)}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${preApproved === true ? 'active' : ''}`}
                            onClick={() => setPreApproved(true)}
                        >
                            Yes
                        </button>
                    </div>
                    <div className='setup-page4-criteria-title'>Proof of Funds</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${proofOfFunds === false ? 'active' : ''}`}
                            onClick={() => setProofOfFunds(false)}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${proofOfFunds === true ? 'active' : ''}`}
                            onClick={() => setProofOfFunds(true)}
                        >
                            Yes
                        </button>
                    </div>
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

// Setup Page 10
function SetupPage10({ formData, setFormData, onSubmit, goBack }) {
    const [moveInTimeline, setMoveInTimeline] = useState(formData.move_in_timeline || '');
    const [otherPreferences, setOtherPreferences] = useState(formData.other_preferences || '');

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            move_in_timeline: moveInTimeline,
            other_preferences: otherPreferences
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>Preferences</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <div className='setup-page4-criteria-title'>Move-in timeline</div>
                    <div className='budget-input-container'>
                        <select
                            className='setup-page4-btn'
                            name="move_in_timeline"
                            value={moveInTimeline}
                            onChange={(e) => setMoveInTimeline(e.target.value)}
                            required
                        >
                            <option value="">Select Timeline</option>
                            <option value="Immediate">Immediate</option>
                            <option value="2-3 months">2-3 months</option>
                            <option value="4-6 months">4-6 months</option>
                            <option value="6-12 months">6-12 months</option>
                            <option value="12+ months">12+ months</option>
                        </select>
                    </div>
                    <div className='setup-page4-criteria-title'>Any Other Preferences</div>
                    <div className='budget-input-container'>
                        <textarea
                            className="setup-input-extended"
                            name="other_preferences"
                            value={otherPreferences}
                            onChange={(e) => setOtherPreferences(e.target.value)}
                            placeholder="Other Preferences"
                        ></textarea>
                    </div>
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

// Main BuyerSetup component
export default function BuyerSetup() {
    const [index, setIndex] = useState(0);
    const [formData, setFormData] = useState({
        city: '',
        state: '',
        zipcode: '',
        min_budget: '',
        max_budget: '',
        bedrooms: '',
        bathrooms: '',
        min_sqft: '',
        max_sqft: '',
        property_type: '',
        listing_status: '',
        other_preferences: '',
        pre_approved: false,
        den: '',
        stories_level: '',
        min_year: '',
        max_year: '',
        garage: '',
        garage_spaces: '',
        min_lot_size: '',
        max_lot_size: '',
        pool: '',
        waterfront: '',
        gulf_access: '',
        spa: '',
        view: '',
        exterior_features: '',
        interior_features: '',
        flooring: '',
        appliances: '',
        hoa: '',
        max_hoa: '',
        fee_frequency: '',
        hoa_amenities: '',
        move_in_timeline: '',
        proof_of_funds: false,
        purchase_terms: ''
    });
    const navigate = useNavigate();
    
    const handleSubmit = async () => {
        // Make sure state is updated before sending the request
        setFormData(prevFormData => {
            const updatedData = { ...prevFormData };
            // console.log('Final Form Data:', updatedData);
            const response = fetch(`${process.env.REACT_APP_API_URL}/api/buyer-criteria`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedData),
                credentials: 'include'
            }).then(response => response.json()).then(result => {
                // console.log('Server response:', result);
                navigate('/dashboard');
            });

            return updatedData;
        });
    };


    const renderPage = () => {
        switch (index) {
            case 0:
                return <SetupPage1 formData={formData} setFormData={setFormData} onSubmit={() => setIndex(index + 1)} />;
            case 1:
                return <SetupPage2 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 2:
                return <SetupPage3 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 3:
                return <SetupPage4 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 4:
                return <SetupPage5 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 5:
                return <SetupPage6 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 6:
                return <SetupPage7 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 7:
                return <SetupPage8 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 8:
                return <SetupPage9 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 9:
                return <SetupPage10 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={handleSubmit} />;
            // case 10:
            //     return <SetupPage11 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={handleSubmit} />;
            default:
                return <div>Step not found!</div>;
        }
    };

    return (
        <div className='setup-main'>
            <BuyerSetupProgressBar currentStep={index} />
            {renderPage()}
        </div>
    );
}

