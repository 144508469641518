import React, { useEffect, useState } from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { SetupPage2Image, SingleFamilyIcon, CondoIcon, ApartmentIcon, TownhouseIcon, UploadIcon, ProfileArrow, MultiFamilyIcon, LandIcon } from '../../assets/icons/icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const SellerSetupProgressBar = ({ currentStep }) => {
    const totalSteps = 12;
    return (
        <div className='buyersetup-dash-container'>
            {[...Array(totalSteps)].map((_, index) => (
                <div
                    key={index}
                    className={index > currentStep ? 'buyersetup-dash-false' : 'buyersetup-dash-true'}
                ></div>
            ))}
        </div>
    );
};

function SetupPage1({ formData, setFormData, onSubmit }) {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleNext = (event) => {
        event.preventDefault();
        onSubmit();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page1-title'>Where is your property located?</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <h2 className='setup-input-title'>Property Address</h2>
                    <input type="text" name="property_address" value={formData.property_address} onChange={handleChange} placeholder="Property Address" className="setup-input" required />
                    <h2 className='setup-input-title'>City</h2>
                    <input type="text" name="city" value={formData.city} onChange={handleChange} placeholder="City" className="setup-input" required />
                    <h2 className='setup-input-title'>State (ex: TX)</h2>
                    <input type="text" name="state" value={formData.state} onChange={handleChange} placeholder="State" className="setup-input" required />
                    <h2 className='setup-input-title'>ZIP Code</h2>
                    <input type="number" name="zipcode" min='0' max='99999' value={formData.zipcode} onChange={handleChange} placeholder="ZIP Code" className="setup-input" required />
                    <div className='setup-page-button-container'>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function SetupPage2({ formData, setFormData, onSubmit, goBack }) {
    const [selectedStatus, setSelectedStatus] = useState(formData.property_status || '');
    const [fsbo, setFsbo] = useState(formData.fsbo || false);

    const handleStatusChange = (status) => {
        setSelectedStatus(status);
        setFormData(prevFormData => ({
            ...prevFormData,
            property_status: status
        }));
    };

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            fsbo: selectedStatus === 'selling' ? fsbo : false
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page2-title'>Are you looking to rent or sell your home?</h1>
                <form onSubmit={handleNext} className='setup-page-form-listing-type'>
                    <div className='setup-page-status-container'>
                        <button
                            type="button"
                            className={`setup-status-btn ${selectedStatus === 'for_rent' ? 'active' : ''}`}
                            onClick={() => handleStatusChange('for_rent')}
                        >
                            Rent
                        </button>
                        <button
                            type="button"
                            className={`setup-status-btn ${selectedStatus === 'for_sale' ? 'active' : ''}`}
                            onClick={() => handleStatusChange('for_sale')}
                        >
                            Sell
                        </button>
                    </div>
                    {selectedStatus === 'for_sale' && (
                        <>
                            <div className='setup-page4-criteria-container'>
                                <h2 className='setup-page4-criteria-title'>Is this a For Sale by Owner? (FSBO)</h2>
                                <div className='setup-page4-criteria-selections'>
                                    <button
                                        type="button"
                                        className={`setup-status-btn ${fsbo ? 'active' : ''}`}
                                        onClick={(e) => setFsbo(true)}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        type="button"
                                        className={`setup-status-btn ${fsbo ? '' : 'active'}`}
                                        onClick={(e) => setFsbo(false)}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                    <SetupPage2Image/>
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function SetupPage3({ formData, setFormData, onSubmit, goBack }) {
    const [selectedPropertyType, setSelectedPropertyType] = useState(formData.property_type || '');

    const handlePropertyTypeChange = (value) => {
        setSelectedPropertyType(value);
        setFormData(prevFormData => ({
            ...prevFormData,
            property_type: value
        }));
    };

    const handleNext = (event) => {
        event.preventDefault();
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <>
            <div className='setup-pages-main'>
                <div className='setup-page-content'>
                    <h2 className='setup-page3-title'>What type of property is it?</h2>
                    <form onSubmit={handleNext} className='setup-page-form-property-type'>
                        <div className='setup-page-property-select-container'>
                            <div className='setup-page3-selections-row'>
                                <div
                                    className={`setup-page3-boxes ${selectedPropertyType === 'Single Family' ? 'active' : ''}`}
                                    onClick={() => handlePropertyTypeChange('Single Family')}
                                >
                                    <SingleFamilyIcon />
                                    <h2 className='setup-page3-boxes-title'>Single Family</h2>
                                </div>
                                <div
                                    className={`setup-page3-boxes ${selectedPropertyType === 'Condo' ? 'active' : ''}`}
                                    onClick={() => handlePropertyTypeChange('Condo')}
                                >
                                    <CondoIcon />
                                    <h2 className='setup-page3-boxes-title'>Condo</h2>
                                </div>
                            </div>
                            <div className='setup-page3-selections-row'>
                                <div
                                    className={`setup-page3-boxes ${selectedPropertyType === 'Apartment' ? 'active' : ''}`}
                                    onClick={() => handlePropertyTypeChange('Apartment')}
                                >
                                    <ApartmentIcon />
                                    <h2 className='setup-page3-boxes-title'>Apartment</h2>
                                </div>
                                <div
                                    className={`setup-page3-boxes ${selectedPropertyType === 'Townhouse' ? 'active' : ''}`}
                                    onClick={() => handlePropertyTypeChange('Townhouse')}
                                >
                                    <TownhouseIcon />
                                    <h2 className='setup-page3-boxes-title'>Townhouse</h2>
                                </div>
                            </div>
                            <div className='setup-page3-selections-row'>
                                <div
                                    className={`setup-page3-boxes ${selectedPropertyType === 'Multi Family' ? 'active' : ''}`}
                                    onClick={() => handlePropertyTypeChange('Multi Family')}
                                >
                                    <MultiFamilyIcon/>
                                    <h2 className='setup-page3-boxes-title'>Multi Famil</h2>
                                </div>
                                <div
                                    className={`setup-page3-boxes ${selectedPropertyType === 'Land' ? 'active' : ''}`}
                                    onClick={() => handlePropertyTypeChange('Land')}
                                >
                                    <LandIcon />
                                    <h2 className='setup-page3-boxes-title'>Land</h2>
                                </div>
                            </div>
                        </div>
                        <div className='setup-page-button-container'>
                            <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                            <button type="submit" className='setup-page-next-btn'>Next</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}


function SetupPage4({ formData, setFormData, onSubmit, goBack }) {
    const [selectedBedrooms, setSelectedBedrooms] = useState(formData.bedrooms);
    const [selectedBathrooms, setSelectedBathrooms] = useState(formData.bathrooms);
    const [selectedBathroomsHalf, setSelectedBathroomsHalf] = useState(formData.bathrooms_half);

    const handleBedroomChange = (value) => {
        setSelectedBedrooms(value);
        setFormData(prevFormData => ({
            ...prevFormData,
            bedrooms: value
        }));
    };

    const handleBathroomChange = (value) => {
        setSelectedBathrooms(value);
        setFormData(prevFormData => ({
            ...prevFormData,
            bathrooms: value
        }));
    };

    const handleBathroomHalfChange = (value) => {
        setSelectedBathroomsHalf(value);
        setFormData(prevFormData => ({
            ...prevFormData,
            bathrooms_half: value
        }));
    };

    const handleNext = (event) => {
        event.preventDefault();
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <>
            <div className='setup-pages-main setup-page-content'>
                <div className='setup-page4-title'>Tell us about<br />your home?</div>
                <div className='setup-page4-criteria-container'>
                    <form onSubmit={handleNext} className='setup-page-criteria-form'>
                        <div className='setup-page4-criteria-container'>
                            <div className='setup-page-single-input-container'>
                                <h2 className='setup-page-single-input-title'>Bedrooms</h2>
                                <input 
                                    type="text" 
                                    name="bedroom" 
                                    min='0' 
                                    max='99' 
                                    pattern="\d*" 
                                    maxLength='2'
                                    value={selectedBedrooms} 
                                    onChange={(e) => handleBedroomChange(e.target.value)} 
                                    placeholder="" 
                                    className="setup-page4-btn setup-page-single-input" 
                                    required 
                                />
                            </div>
                            <div className='setup-page-single-input-container'>
                                <h2 className='setup-page-single-input-title'>Full Bathrooms</h2>
                                <input 
                                    type="text" 
                                    name="bathroom" 
                                    min='0' 
                                    max='99' 
                                    pattern="\d*" 
                                    maxLength='2'
                                    value={selectedBathrooms} 
                                    onChange={(e) => handleBathroomChange(e.target.value)} 
                                    placeholder="" 
                                    className="setup-page4-btn setup-page-single-input" 
                                    required 
                                />
                            </div>
                            <div className='setup-page-single-input-container'>
                                <h2 className='setup-page-single-input-title'>1/2 Bathrooms</h2>
                                <input 
                                    type="text" 
                                    name="bathroom_half" 
                                    min='0' 
                                    max='99' 
                                    pattern="\d*" 
                                    maxLength='2'
                                    value={selectedBathroomsHalf} 
                                    onChange={(e) => handleBathroomHalfChange(e.target.value)} 
                                    placeholder="" 
                                    className="setup-page4-btn setup-page-single-input" 
                                    required 
                                />
                            </div>
                            <div className='setup-page4-criteria-title'>Total Sqft</div>
                            <div className='setup-page4-sqft-selections-container'>
                                <input
                                    className='setup-input-sqft'
                                    placeholder='SQFT'
                                    type="number"
                                    name="sqft"
                                    min='0'
                                    value={formData.sqft}
                                    onChange={(e) => setFormData(prevFormData => ({ ...prevFormData, sqft: e.target.value }))}
                                    required
                                />
                            </div>
                            <div className='setup-page4-criteria-title'>Living Area Sqft</div>
                            <div className='setup-page4-sqft-selections-container'>
                                <input
                                    className='setup-input-sqft'
                                    placeholder='Living Area SQFT'
                                    type="number"
                                    name="sqft_living"
                                    min='0'
                                    value={formData.sqft_living}
                                    onChange={(e) => setFormData(prevFormData => ({ ...prevFormData, sqft_living: e.target.value }))}
                                    required
                                />
                            </div>
                        </div>
                        <div className='setup-page-button-container'>
                            <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                            <button type="submit" className='setup-page-next-btn'>Next</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

function SetupPage5({ formData, setFormData, onSubmit, goBack }) {
    const [selectedDen, setSelectedDen] = useState(formData.den || 0);
    const [selectedStoriesLevel, setSelectedStoriesLevel] = useState(formData.stories_level || 1);
    const [selectedGarage, setSelectedGarage] = useState(formData.garage || false);
    const [attachedGarage, setAttachedGarage] = useState(formData.attached_garage || false);
    const [selectedGarageSpaces, setSelectedGarageSpaces] = useState(formData.garage_spaces || 0);
    const [carport, setCarport] = useState(formData.carport || false);
    const [selectedCarportSpaces, setSelectedCarportSpaces] = useState(formData.carport_spaces || 0);
    const [yearBuilt, setYearBuilt] = useState(formData.year_built || '');

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            den: selectedDen,
            stories_level: selectedStoriesLevel,
            garage: selectedGarage,
            attached_garage: attachedGarage,
            garage_spaces: selectedGarageSpaces,
            carport: carport,
            carport_spaces: selectedCarportSpaces,
            year_built: yearBuilt
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>Additional Home Criteria</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <div className='setup-page4-criteria-container'>
                        <div className='setup-page-single-input-container'>
                            <h2 className='setup-page-single-input-title'>Den(s)</h2>
                            <input 
                                type="text" 
                                name="den" 
                                min='0' 
                                max='99' 
                                pattern="\d*" 
                                maxLength='2'
                                value={selectedDen} 
                                onChange={(e) => setSelectedDen(e.target.value)} 
                                placeholder="0" 
                                className="setup-page4-btn setup-page-single-input" 
                                required 
                            />
                        </div>
                        <div className='setup-page-single-input-container'>
                            <h2 className='setup-page-single-input-title'>Stories/Level</h2>
                            <input 
                                type="text" 
                                name="level" 
                                min='0' 
                                max='99' 
                                pattern="\d*" 
                                maxLength='2'
                                value={selectedStoriesLevel} 
                                onChange={(e) => setSelectedStoriesLevel(e.target.value)} 
                                placeholder="0" 
                                className="setup-page4-btn setup-page-single-input" 
                                required 
                            />
                        </div>
                        <div className='setup-page4-criteria-title'>Garage</div>
                        <div className='setup-page4-criteria-selections'>
                            <button
                                type="button"
                                className={`setup-toggle-button ${selectedGarage === false ? 'active' : ''}`}
                                onClick={() => setSelectedGarage(false)}
                            >
                                No
                            </button>
                            <button
                                type="button"
                                className={`setup-toggle-button ${selectedGarage === true ? 'active' : ''}`}
                                onClick={() => setSelectedGarage(true)}
                            >
                                Yes
                            </button>
                        </div>
                        {selectedGarage && (
                            <>
                                <div className='setup-page4-criteria-title'>Attached Garage</div>
                                <div className='setup-page4-criteria-selections'>
                                    <button
                                        type="button"
                                        className={`setup-toggle-button ${attachedGarage === false ? 'active' : ''}`}
                                        onClick={() => setAttachedGarage(false)}
                                    >
                                        Detached
                                    </button>
                                    <button
                                        type="button"
                                        className={`setup-toggle-button ${attachedGarage === true ? 'active' : ''}`}
                                        onClick={() => setAttachedGarage(true)}
                                    >
                                        Attached
                                    </button>
                                </div>
                                <div className='setup-page-single-input-container'>
                                    <h2 className='setup-page-single-input-title'>Garage Spaces</h2>
                                    <input 
                                        type="text" 
                                        name="level" 
                                        min='0' 
                                        max='99' 
                                        pattern="\d*" 
                                        maxLength='2'
                                        value={selectedGarageSpaces} 
                                        onChange={(e) => setSelectedGarageSpaces(e.target.value)} 
                                        placeholder="0" 
                                        className="setup-page4-btn setup-page-single-input" 
                                        required 
                                    />
                                </div>
                            </>
                        )}
                        <div className='setup-page4-criteria-title'>Carport</div>
                        <div className='setup-page4-criteria-selections'>
                            <button
                                type="button"
                                className={`setup-toggle-button ${carport === false ? 'active' : ''}`}
                                onClick={() => setCarport(false)}
                            >
                                No
                            </button>
                            <button
                                type="button"
                                className={`setup-toggle-button ${carport === true ? 'active' : ''}`}
                                onClick={() => setCarport(true)}
                            >
                                Yes
                            </button>
                        </div>
                        { carport && (
                            <>
                                <div className='setup-page-single-input-container'>
                                    <h2 className='setup-page-single-input-title'>Carport Spaces</h2>
                                    <input 
                                        type="text" 
                                        name="level" 
                                        min='0' 
                                        max='99' 
                                        pattern="\d*" 
                                        maxLength='2'
                                        value={selectedCarportSpaces} 
                                        onChange={(e) => setSelectedCarportSpaces(e.target.value)} 
                                        placeholder="0" 
                                        className="setup-page4-btn setup-page-single-input" 
                                        required 
                                    />
                                </div>
                            </>
                        )}
                        <div className='setup-page-single-input-container'>
                            <h2 className='setup-page-single-input-title'>Year Built</h2>
                            <input 
                                type="text" 
                                name="year_built" 
                                min='0' 
                                max='99' 
                                pattern="\d*" 
                                maxLength='4'
                                value={yearBuilt} 
                                onChange={(e) => setYearBuilt(e.target.value)} 
                                placeholder="Year Built" 
                                className="setup-page4-btn setup-page-single-input" 
                                required 
                            />
                        </div>
                    </div>
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function SetupPage6({ formData, setFormData, onSubmit, goBack }) {
    const [selectedLotSize, setSelectedLotSize] = useState(formData.lot_size || '');
    const [lotDescription, setLotDescription] = useState(formData.lot_description || '');
    const [selectedPool, setSelectedPool] = useState(formData.pool || false);
    const [poolDescription, setPoolDescription] = useState(formData.pool_description || '');
    const [selectedWaterfront, setSelectedWaterfront] = useState(formData.waterfront || false);
    const [waterfrontDescription, setWaterfrontDescription] = useState(formData.waterfront_description || '')
    const [selectedGulfAccess, setSelectedGulfAccess] = useState(formData.gulf_access || false);
    const [selectedSpa, setSelectedSpa] = useState(formData.spa || false);
    const [spaDescription, setSpaDescription] = useState(formData.spa_description || '');
    const [isSQFTMetric, setIsSQFTMetric] = useState('sqft_lot');

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            lot_size: selectedLotSize,
            lot_description: lotDescription,
            pool: selectedPool,
            pool_description: poolDescription,
            waterfront: selectedWaterfront,
            waterfront_description: waterfrontDescription,
            gulf_access: selectedGulfAccess,
            spa: selectedSpa,
            spa_description: spaDescription,
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>Property Features</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <div className='setup-page-single-input-container'>
                        <h2 className='setup-page-single-input-title'>Lot Size <span className='setup-example-text'>{isSQFTMetric === 'sqft_lot' ? '': '(ex: 1.7, 0.12)' }</span></h2>
                        <input
                            className='setup-page4-btn'
                            type="number"
                            name="lot_size"
                            value={selectedLotSize}
                            onChange={(e) => setSelectedLotSize(e.target.value)}
                            placeholder="Lot Size"
                        />
                        <select
                            className='setup-page-single-select'
                            name="lot_metric"
                            value={isSQFTMetric}
                            placeholder="Sqft lot"
                            onChange={(e) => setIsSQFTMetric(e.target.value)}
                        >
                            <option value='sqft_lot'>sqft lot</option>
                            <option value="acre_lot">acre lot</option>
                        </select>
                    </div>
                    {/* <div className='setup-page4-criteria-title'>Lot Description</div>
                    <div className='budget-input-container'>
                        <input
                            className='setup-input-extended'
                            type="text"
                            name="lot_description"
                            value={lotDescription}
                            onChange={(e) => setLotDescription(e.target.value)}
                            placeholder="Lot Description"
                        />
                    </div> */}
                    <div className='setup-page4-criteria-title'>Pool</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedPool === false ? 'active' : ''}`}
                            onClick={() => setSelectedPool(false)}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedPool === true ? 'active' : ''}`}
                            onClick={() => setSelectedPool(true)}
                        >
                            Yes
                        </button>
                    </div>
                    {selectedPool && (
                        <div className='setup-page4-criteria-title'>Pool Description</div>
                    )}
                    {selectedPool && (
                        <div className='budget-input-container'>
                            <input
                                className='setup-input-extended'
                                type="text"
                                name="pool_description"
                                value={poolDescription}
                                onChange={(e) => setPoolDescription(e.target.value)}
                                placeholder="Pool Description"
                            />
                        </div>
                    )}
                    <div className='setup-page4-criteria-title'>Waterfront</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedWaterfront === false ? 'active' : ''}`}
                            onClick={() => setSelectedWaterfront(false)}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedWaterfront === true ? 'active' : ''}`}
                            onClick={() => setSelectedWaterfront(true)}
                        >
                            Yes
                        </button>
                    </div>
                    { selectedWaterfront && (
                        <>
                            <div className='setup-page4-criteria-title'>Waterfront Description</div>
                            <div className='budget-input-container'>
                                <input
                                    className='setup-input-extended'
                                    type="text"
                                    name="waterfront_description"
                                    value={waterfrontDescription}
                                    onChange={(e) => setWaterfrontDescription(e.target.value)}
                                    placeholder="Waterfront Description"
                                />
                            </div>
                        </>
                    )}
                    <div className='setup-page4-criteria-title'>Gulf Access</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedGulfAccess === false ? 'active' : ''}`}
                            onClick={() => setSelectedGulfAccess(false)}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedGulfAccess === true ? 'active' : ''}`}
                            onClick={() => setSelectedGulfAccess(true)}
                        >
                            Yes
                        </button>
                    </div>
                    <div className='setup-page4-criteria-title'>Spa</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedSpa === false ? 'active' : ''}`}
                            onClick={() => setSelectedSpa(false)}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedSpa === true ? 'active' : ''}`}
                            onClick={() => setSelectedSpa(true)}
                        >
                            Yes
                        </button>
                    </div>
                    {selectedSpa && (
                        <div className='setup-page4-criteria-title'>Spa Description</div>
                    )}
                    {selectedSpa && (
                        <div className='budget-input-container'>
                            <input
                                className='setup-input-extended'
                                type="text"
                                name="spa_description"
                                value={spaDescription}
                                onChange={(e) => setSpaDescription(e.target.value)}
                                placeholder="Spa Description"
                            />
                        </div>
                    )}
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function SetupPage7({ formData, setFormData, onSubmit, goBack }) {
    const [selectedExteriorFeatures, setSelectedExteriorFeatures] = useState(formData.exterior_features || []);
    const [selectedInteriorFeatures, setSelectedInteriorFeatures] = useState(formData.interior_features || []);
    const [selectedFlooring, setSelectedFlooring] = useState(formData.flooring || []);
    const [selectedAppliances, setSelectedAppliances] = useState(formData.appliances || []);
    const [selectedKitchen, setSelectedKitchen] = useState(formData.kitchen || []);
    const [isExteriorOpen, setIsExteriorOpen] = useState(false);
    const [isInteriorOpen, setIsInteriorOpen] = useState(false);
    const [isFlooringOpen, setIsFlooringOpen] = useState(false);
    const [isKitchenOpen, setIsKitchenOpen] = useState(false)
    const [isAppliancesOpen, setIsAppliancesOpen] = useState(false);
    const [selectedViews, setSelectedViews] = useState(formData.view || []);
    const [isViewOpen, setIsViewOpen] = useState(false);

    const handleViewChange = (view) => {
        setSelectedViews(prevSelected => {
            if (prevSelected.includes(view)) {
                return prevSelected.filter(item => item !== view);
            } else {
                return [...prevSelected, view];
            }
        });
    };

    const handleFeatureChange = (feature, setSelectedFeatures, selectedFeatures) => {
        setSelectedFeatures(prevSelected => {
            if (prevSelected.includes(feature)) {
                return prevSelected.filter(item => item !== feature);
            } else {
                return [...prevSelected, feature];
            }
        });
    };

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            exterior_features: selectedExteriorFeatures,
            interior_features: selectedInteriorFeatures,
            flooring: selectedFlooring,
            kitchen: selectedKitchen,
            appliances: selectedAppliances,
            view: selectedViews
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>Property Features (cont.)</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>Exterior Features</div>
                        <p className='setup-dropdown-selected-count'>{selectedExteriorFeatures.length > 0 ? selectedExteriorFeatures.length + ' selected' : ''}</p>
                        { isExteriorOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsExteriorOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsExteriorOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isExteriorOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Barn', 'Gas Fire Pit', 'Courtyard', 'Deck', 'Fence', 'Fruit Trees', 'Gazebo', 'Grill', 'Outdoor Fireplace',
                                    'Outdoor Kitchen', 'Outdoor Shower', 'Patio', 'Pond', 'Privacy Hedges', 'Room for Pool', 'Sprinkler system',
                                    'Storage', 'Tennis Court', 'Warehouse'
                                ].map((feature, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedExteriorFeatures.includes(feature) ? 'active' : ''}`}
                                        onClick={() => handleFeatureChange(feature, setSelectedExteriorFeatures, selectedExteriorFeatures)}
                                    >
                                        {feature}
                                    </div>
                                ))}
                            </div>      
                        </>
                    )}
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>Interior Features</div>
                        <p className='setup-dropdown-selected-count'>{selectedInteriorFeatures.length > 0 ? selectedInteriorFeatures.length + ' selected' : ''}</p>
                        { isInteriorOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsInteriorOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsInteriorOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isInteriorOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Bar', 'Closets: Built in shelves', 'Custom Mirrors', 'Disability Equipped', 'Exclusions', 'Fireplace', 'Foyer',
                                    'French Doors', 'Pantry', 'Pocket sliders', 'Surround Sound Wired', 'Volume Ceiling', 'Walk-In Closet', 'Wet Bar',
                                    'Wheelchair Access', 'Window Coverings', 'Zero/Corner Door Sliders'
                                ].map((feature, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedInteriorFeatures.includes(feature) ? 'active' : ''}`}
                                        onClick={() => handleFeatureChange(feature, setSelectedInteriorFeatures, selectedInteriorFeatures)}
                                    >
                                        {feature}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>Flooring</div>
                        <p className='setup-dropdown-selected-count'>{selectedFlooring.length > 0 ? selectedFlooring.length + ' selected' : ''}</p>
                        { isFlooringOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsFlooringOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsFlooringOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isFlooringOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {['Brick', 'Carpet', 'Concrete', 'Laminate', 'Marble', 'Tile', 'Vinyl', 'Wood', 'Other'].map((floor, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedFlooring.includes(floor) ? 'active' : ''}`}
                                        onClick={() => handleFeatureChange(floor, setSelectedFlooring, selectedFlooring)}
                                    >
                                        {floor}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>Kitchen</div>
                        <p className='setup-dropdown-selected-count'>{selectedKitchen.length > 0 ? selectedKitchen.length + ' selected' : ''}</p>
                        { isKitchenOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsKitchenOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsKitchenOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isKitchenOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Island', 'Pantry', 'Walk-in Pantry' 
                                ].map((feature, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedKitchen.includes(feature) ? 'active' : ''}`}
                                        onClick={() => handleFeatureChange(feature, setSelectedKitchen, selectedKitchen)}
                                    >
                                        {feature}
                                    </div>
                                ))}
                            </div>      
                        </>
                    )}
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>Appliances</div>
                        <p className='setup-dropdown-selected-count'>{selectedAppliances.length > 0 ? selectedAppliances.length + ' selected' : ''}</p>
                        { isAppliancesOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsAppliancesOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsAppliancesOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isAppliancesOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Auto Garage Door', 'Cooktop - Electric', 'Cooktop - Gas', 'Dishwasher', 'Disposal', 'Double Oven', 'Dryer', 'Freezer',
                                    'Generator', 'Grill - Gas', 'Home Automation', 'Ice Maker - Stand Alone', 'Microwave', 'Pot Filler', 'Range', 'Refrigerator',
                                    'Refrigerator/Freezer', 'Reverse Osmosis', 'Security System', 'Solar Panels', 'Steam Oven', 'Tankless Water Heater', 'Wall Oven',
                                    'Washer', 'Water Treatment', 'Wine Cooler', 'Other'
                                ].map((appliance, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedAppliances.includes(appliance) ? 'active' : ''}`}
                                        onClick={() => handleFeatureChange(appliance, setSelectedAppliances, selectedAppliances)}
                                    >
                                        {appliance}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>View</div>
                        <p className='setup-dropdown-selected-count'>{selectedViews.length > 0 ? selectedViews.length + ' selected' : ''}</p>
                        { isViewOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsViewOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsViewOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isViewOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Bay', 'Canal', 'City', 'Creek', 'Golf Course', 'Intersecting Canal', 'Landscaped Area',
                                    'Mangroves', 'Pond', 'Pool/Club', 'Preserve', 'Privacy Wall', 'River', 'Tennis Courts',
                                    'Water', 'Water Feature', 'Wooded Area'
                                ].map((view, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedViews.includes(view) ? 'active' : ''}`}
                                        onClick={() => handleViewChange(view)}
                                    >
                                        {view}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function SetupPage8({ formData, setFormData, onSubmit, goBack }) {
    const [heating, setHeating] = useState(formData.heating || '');
    const [cooling, setCooling] = useState(formData.cooling || '');
    const [utilities, setUtilities] = useState(formData.utilities || []);
    const [isUtilitiesOpen, setIsUtilitiesOpen] = useState(false);

    const handleUtilitiesChange = (utility) => {
        setUtilities(prevSelected => {
            if (prevSelected.includes(utility)) {
                return prevSelected.filter(item => item !== utility);
            } else {
                return [...prevSelected, utility];
            }
        });
    };

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            heating: heating,
            cooling: cooling,
            utilities: utilities
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>Utilities and Systems (optional)</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <div className='setup-page4-criteria-title'>Heating</div>
                    <div className='budget-input-container'>
                        <select
                            className='setup-page4-btn'
                            name="heating"
                            value={heating}
                            onChange={(e) => setHeating(e.target.value)}
                            required
                        >
                            <option value="">Select Heating</option>
                            <option value="central">Central</option>
                            <option value="electric">Electric</option>
                            <option value="gas">Gas</option>
                        </select>
                    </div>
                    <div className='setup-page4-criteria-title'>Cooling</div>
                    <div className='budget-input-container'>
                        <select
                            className='setup-page4-btn'
                            name="cooling"
                            value={cooling}
                            onChange={(e) => setCooling(e.target.value)}
                            required
                        >
                            <option value="">Select Cooling</option>
                            <option value="central">Central</option>
                            <option value="electric">Electric</option>
                        </select>
                    </div>
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>Other Utilities</div>
                        <p className='setup-dropdown-selected-count'>{utilities.length > 0 ? utilities.length + ' selected' : ''}</p>
                        { isUtilitiesOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsUtilitiesOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsUtilitiesOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isUtilitiesOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Water', 'Sewage', 'Electrical', 'Gas', 'Internet'
                                ].map((utility, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${utilities.includes(utility) ? 'active' : ''}`}
                                        onClick={() => handleUtilitiesChange(utility)}
                                    >
                                        {utility}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function SetupPage9({ formData, setFormData, onSubmit, goBack }) {
    const [selectedHOA, setSelectedHOA] = useState(formData.hoa || false);
    const [hoaFee, setHoaFee] = useState(formData.hoa_fee || '');
    const [hoaFeeMaster, setHoaFeeMaster] = useState(formData.hoa_fee_master || '');
    const [feeFrequency, setFeeFrequency] = useState(formData.fee_frequency || '');
    const [feeFrequencyMaster, setFeeFrequencyMaster] = useState(formData.fee_frequency_master || '');
    const [applicationFee, setApplicationFee] = useState(formData.application_fee || '');
    const [transferFee, setTrasnerFee] = useState(formData.transfer_fee || '');
    const [selectedHOAAmenities, setSelectedHOAAmenities] = useState(formData.hoa_amenities || []);
    const [isAmenitiesOpen, setIsAmenitiesOpen] = useState(false);
    const [otherFee, setOtherFee] = useState(formData.other_fee|| '');
    const [subdivision, setSubdivision] = useState(formData.subdivision || false);
    const [subdivisionName, setSubdivisionName] = useState(formData.subdivision_name || '');

    const handleHOAChange = (amenity) => {
        setSelectedHOAAmenities(prevSelected => {
            if (prevSelected.includes(amenity)) {
                return prevSelected.filter(item => item !== amenity);
            } else {
                return [...prevSelected, amenity];
            }
        });
    };

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            hoa: selectedHOA,
            subdivision: subdivision,
            subdivision_name: subdivisionName,
            fee_frequency: feeFrequency,
            fee_frequency_master: feeFrequencyMaster,
            other_fee: otherFee,
            hoa_amenities: selectedHOAAmenities
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    // Function to format numbers with commas and add "+" if it's the maximum value
    const formatNumber = (value, max = false, suffix = '') => {
        const formattedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return max && value >= 20000000 ? `$${formattedValue}+` : `$${formattedValue}${suffix}`;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const numValue = Number(value.replace(/[^0-9]/g, '')); // Remove non-numeric characters for internal state
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: numValue
        }));
        if (name === 'hoa_fee') setHoaFee(numValue);
        if (name === 'hoa_fee_master') setHoaFeeMaster(numValue);
        if (name === 'application_fee') setApplicationFee(numValue);
        if (name === 'transfer_fee') setTrasnerFee(numValue);
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>Is there an HOA?</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                <div className='setup-page4-criteria-title'>Subdivision</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${subdivision === false ? 'active' : ''}`}
                            onClick={() => setSubdivision(false)}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${subdivision === true ? 'active' : ''}`}
                            onClick={() => setSubdivision(true)}
                        >
                            Yes
                        </button>
                    </div>
                    {subdivision && (
                        <>
                            <div className='setup-page4-criteria-title'>Subdivision Name</div>
                            <div className='budget-input-container'>
                                <input
                                    className='setup-page4-btn'
                                    type="text"
                                    name="subdivision_name"
                                    value={subdivisionName}
                                    onChange={(e) => setSubdivisionName(e.target.value)}
                                    placeholder="Subdivision Name"
                                />
                            </div>
                        </>
                    )}
                    <div className='setup-page4-criteria-title'>HOA</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedHOA === false ? 'active' : ''}`}
                            onClick={() => setSelectedHOA(false)}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${selectedHOA === true ? 'active' : ''}`}
                            onClick={() => setSelectedHOA(true)}
                        >
                            Yes
                        </button>
                    </div>
                    {selectedHOA && (
                        <>
                            <div className='setup-page-single-input-container'>
                                <h2 className='setup-page-single-input-title'>HOA Fees</h2>
                                <input 
                                    type="text" 
                                    name="hoa_fee" 
                                    min='0' 
                                    max='9999999' 
                                    maxLength='8'
                                    value={formatNumber(hoaFee)} 
                                    onChange={handleInputChange} 
                                    placeholder="0" 
                                    className="setup-page4-btn setup-page-single-input" 
                                    required 
                                />
                            </div>
                            <div className='setup-page-single-input-container'>
                                <h2 className='setup-page-single-input-title'>Fee Frequency</h2>
                                <select
                                    className='setup-page4-btn'
                                    name="fee_frequency"
                                    value={feeFrequency}
                                    onChange={(e) => setFeeFrequency(e.target.value)}
                                    required
                                >
                                    <option value="None">None</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Bi-Annually">Bi-Annually</option>
                                    <option value="Annually">Annually</option>
                                </select>
                            </div>
                            <div className='setup-page-single-input-container'>
                                <h2 className='setup-page-single-input-title'>Master HOA Fee</h2>
                                <input 
                                    type="text" 
                                    name="hoa_fee_master" 
                                    min='0' 
                                    max='9999999' 
                                    maxLength='8'
                                    value={formatNumber(hoaFeeMaster)} 
                                    onChange={handleInputChange} 
                                    placeholder="0" 
                                    className="setup-page4-btn setup-page-single-input" 
                                    required 
                                />
                            </div>
                            <div className='setup-page-single-input-container'>
                                <h2 className='setup-page-single-input-title'>Master HOA Fee Frequency</h2>
                                <select
                                    className='setup-page4-btn'
                                    name="fee_frequency_master"
                                    value={feeFrequencyMaster}
                                    onChange={(e) => setFeeFrequencyMaster(e.target.value)}
                                    required
                                >
                                    <option value="None">None</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Bi-Annually">Bi-Annually</option>
                                    <option value="Annually">Annually</option>
                                </select>
                            </div>
                            <div className='setup-page-single-input-container'>
                                <h2 className='setup-page-single-input-title'>Application Fee</h2>
                                <input 
                                    type="text" 
                                    name="application_fee" 
                                    min='0' 
                                    max='9999999' 
                                    maxLength='8'
                                    value={formatNumber(applicationFee)} 
                                    onChange={handleInputChange} 
                                    placeholder="0" 
                                    className="setup-page4-btn setup-page-single-input" 
                                />
                            </div>
                            <div className='setup-page-single-input-container'>
                                <h2 className='setup-page-single-input-title'>Transfer Fee</h2>
                                <input 
                                    type="text" 
                                    name="transfer_fee" 
                                    min='0' 
                                    max='9999999' 
                                    maxLength='8'
                                    value={formatNumber(transferFee)} 
                                    onChange={handleInputChange} 
                                    placeholder="0" 
                                    className="setup-page4-btn setup-page-single-input" 
                                />
                            </div>
                            <h2 className='setup-page-single-input-title'>Other Fee(s)</h2>
                            <div className='budget-input-container'>
                                <input
                                    className='setup-input-extended'
                                    type="text"
                                    name="other_fee"
                                    value={otherFee}
                                    onChange={(e) => setOtherFee(e.target.value)}
                                    placeholder="other fee(s)?"
                                />
                            </div>
                            <div className='profile-selection-container'>
                                <div className='setup-page4-criteria-title'>HOA Amenities</div>
                                <p className='setup-dropdown-selected-count'>{selectedHOAAmenities.length > 0 ? selectedHOAAmenities.length + ' selected' : ''}</p>
                                { isAmenitiesOpen ? (
                                    <>
                                        <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsAmenitiesOpen(false)}/>
                                    </>
                                ) : (
                                    <>
                                        <ProfileArrow className='profile-arrow' onClick={(e) => setIsAmenitiesOpen(true)}/>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    {isAmenitiesOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Assisted Living Available', 'Basketball', 'BBQ - Picnic', 'Beach Access', 'Beach Club Available', 'Beach Club Included',
                                    'Beauty Salon', 'Bike And Jog Path', 'Bike Storage', 'Billiards', 'Boat Storage', 'Bocce Court', 'Business Center', 'Cabana',
                                    'Clubhouse', 'Common Laundry', 'Community Boat Dock', 'Community Boat Lift', 'Community Boat Ramp', 'Community Boat Slip',
                                    'Community Gulf Boat Access', 'Community Park', 'Community Pool', 'Community Spa/Hot tub', 'Concierge Services', 'Dog Park',
                                    'Electric Vehicle Charging', 'Exercise Room', 'Extra Storage', 'Fish Cleaning Station', 'Fishing Pier', 'Fitness Center Attended',
                                    'Full Service Spa', 'Golf Course', 'Guest Room', 'Hobby Room', 'Horses OK', 'Internet Access', 'Lakefront Beach', 'Lap Pool',
                                    'Library', 'Marina', 'None', 'Other', 'Pickleball', 'Play Area', 'Private Beach Pavilion', 'Private Membership', 'Putting Green',
                                    'Racquetball', 'Restaurant', 'Sauna', 'Shuffleboard', 'Sidewalk', 'Stable - Horse', 'Tennis Court', 'Theater', 'Trash Chute',
                                    'Vehicle Wash Area', 'Volleyball', 'Water Skiing'
                                ].map((amenity, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedHOAAmenities.includes(amenity) ? 'active' : ''}`}
                                        onClick={() => handleHOAChange(amenity)}
                                    >
                                        {amenity}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function SetupPage10({ formData, setFormData, onSubmit, goBack }) {
    const [localAmenities, setLocalAmenities] = useState(formData.local_amenities || []);
    const [publicTransit, setPublicTransit] = useState(formData.public_transit || false);
    const [isLocalAmenitiesOpen, setIsLocalAmenitiesOpen] = useState(false);

    const handleLocalAmenitiesChange = (amenity) => {
        setLocalAmenities(prevSelected => {
            if (prevSelected.includes(amenity)) {
                return prevSelected.filter(item => item !== amenity);
            } else {
                return [...prevSelected, amenity];
            }
        });
    };

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            local_amenities: localAmenities,
            public_transit: publicTransit
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>Neighborhood Information</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>Local Amenities</div>
                        <p className='setup-dropdown-selected-count'>{localAmenities.length > 0 ? localAmenities.length + ' selected' : ''}</p>
                        { isLocalAmenitiesOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsLocalAmenitiesOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsLocalAmenitiesOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isLocalAmenitiesOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Proximity to Schools', 'Parks', 'Shopping Centers', 'Restaurants', 'Access to Public Transportation Nearby'
                                ].map((amenity, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${localAmenities.includes(amenity) ? 'active' : ''}`}
                                        onClick={() => handleLocalAmenitiesChange(amenity)}
                                    >
                                        {amenity}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className='setup-page4-criteria-title'>Access to Public Transit</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${publicTransit === false ? 'active' : ''}`}
                            onClick={() => setPublicTransit(false)}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${publicTransit === true ? 'active' : ''}`}
                            onClick={() => setPublicTransit(true)}
                        >
                            Yes
                        </button>
                    </div>
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function SetupPage11({ formData, setFormData, onSubmit, goBack }) {
    // const [disclosureInformation, setDisclosureInformation] = useState(formData.disclosure_information || '');
    const [description, setDescription] = useState(formData.description || '')
    const [listingAgent, setListingAgent] = useState(formData.listing_agent || false);
    const [agentName, setAgentName] = useState(formData.agent_name || '');
    const [agentPhoneNumber, setAgentPhoneNumber] = useState(formData.agent_phone_number || '');
    const [agentEmail, setAgentEmail] = useState(formData.agent_email || '');
    const [brokerageName, setBrokerageName] = useState(formData.brokerage_name || '');

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            // disclosure_information: disclosureInformation,
            description: description,
            listing_agent: listingAgent,
            agent_name: agentName,
            agent_phone_number: agentPhoneNumber,
            agent_email: agentEmail,
            brokerage_name: brokerageName
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>Additional Information</h1>
                <br></br>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <h2 className='setup-page-single-input-title'>Property Description</h2>
                    <div className='budget-input-container'>
                        <input
                            className='setup-input-extended'
                            type="text"
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Property Description"
                        />
                    </div>
                    {/* <div className='setup-page4-criteria-title'>Disclosure Information</div>
                    <div className='budget-input-container'>
                        <textarea
                            className="setup-input-extended"
                            name="disclosure_information"
                            value={disclosureInformation}
                            onChange={(e) => setDisclosureInformation(e.target.value)}
                            placeholder="Disclosure Information"
                        ></textarea>
                    </div> */}
                    <div className='setup-page4-criteria-title'>Listing Agent</div>
                    <div className='setup-page4-criteria-selections'>
                        <button
                            type="button"
                            className={`setup-toggle-button ${listingAgent === false ? 'active' : ''}`}
                            onClick={() => setListingAgent(false)}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className={`setup-toggle-button ${listingAgent === true ? 'active' : ''}`}
                            onClick={() => setListingAgent(true)}
                        >
                            Yes
                        </button>
                    </div>
                    {listingAgent && (
                        <>
                            <div className='setup-page4-criteria-title'>Agent Name</div>
                            <div className='budget-input-container'>
                                <input
                                    className='setup-page4-btn'
                                    type="text"
                                    name="agent_name"
                                    value={agentName}
                                    onChange={(e) => setAgentName(e.target.value)}
                                    placeholder="Agent Name"
                                />
                            </div>
                            <div className='setup-page4-criteria-title'>Agent Phone Number</div>
                            <div className='budget-input-container'>
                                <input
                                    className='setup-page4-btn'
                                    type="text"
                                    name="agent_phone_number"
                                    value={agentPhoneNumber}
                                    onChange={(e) => setAgentPhoneNumber(e.target.value)}
                                    placeholder="Agent Phone Number"
                                />
                            </div>
                            <div className='setup-page4-criteria-title'>Agent Email</div>
                            <div className='budget-input-container'>
                                <input
                                    className='setup-page4-btn'
                                    type="email"
                                    name="agent_email"
                                    value={agentEmail}
                                    onChange={(e) => setAgentEmail(e.target.value)}
                                    placeholder="Agent Email"
                                />
                            </div>
                            <div className='setup-page4-criteria-title'>Brokerage Name</div>
                            <div className='budget-input-container'>
                                <input
                                    className='setup-page4-btn'
                                    type="text"
                                    name="brokerage_name"
                                    value={brokerageName}
                                    onChange={(e) => setBrokerageName(e.target.value)}
                                    placeholder="Brokerage Name"
                                />
                            </div>
                        </>
                    )}
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function SetupPage12({ formData, setFormData, onSubmit, goBack }) {
    const [askingPrice, setAskingPrice] = useState(formData.asking_price || '');
    const [selectedPurchaseTerms, setSelectedPurchaseTerms] = useState(formData.purchase_terms || []);
    // const [propertyTax, setPropertyTax] = useState(formData.property_tax || '');
    // const [monthlyMortgageEstimate, setMonthlyMortgageEstimate] = useState(formData.monthly_mortgage_estimate || '');

    // Open State Variables
    const [isPurchaseOpen, setIsPurchaseOpen] = useState(false)

    // useEffect(() => {
    //     console.log(askingPrice)
    //     console.log(propertyTax)
    //     console.log(monthlyMortgageEstimate)

    // }, [askingPrice, propertyTax, monthlyMortgageEstimate])
    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            // asking_price: askingPrice,
            // property_tax: propertyTax,
            // monthly_mortgage_estimate: monthlyMortgageEstimate
            purchase_terms: selectedPurchaseTerms,
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    const handlePurchaseTermsChange = (term) => {
        setSelectedPurchaseTerms(prevSelected => {
            if (prevSelected.includes(term)) {
                return prevSelected.filter(item => item !== term);
            } else {
                return [...prevSelected, term];
            }
        });
    };

    // Function to format numbers with commas and add "+" if it's the maximum value
    const formatNumber = (value, max = false, suffix = '') => {
        const formattedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return max && value >= 20000000 ? `$${formattedValue}+` : `$${formattedValue}${suffix}`;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const numValue = Number(value.replace(/[^0-9]/g, '')); // Remove non-numeric characters for internal state
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: numValue
        }));
        if (name === 'asking_price') setAskingPrice(numValue);
        // if (name === 'property_tax') setPropertyTax(numValue);
        // if (name === 'monthly_mortgage_estimate') setMonthlyMortgageEstimate(numValue);
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>Budget and Financing</h1>
                <br></br>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <div className='setup-page-single-input-container'>
                        <h2 className='setup-page-single-input-title'>Asking Price</h2>
                        <input 
                            type="text" 
                            name="asking_price" 
                            min='0' 
                            max='9999999999' 
                            value={formatNumber(askingPrice)} 
                            onChange={handleInputChange} 
                            placeholder="$xxx,xxx" 
                            className="setup-page4-btn setup-page-single-input" 
                            required 
                        />
                    </div>
                    <div className='profile-selection-container'>
                        <div className='setup-page4-criteria-title'>Preferred Terms</div>
                        <p className='setup-dropdown-selected-count'>{selectedPurchaseTerms.length > 0 ? selectedPurchaseTerms.length + ' selected' : ''}</p>
                        { isPurchaseOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsPurchaseOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsPurchaseOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isPurchaseOpen && (
                        <>
                            <div className='setup-dropdown-container'>
                                {[
                                    'Cash', 'Financing/Cash', 'Seller/Creative Financing', 'FHA Mortgage', 'VA Mortgage', 'Closing Cost Negotiable', 'Other'
                                ].map((term, index) => (
                                    <div
                                        key={index}
                                        className={`setup-dropdown-item ${selectedPurchaseTerms.includes(term) ? 'active' : ''}`}
                                        onClick={() => handlePurchaseTermsChange(term)}
                                    >
                                        {term}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    {/* <div className='setup-page-single-input-container'>
                        <h2 className='setup-page-single-input-title'>Property Tax Amount</h2>
                        <input 
                            type="text" 
                            name="property_tax" 
                            min='0' 
                            max='9999999' 
                            value={formatNumber(propertyTax)} 
                            onChange={handleInputChange} 
                            placeholder="$xxx,xxx" 
                            className="setup-page4-btn setup-page-single-input" 
                            required 
                        />
                    </div>
                    <div className='setup-page-single-input-container'>
                        <h2 className='setup-page-single-input-title'>Monthly Mortgage Estimate</h2>
                        <input 
                            type="text" 
                            name="monthly_mortgage_estimate" 
                            min='0' 
                            max='999999999' 
                            value={formatNumber(monthlyMortgageEstimate)} 
                            onChange={handleInputChange} 
                            placeholder="$xxx,xxx" 
                            className="setup-page4-btn setup-page-single-input" 
                            required 
                        />
                    </div> */}
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function SetupPage14({ formData, setFormData, onSubmit, goBack }) {
    const [moveOutTimeline, setMoveOutTimeline] = useState(formData.move_out_timeline || '');
    const [uniqueFeatures, setUniqueFeatures] = useState(formData.other_preferences || '');

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => ({
            ...prevFormData,
            move_out_timeline: moveOutTimeline,
            unique_features: uniqueFeatures
        }));
        onSubmit();
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='setup-pages-main'>
            <div className='setup-page-content'>
                <h1 className='setup-page6-title'>Preferences</h1>
                <form onSubmit={handleNext} className='setup-page-form'>
                    <div className='setup-page4-criteria-title'>Move-Out timeline</div>
                    <div className='budget-input-container'>
                        <select
                            className='setup-page4-btn'
                            name="move_in_timeline"
                            value={moveOutTimeline}
                            onChange={(e) => setMoveOutTimeline(e.target.value)}
                            required
                        >
                            <option value="">Select Timeline</option>
                            <option value="Ready">Ready</option>
                            <option value="2-3 months">2-3 months</option>
                            <option value="4-6 months">4-6 months</option>
                            <option value="6-12 months">6-12 months</option>
                            <option value="12+ months">12+ months</option>
                        </select>
                    </div>
                    <div className='setup-page4-criteria-title'>Unique Features</div>
                    <div className='budget-input-container'>
                        <textarea
                            className="setup-input-extended"
                            name="other_preferences"
                            value={uniqueFeatures}
                            onChange={(e) => setUniqueFeatures(e.target.value)}
                            placeholder="Other Preferences"
                        ></textarea>
                    </div>
                    <div className='setup-page-button-container'>
                        <button className='setup-page-back-btn' onClick={(e) => handleBack()}>Go Back</button>
                        <button type="submit" className='setup-page-next-btn'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

// function SetupPage13({ formData, setFormData, onSubmit, goBack }) {
//     const [images, setImages] = useState(formData.images || []);
//     const [coverPhotoIndex, setCoverPhotoIndex] = useState(formData.cover_photo_index || null);

//     const handleFileChange = (e) => {
//         const files = Array.from(e.target.files);
//         setImages(prevImages => [...prevImages, ...files]);
//     };

//     const handleCoverPhotoChange = (index) => {
//         setCoverPhotoIndex(index);
//     };

//     const handleNext = (event) => {
//         event.preventDefault();
//         const formDataWithImages = new FormData();
//         images.forEach((image, index) => {
//             formDataWithImages.append('images', image);
//         });
//         for (const key in formData) {
//             formDataWithImages.append(key, formData[key]);
//         }
//         formDataWithImages.append('cover_photo_index', coverPhotoIndex);
//         onSubmit(formDataWithImages);
//     };

//     const handleBack = (event) => {
//         goBack();
//     };

//     return (
//         <div className='upload-setup-page-main'>
//             <div className='upload-setup-page-content'>
//                 <h1 className='setup-page6-title'>Upload Images of Your Property</h1>
//                 <form onSubmit={handleNext} className='upload-setup-page-form'>
//                     <div className='upload-btn-container'>
//                         <label className='upload-file-btn'>
//                             Upload
//                             <input type='file' name='images' multiple onChange={handleFileChange} />
//                         </label>
//                         <p>{images.length} Photos</p>
//                     </div>
//                     <div className='uploaded-images-preview'>
//                         <Carousel showThumbs={true}>
//                             {images.map((image, index) => (
//                                 <div key={index} className='uploaded-image-wrapper'>
//                                     <div className='cover-button-container'>
//                                         <button type='button' className={`cover-button ${coverPhotoIndex === index ? 'selected' : ''}`} onClick={() => handleCoverPhotoChange(index)}>
//                                             {coverPhotoIndex === index ? 'Cover Photo' : 'Set as Cover'}
//                                         </button>
//                                     </div>
//                                     <img src={URL.createObjectURL(image)} alt={`Uploaded ${index}`} className='uploaded-image' />
//                                 </div>
//                             ))}
//                         </Carousel>
//                     </div>
//                     <div className='submit-button-container'>
//                         <button className='setup-page-back-btn' onClick={handleBack}>Go Back</button>
//                         <button type='submit' className='submit-btn'>Submit</button>
//                     </div>
//                 </form>
//             </div>
//         </div>
//     );
// }

function SetupPage13({ formData, setFormData, onSubmit, goBack }) {
    const [images, setImages] = useState(formData.images || []);
    const [coverPhotoIndex, setCoverPhotoIndex] = useState(formData.cover_photo_index || null);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setImages(prevImages => [...prevImages, ...files]);
    };

    const handleCoverPhotoChange = (index) => {
        setCoverPhotoIndex(index);
    };

    const handleNext = (event) => {
        event.preventDefault();
        setFormData(prevFormData => {
            const updatedFormData = {
                ...prevFormData,
                images: images,
                cover_photo: coverPhotoIndex === null ? null : images[coverPhotoIndex] ? URL.createObjectURL(images[coverPhotoIndex]) : URL.createObjectURL(images[0]),
                cover_photo_index: coverPhotoIndex
            };
            onSubmit(updatedFormData); // Pass the updated form data to onSubmit
            return updatedFormData;
        });
    };

    const handleBack = (event) => {
        goBack();
    };

    return (
        <div className='upload-setup-page-main'>
            <div className='upload-setup-page-content'>
                <h1 className='setup-page6-title'>Upload Images of Your Property</h1>
                <form onSubmit={handleNext} className='upload-setup-page-form'>
                    <div className='upload-btn-container'>
                        <label className='upload-file-btn'>
                            Upload
                            <input type='file' name='images' multiple onChange={handleFileChange} />
                        </label>
                        <p>{images.length} Photos</p>
                    </div>
                    <div className='uploaded-images-preview'>
                        <Carousel showThumbs={true}>
                            {images.map((image, index) => (
                                <div key={index} className='uploaded-image-wrapper'>
                                    <div className='cover-button-container'>
                                        <button type='button' className={`cover-button ${coverPhotoIndex === index ? 'selected' : ''}`} onClick={() => handleCoverPhotoChange(index)}>
                                            {coverPhotoIndex === index ? 'Cover Photo' : 'Set as Cover'}
                                        </button>
                                    </div>
                                    <img src={URL.createObjectURL(image)} alt={`Uploaded ${index}`} className='uploaded-image' />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <div className='submit-button-container'>
                        <button className='setup-page-back-btn' onClick={handleBack}>Go Back</button>
                        <button type='submit' className='submit-btn'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

  
export default function SellerSetup() {
    const [index, setIndex] = useState(0);
    const [formData, setFormData] = useState({
      property_address: '',
      city: '',
      state: '',
      zipcode: '',
      property_status: '',
      fsbo: false,
      property_type: '',
      bedrooms: '',
      bathrooms: '',
      bathrooms_half: '',
      sqft: '',
      den: '',
      stories_level: '',
      garage: false,
      attached_garage: false,
      garage_spaces: '',
      carport: false,
      carport_spaces: '',
      year_built: '',
      description: '',
      lot_size: '',
      lot_description: '',
      pool: false,
      pool_description: '',
      waterfront: false,
      waterfront_description: '',
      gulf_access: false,
      spa: false,
      spa_description: '',
      view: [],
      exterior_features: [],
      interior_features: [],
      flooring: [],
      kitchen: [],
      appliances: [],
      heating: '',
      cooling: '',
      utilities: [],
      hoa: false,
      hoa_fee: '',
      fee_frequency: '',
      sqft_living: '',
      hoa_fee_master: '',
      fee_frequency_master: '',
      application_fee: '',
      transfer_fee: '',
      other_fee: '',
      hoa_amenities: [],
      subdivision: false,
      subdivision_name: '',
      local_amenities: [],
      public_transit: false,
      disclosure_information: '',
      listing_agent: false,
      agent_name: '',
      agent_phone_number: '',
      agent_email: '',
      brokerage_name: '',
      asking_price: '',
      property_tax: '',
      monthly_mortgage_estimate: '',
      move_out_timeline: '',
      purchase_terms: [],
      images: [],
      cover_photo: '',
      cover_photo_index: null
    });
    // const navigate = useNavigate();

    const handleSubmit = async (updatedFormData) => {
        // Ensure JSON fields are arrays before submission
        const jsonFields = [
            'view', 'exterior_features', 'interior_features', 'flooring', 'kitchen', 'appliances', 
            'hoa_amenities', 'utilities', 'local_amenities', 'purchase_terms'
        ];

        jsonFields.forEach(field => {
            if (!Array.isArray(updatedFormData[field])) {
                updatedFormData[field] = [];
            }
        });

        // Create a FormData object for the POST request
        const formDataWithImages = new FormData();
        for (const key in updatedFormData) {
            if (jsonFields.includes(key)) {
                formDataWithImages.append(key, JSON.stringify(updatedFormData[key]));
            } else {
                formDataWithImages.append(key, updatedFormData[key]);
            }
        }

        // Append images to FormData
        updatedFormData.images.forEach((image) => {
            formDataWithImages.append('images', image);
        });
        formDataWithImages.append('coverPhotoIndex', updatedFormData.cover_photo_index);

        // console.log('Final Form Data:', formDataWithImages);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/seller-criteria`, {
                method: 'POST',
                body: formDataWithImages,
                credentials: 'include'
            });

            if (response.ok) {
                const result = await response.json();
                // console.log('Server response:', result);
                window.location.href='/dashboard';
            } else {
                console.error('Failed to submit form data');
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    };

    const renderPage = () => {
        switch (index) {
            case 0:
                return <SetupPage1 formData={formData} setFormData={setFormData} onSubmit={() => setIndex(index + 1)} />;
            case 1:
                return <SetupPage2 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 2:
                return <SetupPage3 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 3:
                return <SetupPage4 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 4:
                return <SetupPage5 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 5:
                return <SetupPage6 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 6:
                return <SetupPage7 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 7:
                return <SetupPage9 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 8:
                return <SetupPage12 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 9:
                return <SetupPage14 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 10:
                return <SetupPage11 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={() => setIndex(index + 1)} />;
            case 11:
                return <SetupPage13 formData={formData} setFormData={setFormData} goBack={() => setIndex(index - 1)} onSubmit={handleSubmit} />;
            default:
                return <div>Step not found!</div>;
        }
    };

    return (
        <div className='setup-main'>
            <SellerSetupProgressBar currentStep={index} />
            {renderPage()}
        </div>
    );
}
