import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WelcomeImage } from '../../assets/icons/icons';
import './styles.css';

export default function Welcome(props) {
    const navigate = useNavigate();
    
    const handleRoleSelection = async (role, route) => {
        // console.log(`Setting role to ${role}`);
        // console.log(`This is the ${route}`)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/set-role`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ role }),
        });
        // console.log('Response status:', response.status);
        if (response.ok) {
            // console.log('Role set successfully, redirecting...');
            navigate(`/${route}-setup`);  // Redirect based on role
        } else {
            throw new Error('Failed to set role');
        } 
    };


    return (
        <>
            <main className='welcome-main'>
                <div className='welcome-header'>
                    <h1 className='welcome-title'>Welcome to Havyn!</h1>
                    <p className='welcome-subtext'>Your AI-powered partner in finding the perfect home.</p>
                </div>
                <div className='welcome-image'>
                    <WelcomeImage/>
                </div>
                <button className='buyer-button' onClick={() => {
                    handleRoleSelection('user', 'buyer')
                    }}>
                    I'm looking for a home
                </button>
                <button className='seller-button' onClick={() => {
                    handleRoleSelection('user', 'seller')
                    }}>
                    I'm selling a home
                </button>
                <button className='agent-button' onClick={() => {
                    handleRoleSelection('', 'agent')
                    }}>
                    I'm an Agent
                </button>
            </main>
        </>
    );
}

