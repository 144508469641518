import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatBackArrow, ProfileArrow, SetupPage2Image } from '../../assets/icons/icons';
import './style.css';

export default function AgentSetup(props) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [actionSelect, setActionSelect] = useState(false);
    const [selectedOption, setSelectedOption] = useState('buyer');
    const [formData, setFormData] = useState({
        name: '',
        licenseNumber: '',
        brokerage: ''
    });

    useEffect(() => {
        // Check if the user is already an agent
        const checkUserRole = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-role`, {
                    method: 'GET',
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    // if (data.role === 'agent') {
                    //     navigate('/dashboard');
                    // }
                }
            } catch (error) {
                console.error('Error checking user role:', error);
            }
        };

        checkUserRole();
    }, [navigate]);

    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCredentialSubmit = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/submit-agent-credentials`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
                credentials: 'include',
            });
            if (response.ok) {
                setIsVerified(true);
                setActionSelect(true);
            } else {
                throw new Error('Failed to submit credentials');
            }
        } catch (error) {
            console.error('Error submitting credentials:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = () => {
        navigate(`/payment-selection-${selectedOption}`);
    };

    return (
        <>
            <div className="agent-setup-container">
                <div className="header">
                    <ChatBackArrow className="chatwindow-back" onClick={handleBackClick} />
                    <h1 className="header-title">Agent Setup and Verification</h1>
                </div>
                <SetupPage2Image className='premium-image' />
                {!isVerified ? (
                    <>
                        <div className="premium-description">
                            <h2 className="premium-title">Enter Your Agent Credentials</h2>
                            <p className='agent-setup-disclaimer'>Verification may take up to 48hrs*</p>
                        </div>
                        <div className='agent-setup-form-container'>
                            <form className='agent-setup-form'>
                                <label className='agent-input-title'>What is your legal name?</label>
                                <input name="name" value={formData.name} onChange={handleChange} className='agent-input'/>
                                <label className='agent-input-title'>What is your license number?</label>
                                <input name="licenseNumber" value={formData.licenseNumber} onChange={handleChange} className='agent-input'/>
                                <label className='agent-input-title'>What Brokerage are you with?</label>
                                <input name="brokerage" value={formData.brokerage} onChange={handleChange} className='agent-input'/>
                            </form>
                        </div>
                        <div className="purchase-button" onClick={handleCredentialSubmit}>
                            {isLoading ? <div className="spinner" id="spinner"></div> : <div className="verify-button-text">Verify Now</div>}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="premium-description">
                            <h2 className="premium-title">Great! Select an Option Below:</h2>
                        </div>
                    </>
                )}
                {actionSelect && (
                    <>
                        <div className="option" onClick={() => handleOptionClick('buyer')}>
                            <div className={`option-text ${selectedOption === 'buyer' ? 'selected' : ''}`}>
                                <span>Create Buyer Listing</span>
                            </div>
                        </div>
                        <div className="option" onClick={() => handleOptionClick('seller')}>
                            <div className={`option-text ${selectedOption === 'seller' ? 'selected' : ''}`}>
                                <span>Create Seller Listing</span>
                            </div>
                        </div>
                        <div className="purchase-button" onClick={handleSubmit}>
                            <button className="purchase-button-text">Continue</button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
