import React from 'react';
import { createPortal } from 'react-dom';
import ChatSelection from '../components/ChatSelection/ChatSelection';
import NavHub from '../components/NavHub/NavHub';

export default function ChatLog(props) {
    return (
        <>
            <div className='main-section'>
                <div className='chat-screen'>
                    <ChatSelection></ChatSelection>
                </div>
                <NavHub/>
            </div>
        </>
    );
}