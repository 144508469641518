import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ChatBackArrow, EditButton, DeleteIcon, ProfileArrow, SingleFamilyIcon, CondoIcon, ApartmentIcon, TownhouseIcon } from '../../assets/icons/icons';
import ReactSlider from 'react-slider';
import './style.css';

export default function BuyerProfileModal({ buyerProfile, onClose }) {
    const [pageTransition, setPageTransition] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [formData, setFormData] = useState({ 
        ...buyerProfile,
        view: buyerProfile.view || [],
        exterior_features: buyerProfile.exterior_features || [],
        interior_features: buyerProfile.interior_features || [],
        flooring: buyerProfile.flooring || [],
        appliances: buyerProfile.appliances || [],
        hoa_amenities: buyerProfile.hoa_amenities || [],
        purchase_terms: buyerProfile.purchase_terms || []
    });
    const [lotSizeMin, setLotSizeMin] = useState('');
    const [lotSizeMax, setLotSizeMax] = useState('');
    const [selectedPropertyTypes, setSelectedPropertyTypes] = useState(formData.property_type || []);


    // Open state variables
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [isExteriorOpen, setIsExteriorOpen] = useState(false);
    const [isInteriorOpen, setIsInteriorOpen] = useState(false);
    const [isFlooringOpen, setIsFlooringOpen] = useState(false);
    const [isAppliancesOpen, setIsAppliancesOpen] = useState(false);
    const [isHOAAmenitiesOpen, setIsHOAAmenitiesOpen] = useState(false);
    const [isPurchaseOpen, setIsPurchaseOpen] = useState(false)

    // useEffect(()=>{
    //     console.log(formData)   
    // }, [formData])

    useEffect(()=>{
        setLotSizeMin(() =>{
            switch (buyerProfile.min_lot_size) {
            case 0:
                return 'No Min';
            case 10890:
                return '1/4 acre';
            case 21780:
                return '1/2 acre';
            case 43560:
                return '1 acre';
            case 87120:
                return '2 acres';
            case 217800:
                return '5 acres';
            case 435600:
                return '10 acres';
            case 871200:
                return '20 acres';
            case 2178000:
                return '50 acres';
            default:
                return ;
            }
        })
        setLotSizeMax(() =>{
            switch (buyerProfile.max_lot_size) {
            case 0:
                return 'No Max';
            case 10890:
                return '1/4 acre';
            case 21780:
                return '1/2 acre';
            case 43560:
                return '1 acre';
            case 87120:
                return '2 acres';
            case 217800:
                return '5 acres';
            case 435600:
                return '10 acres';
            case 871200:
                return '20 acres';
            case 2178000:
                return '50+ acres';
            default:
                return ;
            }
        })
    }, [buyerProfile.min_lot_size, buyerProfile.max_lot_size])

    const handlePropertyTypeChange = (value) => {
        setSelectedPropertyTypes(prevSelected => {
            if (prevSelected.includes(value)) {
                return prevSelected.filter(type => type !== value);
            } else {
                return [...prevSelected, value];
            }
        });
    };
    
    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            property_type: selectedPropertyTypes
        }));
    }, [selectedPropertyTypes]);

    ////////////////////// SLIDER FUNCTIONS \\\\\\\\\\\\\\\\\\\\\\

    // Function to format numbers with commas and add "+" if it's the maximum value
    const formatNumber = (value, max = false, suffix = '') => {
        const formattedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return max && value >= 20000000 ? `$${formattedValue}+` : `$${formattedValue}${suffix}`;
    };

    // Custom scale transformation
    const transformValueToCustomScale = (value) => {
        if (value <= 1000000) {
            return (value / 1000000) * 50;
        } else {
            return 50 + ((value - 1000000) / (20000000 - 1000000)) * 50;
        }
    };

    // Custom scale inverse transformation
    const transformValueFromCustomScale = (value) => {
        if (value <= 50) {
            return Math.round((value / 50) * 1000000);
        } else {
            return Math.round(1000000 + ((value - 50) / 50) * (20000000 - 1000000));
        }
    };

    // Function to calculate the step based on the current value
    const stepCalculation = (value) => {
        // console.log(`This is the value: ${value}`)
        if (value <= 500000) {
            return 0.5;
        } else if (value > 500000 && value <= 1000000) {
            return 1.25;
        } else if (value > 1000000 && value <= 5000000) {
            return 0.75;
        } else if (value > 5000000 && value <= 10000000){
            return 2;
        } else {
            return 2.5;
        }
    };

    // Price Slider States
    const [minBudget, setMinBudget] = useState(formData.min_budget || 0);
    const [maxBudget, setMaxBudget] = useState(formData.max_budget || 20000000);
    const [step, setStep] = useState(10000);
    const [activeThumb, setActiveThumb] = useState(0);

    useEffect(() => {
        if (formData.min_budget === 0) setMinBudget(0);
        if (formData.max_budget === 0) setMaxBudget(20000000);
    }, [formData.min_budget, formData.max_budget]);

    useEffect(() => {
        if (activeThumb === 0) {
            setStep(stepCalculation(minBudget));
        } else {
            setStep(stepCalculation(maxBudget));
        }
    }, [minBudget, maxBudget, activeThumb]);

    const handleSliderChange = (value) => {
        const [min, max] = value.map(transformValueFromCustomScale);

        setMinBudget(min);
        setMaxBudget(max);

        setFormData(prevFormData => ({
            ...prevFormData,
            min_budget: min,
            max_budget: max >= 20000000 ? 20000000 : max
        }));
    };

    const handlePriceChange = (e) => {
        const { name, value } = e.target;
        const numValue = Number(value.replace(/[^0-9]/g, '')); // Remove non-numeric characters for internal state
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: numValue
        }));
        if (name === 'min_budget') setMinBudget(numValue);
        if (name === 'max_budget') setMaxBudget(numValue);
    };

    //////////////////////////////////////////////////////////////////////////

    

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleMultiSelectChange = (item, field) => {
        setFormData(prevFormData => {
            const selected = prevFormData[field];
            if (selected.includes(item)) {
                return {
                    ...prevFormData,
                    [field]: selected.filter(i => i !== item)
                };
            } else {
                return {
                    ...prevFormData,
                    [field]: [...selected, item]
                };
            }
        });
    };

    const handleSave = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/buyer-criteria/${buyerProfile.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                credentials: 'include'
            });

            if (response.ok) {
                setIsEditing(false);
                window.location.href='/dashboard'
            } else {
                console.error('Failed to update buyer profile');
            }
        } catch (error) {
            console.error('Error updating buyer profile:', error);
        }
    };

    const handleDeleteClick = () => {
        setIsDeleting(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/buyer-criteria/${buyerProfile.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });

            if (response.ok) {
                setIsDeleting(false);
                onClose(); // Close modal after deletion
                window.location.href = '/dashboard';
            } else {
                console.error('Failed to delete buyer profile');
            }
        } catch (error) {
            console.error('Error deleting buyer profile:', error);
        }
    };

    const handleCancelDelete = () => {
        setIsDeleting(false);
    };

    const handlePageTransition = () => {
        setPageTransition(true);
        setTimeout(() => {
            onClose();
        }, 500);
    };

    return createPortal(
        <div className={`listing-details-modal ${pageTransition ? 'leftClose' : 'left'}`}>
            <div className='chatwindow-bg'>
                <div className='chat-window-container'>
                    <div className='listing-details-arrow-container'>
                        <ChatBackArrow className='chatwindow-back' onClick={handlePageTransition} />
                        <h1 className='modal-header'>Buyer Profile</h1>
                        {isEditing ? (
                            <DeleteIcon className='edit-btn' onClick={handleDeleteClick} />
                        ) : (
                            <EditButton className='edit-btn' onClick={handleEditClick} />
                        )}
                    </div>
                    {isEditing ? (
                        <div className='buyer-modal-content'>
                            <form>
                                {/* PROPERTY TYPE */}
                                <p className='modal-section-header'>Property Type</p>
                                <div className="profile-section-divider"></div>
                                <div className='setup-page-property-select-container'>
                                    <div className='setup-page3-selections-row'>
                                        <div
                                            className={`setup-page3-boxes ${selectedPropertyTypes.includes('Single Family') ? 'active' : ''}`}
                                            onClick={() => handlePropertyTypeChange('Single Family')}
                                        >
                                            <SingleFamilyIcon />
                                            <h2 className='setup-page3-boxes-title'>Single Family</h2>
                                        </div>
                                        <div
                                            className={`setup-page3-boxes ${selectedPropertyTypes.includes('Condo') ? 'active' : ''}`}
                                            onClick={() => handlePropertyTypeChange('Condo')}
                                        >
                                            <CondoIcon />
                                            <h2 className='setup-page3-boxes-title'>Condo</h2>
                                        </div>
                                    </div>
                                    <div className='setup-page3-selections-row'>
                                        <div
                                            className={`setup-page3-boxes ${selectedPropertyTypes.includes('Apartment') ? 'active' : ''}`}
                                            onClick={() => handlePropertyTypeChange('Apartment')}
                                        >
                                            <ApartmentIcon />
                                            <h2 className='setup-page3-boxes-title'>Apartment</h2>
                                        </div>
                                        <div
                                            className={`setup-page3-boxes ${selectedPropertyTypes.includes('TownHome') ? 'active' : ''}`}
                                            onClick={() => handlePropertyTypeChange('TownHome')}
                                        >
                                            <TownhouseIcon />
                                            <h2 className='setup-page3-boxes-title'>TownHome</h2>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                {/* LOCATION */}
                                <p className='modal-section-header'>Location</p>
                                <div className="profile-section-divider"></div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>City:</label>
                                    <input
                                        className='buyer-modal-input'
                                        type='text'
                                        name='city'
                                        value={formData.city}
                                        onChange={handleChange}
                                        maxLength={50}
                                    />
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>State:</label>
                                    <input
                                        className='buyer-modal-input'
                                        type='text'
                                        name='state'
                                        value={formData.state}
                                        onChange={handleChange}
                                        maxLength={2}
                                    />
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>ZIP Code:</label>
                                    <input
                                        className='buyer-modal-input'
                                        type='text'
                                        name='zipcode'
                                        value={formData.zipcode}
                                        onChange={handleChange}
                                        maxLength={5}
                                        min={0}
                                        max={99999}
                                    />
                                </div>
                                <br />
                                {/* BUY OR RENT */}
                                <p className='modal-section-header'>Buying or Renting</p>
                                <div className="profile-section-divider"></div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Listing Status:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='listing_status'
                                        value={formData.listing_status}
                                        onChange={handleChange}
                                    >
                                        <option value='for_sale'>Buying</option>
                                        <option value='for_rent'>Renting</option>
                                    </select>
                                </div>
                                <br />
                                {/* HOME CRITERIA */}
                                <p className='modal-section-header'>Home Criteria</p>
                                <div className="profile-section-divider"></div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Bedrooms:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='bedrooms'
                                        value={formData.bedrooms}
                                        onChange={handleChange}
                                    >
                                        <option value={0}>Any</option>
                                        <option value={1}>1+</option>
                                        <option value={2}>2+</option>
                                        <option value={3}>3+</option>
                                        <option value={4}>4+</option>
                                        <option value={5}>5+</option>
                                    </select>
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Bathrooms:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='bathrooms'
                                        value={formData.bathrooms}
                                        onChange={handleChange}
                                    >
                                        <option value={0}>Any</option>
                                        <option value={1}>1+</option>
                                        <option value={2}>2+</option>
                                        <option value={3}>3+</option>
                                        <option value={4}>4+</option>
                                        <option value={5}>5+</option>
                                    </select>
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Min Square Footage:</label>
                                    <input
                                        className='buyer-modal-input'
                                        type='number'
                                        name='min_sqft'
                                        value={formData.min_sqft}
                                        onChange={handleChange}
                                        maxLength={6}
                                        min={0}
                                        max={999999}
                                    />
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Max Square Footage:</label>
                                    <input
                                        className='buyer-modal-input'
                                        type='number'
                                        name='max_sqft'
                                        value={formData.max_sqft}
                                        onChange={handleChange}
                                        maxLength={6}
                                        min={0}
                                        max={999999}
                                    />
                                </div>
                                <br />
                                {/* HOME CRITERIA CONT.*/}
                                <p className='modal-section-header'>Home Criteria cont.</p>
                                <div className="profile-section-divider"></div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Den:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='den'
                                        value={formData.den}
                                        onChange={handleChange}
                                    >
                                        <option value='any'>Any</option>
                                        <option value='1+'>1+</option>
                                        <option value='2+'>2+</option>
                                        <option value='3+'>3+</option>
                                    </select>
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Stories/Level:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='stories_level'
                                        value={formData.stories_level}
                                        onChange={handleChange}
                                    >
                                        <option value='any'>Any</option>
                                        <option value='1'>1</option>
                                        <option value='2+'>2+</option>
                                        <option value='3+'>3+</option>
                                    </select>
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Garage:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='garage'
                                        value={formData.garage}
                                        onChange={handleChange}
                                    >
                                        <option value='any'>Any</option>
                                        <option value='yes'>Yes</option>
                                        <option value='no'>No</option>
                                    </select>
                                </div>
                                {formData.garage === 'yes' && (
                                    <div className='buyer-modal-form-group'>
                                        <label className='buyer-modal-title'>Garage Spaces:</label>
                                        <input
                                            className='buyer-modal-input'
                                            type='number'
                                            name='garage_spaces'
                                            value={formData.garage_spaces}
                                            onChange={handleChange}
                                        />
                                    </div>
                                )}
                                <br />
                                {/* PROPERTY FEATURES */}
                                <p className='modal-section-header'>Property Features</p>
                                <div className="profile-section-divider"></div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Min Lot Size:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='min_lot_size'
                                        value={formData.min_lot_size}
                                        onChange={handleChange}
                                    >
                                        <option value="">No Min</option>
                                        <option value={10890}>1/4 acre</option>
                                        <option value={21780}>1/2 acre</option>
                                        <option value={43560}>1 acre</option>
                                        <option value={87120}>2 acres</option>
                                        <option value={217800}>5 acres</option>
                                        <option value={435600}>10 acres</option>
                                        <option value={871200}>20 acres</option>
                                        <option value={2178000} >50 acres</option>
                                    </select>
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Max Lot Size:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='max_lot_size'
                                        value={formData.max_lot_size}
                                        onChange={handleChange}
                                    >
                                        <option value={10890}>1/4 acre</option>
                                        <option value={21780}>1/2 acre</option>
                                        <option value={43560}>1 acre</option>
                                        <option value={87120}>2 acres</option>
                                        <option value={217800}>5 acres</option>
                                        <option value={435600}>10 acres</option>
                                        <option value={871200}>20 acres</option>
                                        <option value={2178000} >50 acres</option>
                                        <option value="">No Max</option>
                                    </select>
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Pool:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='pool'
                                        value={formData.pool}
                                        onChange={handleChange}
                                    >
                                        <option value='any'>Any</option>
                                        <option value='yes'>Yes</option>
                                        <option value='no'>No</option>
                                    </select>
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Waterfront:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='waterfront'
                                        value={formData.waterfront}
                                        onChange={handleChange}
                                    >
                                        <option value='any'>Any</option>
                                        <option value='yes'>Yes</option>
                                        <option value='no'>No</option>
                                    </select>
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Gulf Access:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='gulf_access'
                                        value={formData.gulf_access}
                                        onChange={handleChange}
                                    >
                                        <option value='any'>Any</option>
                                        <option value='yes'>Yes</option>
                                        <option value='no'>No</option>
                                    </select>
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Spa:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='spa'
                                        value={formData.spa}
                                        onChange={handleChange}
                                    >
                                        <option value='any'>Any</option>
                                        <option value='yes'>Yes</option>
                                        <option value='no'>No</option>
                                    </select>
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Min Year:</label>
                                    <input
                                        className='buyer-modal-input'
                                        type='number'
                                        name='min_year'
                                        value={formData.min_year}
                                        min={0}
                                        max={2024}
                                        maxLength={4}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Max Year:</label>
                                    <input
                                        className='buyer-modal-input'
                                        type='number'
                                        name='max_year'
                                        value={formData.max_year}
                                        min={0}
                                        max={2024}
                                        maxLength={4}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='buyer-modal-dropdown-container'>
                                    <label className='buyer-modal-title'>View:</label>
                                    <p className='setup-dropdown-selected-count'>{formData.view.length > 0 ? formData.view.length + ' selected' : ''}</p>
                                    {isViewOpen ? (
                                        <>
                                            <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsViewOpen(false)} />
                                        </>
                                    ) : (
                                        <>
                                            <ProfileArrow className='profile-arrow' onClick={() => setIsViewOpen(true)} />
                                        </>
                                    )}
                                </div>
                                {isViewOpen && (
                                    <>
                                        <div className='setup-dropdown-container'>
                                            {[
                                                'Bay', 'Canal', 'City', 'Creek', 'Golf Course', 'Intersecting Canal', 'Landscaped Area',
                                                'Mangroves', 'Pond', 'Pool/Club', 'Preserve', 'Privacy Wall', 'River', 'Tennis Courts',
                                                'Water', 'Water Feature', 'Wooded Area'
                                            ].map((view, index) => (
                                                <div
                                                    key={index}
                                                    className={`setup-dropdown-item ${formData.view.includes(view) ? 'active' : ''}`}
                                                    onClick={() => handleMultiSelectChange(view, 'view')}
                                                >
                                                    {view}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                                <br />
                                {/* PROPERTY FEATURES CONT*/}
                                <p className='modal-section-header'>Property Features cont.</p>
                                <div className="profile-section-divider"></div>
                                <div className='buyer-modal-dropdown-container'>
                                    <label className='buyer-modal-title'>Exterior Features:</label>
                                    <p className='setup-dropdown-selected-count'>{formData.exterior_features.length > 0 ? formData.exterior_features.length + ' selected' : ''}</p>
                                    {isExteriorOpen ? (
                                        <>
                                            <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsExteriorOpen(false)} />
                                        </>
                                    ) : (
                                        <>
                                            <ProfileArrow className='profile-arrow' onClick={() => setIsExteriorOpen(true)} />
                                        </>
                                    )}
                                </div>
                                {isExteriorOpen && (
                                    <>
                                        <div className='setup-dropdown-container'>
                                            {[
                                                'Barn', 'Gas Fire Pit', 'Courtyard', 'Deck', 'Fence', 'Fruit Trees', 'Gazebo', 'Grill', 'Outdoor Fireplace',
                                                'Outdoor Kitchen', 'Outdoor Shower', 'Patio', 'Pond', 'Privacy Hedges', 'Room for Pool', 'Sprinkler system',
                                                'Storage', 'Tennis Court', 'Warehouse'
                                            ].map((feature, index) => (
                                                <div
                                                    key={index}
                                                    className={`setup-dropdown-item ${formData.exterior_features.includes(feature) ? 'active' : ''}`}
                                                    onClick={() => handleMultiSelectChange(feature, 'exterior_features')}
                                                >
                                                    {feature}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                                <div className='buyer-modal-dropdown-container'>
                                    <label className='buyer-modal-title'>Interior Features:</label>
                                    <p className='setup-dropdown-selected-count'>{formData.interior_features.length > 0 ? formData.interior_features.length + ' selected' : ''}</p>
                                    {isInteriorOpen ? (
                                        <>
                                            <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsInteriorOpen(false)} />
                                        </>
                                    ) : (
                                        <>
                                            <ProfileArrow className='profile-arrow' onClick={() => setIsInteriorOpen(true)} />
                                        </>
                                    )}
                                </div>
                                {isInteriorOpen && (
                                    <>
                                        <div className='setup-dropdown-container'>
                                            {[
                                                'Bar', 'Closets: Built in shelves', 'Custom Mirrors', 'Disability Equipped', 'Exclusions', 'Fireplace', 'Foyer',
                                                'French Doors', 'Pantry', 'Pocket sliders', 'Surround Sound Wired', 'Volume Ceiling', 'Walk-In Closet', 'Wet Bar',
                                                'Wheelchair Access', 'Window Coverings', 'Zero/Corner Door Sliders'
                                            ].map((feature, index) => (
                                                <div
                                                    key={index}
                                                    className={`setup-dropdown-item ${formData.interior_features.includes(feature) ? 'active' : ''}`}
                                                    onClick={() => handleMultiSelectChange(feature, 'interior_features')}
                                                >
                                                    {feature}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                                <div className='buyer-modal-dropdown-container'>
                                    <label className='buyer-modal-title'>Flooring:</label>
                                    <p className='setup-dropdown-selected-count'>{formData.flooring.length > 0 ? formData.flooring.length + ' selected' : ''}</p>
                                    {isFlooringOpen ? (
                                        <>
                                            <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsFlooringOpen(false)} />
                                        </>
                                    ) : (
                                        <>
                                            <ProfileArrow className='profile-arrow' onClick={() => setIsFlooringOpen(true)} />
                                        </>
                                    )}
                                </div>
                                {isFlooringOpen && (
                                    <>
                                        <div className='setup-dropdown-container'>
                                            {['Brick', 'Carpet', 'Concrete', 'Laminate', 'Marble', 'Tile', 'Vinyl', 'Wood', 'Other'].map((floor, index) => (
                                                <div
                                                    key={index}
                                                    className={`setup-dropdown-item ${formData.flooring.includes(floor) ? 'active' : ''}`}
                                                    onClick={() => handleMultiSelectChange(floor, 'flooring')}
                                                >
                                                    {floor}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                                <div className='buyer-modal-dropdown-container'>
                                    <label className='buyer-modal-title'>Appliances:</label>
                                    <p className='setup-dropdown-selected-count'>{formData.appliances.length > 0 ? formData.appliances.length + ' selected' : ''}</p>
                                    {isAppliancesOpen ? (
                                        <>
                                            <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsAppliancesOpen(false)} />
                                        </>
                                    ) : (
                                        <>
                                            <ProfileArrow className='profile-arrow' onClick={() => setIsAppliancesOpen(true)} />
                                        </>
                                    )}
                                </div>
                                {isAppliancesOpen && (
                                    <>
                                        <div className='setup-dropdown-container'>
                                            {[
                                                'Auto Garage Door', 'Cooktop - Electric', 'Cooktop - Gas', 'Dishwasher', 'Disposal', 'Double Oven', 'Dryer', 'Freezer',
                                                'Generator', 'Grill - Gas', 'Home Automation', 'Ice Maker - Stand Alone', 'Microwave', 'Pot Filler', 'Range', 'Refrigerator',
                                                'Refrigerator/Freezer', 'Reverse Osmosis', 'Security System', 'Solar Panels', 'Steam Oven', 'Tankless Water Heater', 'Wall Oven',
                                                'Washer', 'Water Treatment', 'Wine Cooler', 'Other'
                                            ].map((appliance, index) => (
                                                <div
                                                    key={index}
                                                    className={`setup-dropdown-item ${formData.appliances.includes(appliance) ? 'active' : ''}`}
                                                    onClick={() => handleMultiSelectChange(appliance, 'appliances')}
                                                >
                                                    {appliance}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                                <br />
                                {/* HOME OWNERS ASSOCIATION */}
                                <p className='modal-section-header'>Home Owners Association</p>
                                <div className="profile-section-divider"></div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>HOA:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='hoa'
                                        value={formData.hoa}
                                        onChange={handleChange}
                                    >
                                        <option value='any'>Any</option>
                                        <option value='yes'>Yes</option>
                                        <option value='no'>No</option>
                                    </select>
                                </div>
                                {formData.hoa === 'yes' && (
                                    <>
                                        <div className='buyer-modal-form-group'>
                                            <label className='buyer-modal-title'>Max HOA:</label>
                                            <input
                                                className='buyer-modal-input'
                                                type='number'
                                                name='max_hoa'
                                                value={formData.max_hoa}
                                                onChange={handleChange}
                                                min={0}
                                                max={999999}
                                                maxLength={6}
                                            />
                                        </div>
                                        <div className='buyer-modal-form-group'>
                                            <label className='buyer-modal-title'>Fee Frequency:</label>
                                            <select
                                                className='buyer-modal-input'
                                                name='fee_frequency'
                                                value={formData.fee_frequency}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Frequency</option>
                                                <option value="Quarterly">Quarterly</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Bi-Annually">Bi-Annually</option>
                                                <option value="Annually">Annually</option>
                                            </select>
                                        </div>
                                    </>
                                )}
                                {formData.hoa === 'yes' && (
                                    <>
                                        <div className='buyer-modal-dropdown-container'>
                                            <label className='buyer-modal-title'>HOA Amenities:</label>
                                            <p className='setup-dropdown-selected-count'>{formData.hoa_amenities.length > 0 ? formData.hoa_amenities.length + ' selected' : ''}</p>
                                            {isHOAAmenitiesOpen ? (
                                                <>
                                                    <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsHOAAmenitiesOpen(false)} />
                                                </>
                                            ) : (
                                                <>
                                                    <ProfileArrow className='profile-arrow' onClick={() => setIsHOAAmenitiesOpen(true)} />
                                                </>
                                            )}
                                        </div>
                                        {isHOAAmenitiesOpen && (
                                            <div className='setup-dropdown-container'>
                                                {[
                                                    'Assisted Living Available', 'Basketball', 'BBQ - Picnic', 'Beach Access', 'Beach Club Available', 'Beach Club Included',
                                                    'Beauty Salon', 'Bike And Jog Path', 'Bike Storage', 'Billiards', 'Boat Storage', 'Bocce Court', 'Business Center', 'Cabana',
                                                    'Clubhouse', 'Common Laundry', 'Community Boat Dock', 'Community Boat Lift', 'Community Boat Ramp', 'Community Boat Slip',
                                                    'Community Gulf Boat Access', 'Community Park', 'Community Pool', 'Community Spa/Hot tub', 'Concierge Services', 'Dog Park',
                                                    'Electric Vehicle Charging', 'Exercise Room', 'Extra Storage', 'Fish Cleaning Station', 'Fishing Pier', 'Fitness Center Attended',
                                                    'Full Service Spa', 'Golf Course', 'Guest Room', 'Hobby Room', 'Horses OK', 'Internet Access', 'Lakefront Beach', 'Lap Pool',
                                                    'Library', 'Marina', 'None', 'Other', 'Pickleball', 'Play Area', 'Private Beach Pavilion', 'Private Membership', 'Putting Green',
                                                    'Racquetball', 'Restaurant', 'Sauna', 'Shuffleboard', 'Sidewalk', 'Stable - Horse', 'Tennis Court', 'Theater', 'Trash Chute',
                                                    'Vehicle Wash Area', 'Volleyball', 'Water Skiing'
                                                ].map((amenity, index) => (
                                                    <div
                                                        key={index}
                                                        className={`setup-dropdown-item ${formData.hoa_amenities.includes(amenity) ? 'active' : ''}`}
                                                        onClick={() => handleMultiSelectChange(amenity, 'hoa_amenities')}
                                                    >
                                                        {amenity}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </>
                                )}
                                <br />
                                {/* BUDGET AND FINANCING */}
                                <p className='modal-section-header'>Budget and Financing</p>
                                <div className="profile-section-divider"></div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Min Budget:</label>
                                    <input
                                        className='buyer-modal-input'
                                        type='text'
                                        name='min_budget'
                                        value={formData.min_budget === 0 ? 'Any' : formatNumber(formData.min_budget)}
                                        onChange={handlePriceChange}
                                        min={0}
                                        max={999999999}
                                        maxLength={10}
                                    />
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Max Budget:</label>
                                    <input
                                        className='buyer-modal-input'
                                        type='text'
                                        name='max_budget'
                                        value={formData.max_budget === 20000000 ? 'Any' : formatNumber(formData.max_budget)}
                                        onChange={handlePriceChange}
                                        min={0}
                                        max={999999999}
                                        maxLength={10}
                                    />
                                </div>
                                <ReactSlider
                                    className="horizontal-slider"
                                    thumbClassName="example-thumb"
                                    trackClassName="example-track"
                                    defaultValue={[transformValueToCustomScale(0), transformValueToCustomScale(20000000)]}
                                    ariaLabel={['Lower thumb', 'Upper thumb']}
                                    ariaValuetext={state => `Thumb value ${state.valueNow}`}
                                    onBeforeChange={(value, index) => setActiveThumb(index)}
                                    onChange={handleSliderChange}
                                    min={0}
                                    max={100}
                                    value={[transformValueToCustomScale(minBudget), transformValueToCustomScale(maxBudget)]}
                                    step={step}
                                />
                                <div className='buyer-modal-dropdown-container'>
                                    <label className='buyer-modal-title'>Purchase Terms:</label>
                                    <p className='setup-dropdown-selected-count'>{formData.purchase_terms.length > 0 ? formData.purchase_terms.length + ' selected' : ''}</p>
                                    {isPurchaseOpen ? (
                                        <>
                                            <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsPurchaseOpen(false)} />
                                        </>
                                    ) : (
                                        <>
                                            <ProfileArrow className='profile-arrow' onClick={() => setIsPurchaseOpen(true)} />
                                        </>
                                    )}
                                </div>
                                { isPurchaseOpen && (
                                    <>
                                        <div className='setup-dropdown-container'>
                                            {[
                                                'Cash Only', 'Financing/Cash', 'Seller Financing', 'FHA Mortgage', 'VA Mortgage', 'Other'
                                            ].map((term, index) => (
                                                <div
                                                    key={index}
                                                    className={`setup-dropdown-item ${formData.purchase_terms.includes(term) ? 'active' : ''}`}
                                                    onClick={() => handleMultiSelectChange(term, 'purchase_terms')}
                                                >
                                                    {term}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Pre-Approved:</label>
                                    <div className='setup-page-pre-approved-container'>
                                        <button
                                            type="button"
                                            className={`setup-pre-approved-btn ${formData.pre_approved === 1 ? 'active' : ''}`}
                                            onClick={() => setFormData(prevFormData => ({ ...prevFormData, pre_approved: 1 }))}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            type="button"
                                            className={`setup-pre-approved-btn ${formData.pre_approved === 0 ? 'active' : ''}`}
                                            onClick={() => setFormData(prevFormData => ({ ...prevFormData, pre_approved: 0 }))}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Proof of Funds:</label>
                                    <div className='setup-page-pre-approved-container'>
                                        <button
                                            type="button"
                                            className={`setup-pre-approved-btn ${formData.proof_of_funds === 1 ? 'active' : ''}`}
                                            onClick={() => setFormData(prevFormData => ({ ...prevFormData, proof_of_funds: 1 }))}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            type="button"
                                            className={`setup-pre-approved-btn ${formData.proof_of_funds === 0 ? 'active' : ''}`}
                                            onClick={() => setFormData(prevFormData => ({ ...prevFormData, proof_of_funds: 0 }))}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                                <br />
                                {/* PREFRENCES */}
                                <p className='modal-section-header'>Preferences</p>
                                <div className="profile-section-divider"></div>
                                <div className='buyer-modal-form-group'>
                                    <label className='buyer-modal-title'>Move-in Timeline:</label>
                                    <select
                                        className='buyer-modal-input'
                                        name='move_in_timeline'
                                        value={formData.move_in_timeline}
                                        onChange={handleChange}
                                    >
                                        <option value=''>Select Timeline</option>
                                        <option value='Immediate'>Immediate</option>
                                        <option value='2-3 months'>2-3 months</option>
                                        <option value='4-6 months'>4-6 months</option>
                                        <option value='6-12 months'>6-12 months</option>
                                        <option value='12+ months'>12+ months</option>
                                    </select>
                                </div>
                                <div className='buyer-modal-form-group-extended'>
                                    <label className='buyer-modal-title'>Other Preferences:</label>
                                    <textarea
                                        className='buyer-modal-input-extended'
                                        name='other_preferences'
                                        value={formData.other_preferences}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='delete-modal-button-container'>
                                    <button type='button' className='buyer-modal-cancel-btn ' onClick={() => setIsEditing(false)}>Cancel</button>
                                    <button type='button' className='buyer-modal-save-btn ' onClick={handleSave}>Save</button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div className='buyer-modal-content'>
                            <p className='modal-section-header'>Property Types</p>
                            <div className="profile-section-divider"></div>
                            <div className='buyer-modal-title'> 
                                <div className='buyer-modal-bubble-container'>
                                {buyerProfile.property_type.map((term, index) => (
                                    <span key={index} className='buyer-modal-boxes active'>
                                        {(() => {
                                            switch (term) {
                                                case 'Single Family':
                                                    return (
                                                        <>
                                                            <SingleFamilyIcon />
                                                            <span className='setup-page3-boxes-title'>Single Family</span>
                                                        </>
                                                    )
                                                case 'Condo':
                                                    return (
                                                        <>
                                                            <CondoIcon />
                                                            <span className='setup-page3-boxes-title'>Condo</span>
                                                        </>
                                                    )
                                                case 'Apartment':
                                                    return (
                                                        <>
                                                            <ApartmentIcon />
                                                            <span className='setup-page3-boxes-title'>Apartment</span>
                                                        </>
                                                    )
                                                case 'TownHome':
                                                    return (
                                                        <>
                                                            <TownhouseIcon />
                                                            <span className='setup-page3-boxes-title'>TownHome</span>
                                                        </>
                                                    )
                                                default:
                                                    return null; 
                                            }
                                        })()}
                                    </span>
                                ))}
                                </div>
                            </div>
                            <br />
                            <p className='modal-section-header'>Location</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>City: <span className='buyer-modal-text'>{buyerProfile.city}</span></p>
                            <p className='buyer-modal-title'>State: <span className='buyer-modal-text'>{buyerProfile.state}</span></p>
                            <p className='buyer-modal-title'>ZIP Code: <span className='buyer-modal-text'>{buyerProfile.zipcode}</span></p>
                            <br />
                            <p className='modal-section-header'>Buying or Renting</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'><span className='term-bubble'>{buyerProfile.listing_status === 'for_sale' ? 'Buying' : 'Renting'}</span></p>
                            <br />
                            <p className='modal-section-header'>Home Criteria</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>Bedrooms: <span className='buyer-modal-text'>{buyerProfile.bedrooms}+</span></p>
                            <p className='buyer-modal-title'>Bathrooms: <span className='buyer-modal-text'>{buyerProfile.bathrooms}+</span></p>
                            <p className='buyer-modal-title'>Min Square Footage: <span className='buyer-modal-text'>{buyerProfile.min_sqft} sqft</span></p>
                            <p className='buyer-modal-title'>Max Square Footage: <span className='buyer-modal-text'>{buyerProfile.max_sqft} sqft</span></p>
                            <br />
                            <p className='modal-section-header'>Home Criteria cont.</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>Den: <span className='buyer-modal-text'>{buyerProfile.den === 0 ? 'any' : buyerProfile.den}+</span></p>
                            <p className='buyer-modal-title'>Stories/Levels: <span className='buyer-modal-text'>{buyerProfile.stories_level === 0 ? 'any' : buyerProfile.stories_level}+</span></p>
                            <p className='buyer-modal-title'>Garage: <span className='buyer-modal-text'>{buyerProfile.garage === 'any' ? 'Any' : buyerProfile.garage === 'yes' ? 'Yes' : 'No'}</span></p>
                            {buyerProfile.garage === 'yes' && <p className='buyer-modal-title'>Garage Spaces: <span className='buyer-modal-text'>{buyerProfile.garage_spaces}+</span></p>}
                            <br />
                            <p className='modal-section-header'>Property Features</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>Min Lot Size: <span className='buyer-modal-text'>{lotSizeMin}</span></p>
                            <p className='buyer-modal-title'>Max Lot Size: <span className='buyer-modal-text'>{lotSizeMax}</span></p>
                            <p className='buyer-modal-title'>Pool: <span className='buyer-modal-text'>{buyerProfile.pool === 'any' ? 'Any' : buyerProfile.pool === 'yes' ? 'Yes' : 'No'}</span></p>
                            <p className='buyer-modal-title'>Waterfront: <span className='buyer-modal-text'>{buyerProfile.waterfront === 'any' ? 'Any' : buyerProfile.waterfront === 'yes' ? 'Yes' : 'No'}</span></p>
                            <p className='buyer-modal-title'>Gulf Access: <span className='buyer-modal-text'>{buyerProfile.gulf_access === 'any' ? 'Any' : buyerProfile.gulf_access === 'yes' ? 'Yes' : 'No'}</span></p>
                            <p className='buyer-modal-title'>Spa: <span className='buyer-modal-text'>{buyerProfile.spa === 'any' ? 'Any' : buyerProfile.spa === 'yes' ? 'Yes' : 'No'}</span></p>
                            <p className='buyer-modal-title'>Min Year: <span className='buyer-modal-text'>{buyerProfile.min_year}</span></p>
                            <p className='buyer-modal-title'>Max Year: <span className='buyer-modal-text'>{buyerProfile.max_year}</span></p>
                            <br />
                            <p className='modal-section-header'>Property Features cont.</p>
                            <div className="profile-section-divider"></div>
                            <div className='buyer-modal-title'>Exterior Features: 
                                <div className='buyer-modal-bubble-container'>
                                    {buyerProfile.exterior_features.map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className='buyer-modal-title'>Interior Features: 
                                <div className='buyer-modal-bubble-container'>
                                    {buyerProfile.interior_features.map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className='buyer-modal-title'>Flooring: 
                                <div className='buyer-modal-bubble-container'>
                                    {buyerProfile.flooring.map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className='buyer-modal-title'>Appliances: 
                                <div className='buyer-modal-bubble-container'>
                                    {buyerProfile.appliances.map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className='buyer-modal-title'>View: 
                                <div className='buyer-modal-bubble-container'>
                                    {buyerProfile.view.map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <br />
                            <p className='modal-section-header'>Home Owners Association</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>HOA: <span className='buyer-modal-text'>{buyerProfile.hoa === 'any' ? 'Any' : buyerProfile.hoa === 'yes' ? 'Yes' : 'No'}</span></p>
                            {   buyerProfile.hoa === 'yes' && (
                                <>
                                    <p className='buyer-modal-title'>Max HOA Fee(s): <span className='buyer-modal-text'>${buyerProfile.max_hoa}</span></p>
                                    <p className='buyer-modal-title'>Fee Frequency: <span className='buyer-modal-text'>{buyerProfile.fee_frequency}</span></p>
                                </>
                            )}
                            {   buyerProfile.hoa === 'yes' && 
                                <div className='buyer-modal-title'>HOA Amenities: 
                                    <div className='buyer-modal-bubble-container'>
                                        {buyerProfile.hoa_amenities.map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                    ))}
                                    </div>
                                </div>
                            }
                            <br />
                            <p className='modal-section-header'>Budget and Financing</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>Min Budget: <span className='buyer-modal-text'>{formatNumber(buyerProfile.min_budget)}</span></p>
                            <p className='buyer-modal-title'>Max Budget: <span className='buyer-modal-text'>{formatNumber(buyerProfile.max_budget)}</span></p>
                            <div className='buyer-modal-title'>Purchase Terms: 
                                <div className='buyer-modal-bubble-container'>
                                    {buyerProfile.purchase_terms.map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <p className='buyer-modal-title'>Pre-Approved: <span className='buyer-modal-text'>{buyerProfile.pre_approved ? 'Yes' : 'No'}</span></p>
                            <p className='buyer-modal-title'>Proof of Funds: <span className='buyer-modal-text'>{buyerProfile.proof_of_funds ? 'Yes' : 'No'}</span></p>
                            <br />
                            <p className='modal-section-header'>Preferences</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>Move-in Timeline: <span className='buyer-modal-text'>{buyerProfile.move_in_timeline}</span></p>
                            <p className='buyer-modal-title'>Other Preferences: <span className='buyer-modal-text'><br />{buyerProfile.other_preferences}</span></p>
                        </div>
                    )}
                    {isDeleting && createPortal(
                        <div className='delete-confirmation-modal'>
                            <div className='delete-confirmation-content'>
                                <p className='dashboard-title'>Are you sure you want to <strong>delete</strong> this buyer profile?</p>
                                <div className='delete-modal-button-container'>
                                    <button className='delete-cancel-btn' onClick={handleCancelDelete}>Cancel</button>
                                    <button className='delete-confirm-btn' onClick={handleDeleteConfirm}>Yes, Delete</button>
                                </div>
                            </div>
                        </div>,
                        document.getElementById('modal-root')
                    )}
                </div>
            </div>
        </div>,
        document.getElementById('modal-root')
    );
}
