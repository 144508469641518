import React from 'react';
import BuyerSetup from '../components/BuyerSetup/BuyerSetup';

export default function BuyerSetupPage(props) {
    return (
        <>
            <div className='main-section'>
                <BuyerSetup/>
            </div>
        </>
    );
}