import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import './styles.css';
import Pfp from '../../assets/images/EmilyCarter.png';
import { ProfileArrow } from '../../assets/icons/icons';

export function TransactionList({ transactionData }) {
    
    return (
        <>
            <div className='transaction-section'>
                {transactionData.map((transactionData) => (
                    <div key={transactionData.id} className='transaction-container'>
                        <div className='transaction-top-container'>
                            {transactionData.type === 'buyer' ? (
                                <p className='transaction-title'>Buyer Listing</p>
                            ) : (
                                <p className='transaction-title'>Home Listing</p>   
                            )}
                            {/* <p className='transaction-text'>{transactionData.type}</p> */}
                            <p className='transaction-price'>${transactionData.amount}</p>
                        </div>
                        <div>
                            <p className='transaction-text'>Purchase Date: {new Date(transactionData.created_at).toLocaleDateString()}</p>
                            {transactionData.type === 'buyer' && (
                                <p className='transaction-text'>Expiration Date: {new Date(new Date(transactionData.created_at).getTime() + 60*24*60*60*1000).toLocaleDateString()}</p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

// Delete Data Prompt
export function DeleteDataPrompt(props) {
    return (
        <>
            <p>aRe YoU sUrE aBoUt ThAt?</p>
        </>
    )
}

// Agent Info Field
export function AgentInfoPrompt({agentData}) {
    return (
        <>
            <div className='transaction-container'>
                <p className='agent-info-title'>Agent Name: <span className='agent-info-text'>{agentData.name}</span></p>
                <p className='agent-info-title'>License Number: <span className='agent-info-text'>{agentData.credentialNumber}</span></p>
                <p className='agent-info-title'>Brokerage: <span className='agent-info-text'>{agentData.brokerage}</span></p>
            </div>
            <p className='agent-setup-disclaimer'>Report an error? Please email: support@havyn.com</p>
        </>
    )
}

// Terms of use field
export function TermsOfUsePrompt(props) {
    return (
        <>
            <div className=''>
                <h1>Havyn Terms of Use</h1>
                <p className='transaction-text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>
        </>
    )
}

// Privacy Policy
export function PrivacyPolicyPrompt(props) {
    return (
        <>
            <div className=''>
                <h1>Havyn Privacy Policy</h1>
                <p className='transaction-text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>
        </>
    )
}

// Logout Functionality
export function LogoutOption(props) {
    const handleLogout = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (response.ok) {
                window.location.href = '/login'; // Redirect to login page after successful logout
            } else {
                throw new Error('Failed to logout');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };
    return (
        <>
            <div className='logout-section'>
                <p className='agent-info-text'>Are you sure you want to <strong>logout?</strong></p>
                <button className='logout-button' onClick={handleLogout}>Logout</button>
            </div>
        </>
    )
}

export function Account({ userData ,transactionData, accountOpen, isAgent, agentData }) {
    const [isOpen, setIsOpen] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [isAgentInfoOpen, SetIsAgentInfoOpen] = useState(false)
    const [isTermsOfUse, SetIsTermsOfUse] = useState(false)
    const [isPrivacyPolicy, SetIsPrivacyPolicy] = useState(false)
    const [isLogoutOpen, setIsLogoutOpen] = useState(false);

    return (
        <>  
            <div className='profile-section-divider'></div>
            <div className='profile-section'>
                <div className='profile-selection-section-container'>
                    <div className='profile-selection-container' >
                        <div className='profile-selection-name'>
                            <p className='profile-selection-text'>Purchase History</p>
                        </div>
                        { isOpen ? (
                            <>
                                <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsOpen(false)}/>
                            </>
                        ) : (
                            <>
                                <ProfileArrow className='profile-arrow' onClick={(e) => setIsOpen(true)}/>
                            </>
                        )}
                    </div>
                    { isOpen ? (
                        <TransactionList userData={userData} transactionData={transactionData} accountOpen={accountOpen}/>
                    ): (
                        <></>
                    )}
                    { isAgent ? (
                        <>
                            <div className='profile-selection-container'>
                                <div className='profile-selection-name'>
                                    <p className='profile-selection-text'>Agent Information</p>
                                </div>
                                { isAgentInfoOpen ? (
                                    <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => SetIsAgentInfoOpen(false)}/>
                                ) : (
                                    <ProfileArrow className='profile-arrow' onClick={(e) => SetIsAgentInfoOpen(true)}/>
                                )}
                            </div>
                        </> ) : (
                        <>

                        </>
                    )}
                    { isAgentInfoOpen ? (
                        <>
                            <AgentInfoPrompt agentData={agentData}/>
                        </>
                    ) : (
                        <>

                        </>
                    )}
                    <div className='profile-selection-container'>
                        <div className='profile-selection-name'>
                            <p className='profile-selection-text'>Terms of Use</p>
                        </div>
                        { isTermsOfUse ? (
                            <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => SetIsTermsOfUse(false)}/>
                        ) : (
                            <ProfileArrow className='profile-arrow' onClick={(e) => SetIsTermsOfUse(true)}/>
                        )}
                    </div>
                    { isTermsOfUse ? (
                        <>
                            <TermsOfUsePrompt/>
                        </>
                    ) : (
                        <>

                        </>
                    )}
                    <div className='profile-selection-container'>
                        <div className='profile-selection-name'>
                            <p className='profile-selection-text'>Privacy Policy</p>
                        </div>
                        { isPrivacyPolicy ? (
                            <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => SetIsPrivacyPolicy(false)}/>
                        ) : (
                            <ProfileArrow className='profile-arrow' onClick={(e) => SetIsPrivacyPolicy(true)}/>
                        )}
                    </div>
                    { isPrivacyPolicy ? (
                        <>
                            <PrivacyPolicyPrompt/>
                        </>
                    ) : (
                        <>

                        </>
                    )}
                    <div className='profile-selection-container'>
                        <div className='profile-selection-name'>
                            <p className='profile-selection-text'>Logout</p>
                        </div>
                        { isLogoutOpen ? (
                            <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsLogoutOpen(false)}/>
                        ) : (
                            <ProfileArrow className='profile-arrow' onClick={(e) => setIsLogoutOpen(true)}/>
                        )}
                    </div>
                    { isLogoutOpen ? (
                        <>
                            <LogoutOption/>
                        </>
                    ) : (
                        <>

                        </>
                    )}
                </div>
            </div>
        </>
    );
}

// export function Favorite() {
//     return (
//         <>
//             <div className='profile-section-divider'></div>
//             <div className='profile-section'>
//                 <div className='profile-selection-section-container'>
//                     <div className='profile-favorites-container'>
//                         <div className='profile-favorites-img'>
//                             <img className='profile-img' alt='this is an alt tag' sr></img>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

export default function Settings(props) {
    let [AccountIsOpen, setAccountIsOpen] = useState(true);
    const [user, setUser] = useState({});
    const [agentData, setAgentData] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [isAgent, setIsAgent] = useState(false)
    
    // useEffect(() => {
    // }, [AccountIsOpen]);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-profile`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include', // Needed to send the cookie with the request
                });
                if (response.ok) {
                    const data = await response.json();
                    if (data.role === 'agent') {
                        setIsAgent(true)
                        setAgentData(data.agentData)
                    }
                    setUser(data);
                } else {
                    throw new Error('Failed to fetch');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        const fetchUserTransactions = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-transactions`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include', // Needed to send the cookie with the request
                });
                if (response.ok) {
                    const data = await response.json();
                    setTransactions(data);
                } else {
                    throw new Error('Failed to fetch transactions');
                }
            } catch (error) {
                console.error('Error fetching transactions:', error);
            }
        };

        fetchUserProfile();
        fetchUserTransactions();
    }, []);

    if (AccountIsOpen === true ) {
        return (
            <>
                <div className='profile-page-main-container'>
                    <div className='profile-info-container'>
                        <div className='profile-image-container'>
                            <img className='profile-img' alt='persons face' src={user.profilePicture || Pfp}></img>
                        </div>
                        <div className='profile-name-card-container'>
                            <h1 className='profile-name-card'>{user.displayName || 'User Name'}</h1>
                        </div>
                    </div>
                    <div className='profile-account-label'>
                        <h1 id='first-account-header' className={AccountIsOpen ? 'profile-account-header' : 'profile-account-header-false'} onClick={ event => setAccountIsOpen(true)}>Settings</h1>
                    </div>
                    <Account userData={user} transactionData={transactions} accountOpen={AccountIsOpen} isAgent={isAgent} agentData={agentData}/>
                </div> 
            </>
        );
    } 
}


{/* ADD THIS BACK FOR DELETE DATA OPTION  */}
{/* <div className='profile-selection-container'>
    <div className='profile-selection-name'>
        <p className='profile-selection-text'>Delete My Data</p>
    </div>
    { isDeleteOpen ? (
        <ProfileArrow className='profile-arrow-collapsed' onClick={(e) => setIsDeleteOpen(false)}/>
    ) : (
        <ProfileArrow className='profile-arrow' onClick={(e) => setIsDeleteOpen(true)}/>
    )}
</div>
{ isDeleteOpen ? (
    <>
        <DeleteDataPrompt/>
    </>
) : (
    <>

    </>
)} */}