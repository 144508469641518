import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackIcon, ChatBackArrow, PreApprovedIcon, ProfileArrow, SetupPage2Image } from '../../assets/icons/icons';
// import premiumImage from '../../assets/images/premiumImage.png';
import './style.css';

export default function PaymentSelectionBuyer(props) {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('buyer');

    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const handleSubmit = () => {
        // console.log(selectedOption)
        navigate(`/payment-${selectedOption}`)
    }

    return (
        <div className='payment-content'>
            <div className='main-content'>
                <div className="payment-container">
                    <div className="header">
                        <ChatBackArrow className="chatwindow-back" onClick={handleBackClick} />
                        <h1 className="header-title">Buyer Listing Pricing</h1>
                    </div>
                    {/* <img src={premiumImage} alt="Premium" className="premium-image" /> */}
                    <SetupPage2Image className='premium-image'/>
                    <div className="premium-description">
                        { selectedOption === 'buyer' ? (
                            <>
                                <h2 className="premium-title">Buyer Listing Basic</h2>
                                <div className='premium-info'>
                                    <p className='premium-price' id='bold'>$250.00</p>
                                    <span className='premium-price-divider'>|</span>
                                    <div className='premium-info-stack'>
                                        <p className='premium-subtext' id='bold'>3</p>
                                        <p className='premium-subtext'>MONTH</p>
                                    </div>
                                </div>
                                <div className='premium-highlights'>
                                    <p className="premium-text">Match With Sellers</p>
                                    <div className='premium-divider'></div>
                                    <p className="premium-text">Message with Sellers Directly</p>
                                    <div className='premium-divider'></div>
                                    <p className="premium-text">Find Your Dream Home Fast</p>
                                </div>
                            </>
                        ) : (
                            <>
                                <h2 className="premium-title">Buyer Listing Pro</h2>
                                <div className='premium-info'>
                                    <p className='premium-price' id='bold'>$875.00</p>
                                    <span className='premium-price-divider'>|</span>
                                    <div className='premium-info-stack'>
                                        <p className='premium-subtext' id='bold'>3</p>
                                        <p className='premium-subtext'>MONTH</p>
                                    </div>
                                </div>
                                <div className='premium-highlights'>
                                    <p className="premium-text">Ideal for Investors, Builders, & Agents</p>
                                    <div className='premium-divider'></div>
                                    <p className="premium-text">Up to 5 Discounted Listings (Save 30%)</p>
                                    <div className='premium-divider'></div>
                                    <p className="premium-text">Get More Deals Done Faster</p>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="option" onClick={() => handleOptionClick('buyer')}>
                        <div className={`option-text ${selectedOption === 'buyer' ? 'selected' : ''}`}>
                            <span>Buyer Listing</span>
                            <span>$250</span>
                        </div>
                    </div>
                    <div className="option" onClick={() => handleOptionClick('buyer-pro')}>
                        <div className={`option-text ${selectedOption === 'buyer-pro' ? 'selected' : ''}`}>
                            <span>Buyer Listing Pro</span>
                            <span>$875</span>
                        </div>
                    </div>
                    <div className="purchase-button" onClick={() => handleSubmit(selectedOption)}>
                        <button className="purchase-button-text">BUY NOW</button>
                    </div>
                </div>
            </div>
        </div>    
    );
}
