import React from 'react';
import PropertyMatches from '../components/PropertyMatches/PropertyMatches';
import NavHub from '../components/NavHub/NavHub';

export default function PropertyMatchePage(props) {
    return (
        <>
            <PropertyMatches/>
            <NavHub/>
        </>
    );
}