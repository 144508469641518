import React from 'react';
import AgentSetup from '../components/AgentSetup/AgentSetup';

export default function AgentSetupPage(props) {
    return (
        <>
            <div className='main-section'>
                <AgentSetup/>
            </div>
        </>
    );
}