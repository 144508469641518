import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { HavynLogo, ChatBackArrow } from '../../assets/icons/icons';
import './style.css';

export default function PaymentForm({ clientSecret, listingType, amount }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');

  // Submit Logic
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    setPaymentStatus('processing');

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://havyn.app/payment-${listingType}`,
      },
      redirect: "if_required"
    });

    if (error) {
      console.error('Error during payment confirmation:', error);
      setIsLoading(false);
      setPaymentStatus('error');
      return;
    }

    if (paymentIntent.status === 'succeeded') {
      // console.log('Payment successful!');
      setPaymentStatus('success');
      let listing = ''
      let setupType = ''

      if (listingType === 'seller') {
        listing = 'home'
        setupType = 'seller'
      } else if (listingType === 'buyer_pro' || listingType === 'buyer')  {
        listing = listingType
        setupType = 'buyer'
      }

      await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-listing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ listing }),
        credentials: 'include',
      });
      window.location.href=`/${setupType}-setup`
    }
  };

  // Screen Status Message
  const renderMessage = () => {
    switch (paymentStatus) {
      case 'processing':
        return <div>Processing your payment...</div>;
      case 'success':
        return <div>Payment successful! Redirecting...</div>;
      case 'error':
        return <div>There was an error processing your payment. Please try again.</div>;
      default:
        return null;
    }
  };

  // Stripe Styles
  const styles = {
    layout: 'tabs',
  };

  return (
    <div className='main-swipe'>
      <div className='payment-logo-container'>
        <ChatBackArrow className='chatwindow-back' onClick={() => navigate(-1)}/>
        <HavynLogo className='havyn-logo'/>
      </div>
      <form onSubmit={handleSubmit} className="payment-form">
        <PaymentElement id="payment-element" options={styles} />
        <button type="submit" disabled={!stripe || isLoading} className='payment-submit'>
          {isLoading ? <div className="spinner" id="spinner"></div> : `Pay ${amount}`}
        </button>
        <span id='payment-message'>{renderMessage()}</span>
      </form>
    </div>
  );
}
