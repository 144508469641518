import React, { useEffect, useState } from 'react';
import { APIProvider, Map, AdvancedMarker } from '@vis.gl/react-google-maps';
import './style.css';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const MapWithSchools = ({ address }) => {
  const [mapCenter, setMapCenter] = useState({lat: 0, lng: 0});
  const [isLoading, setIsLoading] = useState(true)
  const [nearbySchools, setNearbySchools] = useState({
    elementary: [],
    middle: [],
    high: []
  });
  const [expanded, setExpanded] = useState({
    elementary: false,
    middle: false,
    high: false
  });

  useEffect(() => {
    const geocodeAddress = async () => {
      try {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`);
        const data = await response.json();
        if (data.results && data.results[0]) {
          const location = data.results[0].geometry.location;
          setMapCenter(location);
          setIsLoading(false)
          fetchNearbySchools(location);
        } else {
          console.error('Geocode was not successful for the following reason:', data.status);
        }
      } catch (error) {
        console.error('Error geocoding address:', error);
      }
    };

    const fetchNearbySchools = async (location) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/nearby-schools?lat=${location.lat}&lng=${location.lng}`);
        if (response.ok) {
          const data = await response.json();
          categorizeSchools(data.results || []);
        } else {
          console.error('Error fetching nearby schools:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching nearby schools:', error);
      }
    };

    const categorizeSchools = (schools) => {
      const categorized = {
        elementary: [],
        middle: [],
        high: []
      };

      schools.forEach(school => {
        const name = school.name.toLowerCase();
        if (name.includes('elementary')) {
          categorized.elementary.push(school);
        } else if (name.includes('middle')) {
          categorized.middle.push(school);
        } else if (name.includes('high')) {
          categorized.high.push(school);
        }
      });

      setNearbySchools(categorized);
    };

    geocodeAddress();
  }, [address]);

  const toggleExpand = (category) => {
    setExpanded(prevState => ({
      ...prevState,
      [category]: !prevState[category]
    }));
  };

  const renderSchoolList = (schools, category) => (
    <div>
      {schools.slice(0, expanded[category] ? schools.length : 1).map((school, index) => (
        <ul className='school-list-container' key={index}>
          <li className='buyer-modal-text-description'>{school.name}</li>
        </ul>
      ))}
      {schools.length > 1 && (
        <button className='school-button' onClick={() => toggleExpand(category)}>
          {expanded[category] ? 'See less' : 'See more'}
        </button>
      )}
    </div>
  );

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <div className='map-container'>
        { !isLoading && (
          <Map
            style={containerStyle}
            defaultCenter={mapCenter}
            defaultZoom={15}
            gestureHandling={'greedy'}
            disableDefaultUI={false}
            mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
          >
            <AdvancedMarker  position={mapCenter} title="Property Location" />
            {Object.values(nearbySchools).flat().map((school, index) => (
              <AdvancedMarker key={index} position={school.geometry.location} title={school.name}/>
            ))}
          </Map>
        )}
        <div>
          <h2 className='nearby-title'>Nearby schools to {address}<span className='info-icon'>(3 Mile Radius)</span></h2>
          <div className='school-section'>
            <div className='school-subsection'>
              <h3 className='buyer-modal-title'>Elementary Schools</h3>
              {renderSchoolList(nearbySchools.elementary, 'elementary')}
            </div>
            <div className="school-section-divider"></div>
            <div className='school-subsection'>
              <h3 className='buyer-modal-title'>Middle Schools</h3>
              {renderSchoolList(nearbySchools.middle, 'middle')}
            </div>
            <div className="school-section-divider"></div>
            <div className='school-subsection'>
              <h3 className='buyer-modal-title'>High Schools</h3>
              {renderSchoolList(nearbySchools.high, 'high')}
            </div>
          </div>
        </div>
      </div>
    </APIProvider>
  );
};

export default MapWithSchools;
