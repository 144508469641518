import React, {useState, useSyncExternalStore} from 'react';
import Dashboard from '../components/Dashboard/Dashboard';
import NavHub from '../components/NavHub/NavHub';

export default function UserDashboard(props) {
    const [pageTransition, setPageTransition] = useState(false)
    return (
        <>
            <div className='main-section'>
                <div className={`dashboard-main ${pageTransition ? '' : ''}`}>
                    <Dashboard pageTransition={pageTransition}/>
                </div>
                <NavHub/>
            </div>
        </>
    );
}