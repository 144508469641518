import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './style.css';

// Google Maps API
import MapWithSchools from '../MapWithSchools.js/MapWithSchools';

import {
    BedIconBlack,
    ShowerIcon,
    SqftIconBlack,
    ChatBackArrow,
    EditButton,
    DeleteIcon,
    PreApprovedIcon,
    CancelIcon,
    AgentIcon,
    ProfileArrow, 
    SingleFamilyIcon, 
    ApartmentIcon, 
    CondoIcon, 
    TownhouseIcon
} from '../../assets/icons/icons';
import home2 from '../../assets/images/dd-home-2.png';
import defaultProfileImg from '../../assets/images/default-profile.png';
import MatchDetails from '../MatchDetails/MatchDetails';
import ImageGallery from '../ImageGallery/ImageGallery';

export function MatchItem({ match, listingData }) {
    const fallbackName = "John Doe"; // Fallback name for testing or if user name is not available
    const [isMatchDetailsOpen, setIsMatchDetailsOpen] = useState(false);
    // console.log(match);
    return (
        <>
            <div className='match-item' onClick={(event) => setIsMatchDetailsOpen(true)} >
                <div className='match-image-container'>
                    {match.role === 'agent' ? (
                        <>
                            <AgentIcon className='agent-icon' />
                        </>
                    ) : (<></>
                    )}
                    <img src={match.profilePicture || defaultProfileImg} className='match-img' alt='Match' />
                </div>
                <div className='match-details'>
                    <h2 className='match-name'>
                        {match.role === 'agent' ? match.name : match.displayName.split(' ')[0] || fallbackName}
                        {match.pre_approved ?
                            <PreApprovedIcon className='pre-approved-icon'></PreApprovedIcon>
                            : <></>
                        }
                    </h2>
                    <p className='match-location'>{match.city}, {match.state} {match.zipcode}</p>
                    <p className='match-budget'>Budget: ${match.min_budget} - ${match.max_budget}</p>
                </div>
            </div>
            {isMatchDetailsOpen && createPortal(
                <MatchDetails
                    onClose={(event) => setIsMatchDetailsOpen(false)}
                    match={match}
                    listingData={listingData}
                />,
                document.getElementById('chat-window')
            )}
        </>
    );
}

export function MatchesContainer({ matches, listingData }) {
    return (
        <div className='matches-list'>
            {matches.map((match, index) => (
                <MatchItem key={index} match={match} listingData={listingData} />
            ))}
        </div>
    );
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export default function ListingDetailsModal({ listing, onClose }) {
    // console.log(`THIS IS THE LISTING DATA: ${JSON.stringify(listing)}`)
    const [pageTransition, setPageTransition] = useState(false);
    const [matches, setMatches] = useState([]);
    const [isSelected, setIsSelected] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [formData, setFormData] = useState({ 
        ...listing,
        view: listing.view || [],
        exterior_features: listing.exterior_features || [],
        interior_features: listing.interior_features || [],
        flooring: listing.flooring || [],
        appliances: listing.appliances || [],
        hoa_amenities: listing.hoa_amenities || [],
        purchase_terms: listing.purchase_terms || []
    });
    const [images, setImages] = useState(listing.images ? listing.images : []);
    const [coverPhoto, setCoverPhoto] = useState(listing.cover_photo || images[0] || home2);
    const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isSQFTMetric, setIsSQFTMetric] = useState('');

    // Open state variables
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [isExteriorOpen, setIsExteriorOpen] = useState(false);
    const [isInteriorOpen, setIsInteriorOpen] = useState(false);
    const [isFlooringOpen, setIsFlooringOpen] = useState(false);
    const [isAppliancesOpen, setIsAppliancesOpen] = useState(false);
    const [isHOAAmenitiesOpen, setIsHOAAmenitiesOpen] = useState(false);
    const [isPurchaseOpen, setIsPurchaseOpen] = useState(false)

    const handleMultiSelectChange = (item, field) => {
        setFormData(prevFormData => {
            const selected = prevFormData[field];
            if (selected.includes(item)) {
                return {
                    ...prevFormData,
                    [field]: selected.filter(i => i !== item)
                };
            } else {
                return {
                    ...prevFormData,
                    [field]: [...selected, item]
                };
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const numValue = Number(value.replace(/[^0-9]/g, '')); // Remove non-numeric characters for internal state
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: numValue
        }));
        // if (name === 'property_tax') setPropertyTax(numValue);
        // if (name === 'monthly_mortgage_estimate') setMonthlyMortgageEstimate(numValue);
    };

    useEffect(() => {
        // console.log(`${listing.property_address}, ${listing.city}, ${listing.state} ${listing.zipcode}`)
        const fetchMatches = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/matches?sellerId=${listing.property_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    setMatches(data.matches);
                } else {
                    console.error('Failed to fetch matches');
                }
            } catch (error) {
                console.error('Error fetching matches:', error);
            }
        };
        fetchMatches();
    }, [listing.property_id]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleDeleteClick = () => {
        setIsDeleting(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/property-listing/${listing.property_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });

            if (response.ok) {
                setIsDeleting(false);
                onClose(); // Close modal after deletion
                window.location.href = '/dashboard';
            } else {
                console.error('Failed to delete listing');
            }
        } catch (error) {
            console.error('Error deleting listing:', error);
        }
    };

    const handleCancelDelete = () => {
        setIsDeleting(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSave = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/property-listing/${listing.property_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...formData, images: JSON.stringify(images), cover_photo: coverPhoto }), // Ensure images are stringified
                credentials: 'include'
            });

            if (response.ok) {
                setIsEditing(false);
                onClose(); // Close modal after saving
                window.location.href = '/dashboard';
            } else {
                console.error('Failed to update listing');
            }
        } catch (error) {
            console.error('Error updating listing:', error);
        }
    };

    const handleImageDelete = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);
        if (index === 0 && updatedImages.length > 0) {
            setCoverPhoto(updatedImages[0]); // Update cover photo if the first image is deleted
        } else if (updatedImages.length === 0) {
            setCoverPhoto(home2); // Default cover photo if no images left
        }
    };

    const handleImageReorder = (result) => {
        if (!result.destination) return;
        const updatedImages = reorder(images, result.source.index, result.destination.index);
        setImages(updatedImages);
        setCoverPhoto(updatedImages[0]);
    };

    const handleUploadImages = async (e) => {
        const files = e.target.files;
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('images', files[i]);
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/property-listing/${listing.property_id}/upload`, {
                method: 'POST',
                credentials: 'include',
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                if (Array.isArray(data.images)) {
                    setImages(prevImages => [...new Set([...prevImages, ...data.images])]);
                } else {
                    console.error('Expected an array of images, but received:', data.images);
                }
            } else {
                throw new Error('Failed to upload images');
            }
        } catch (error) {
            console.error('Error uploading images:', error);
        }
    };

    const handlePageTransition = () => {
        setPageTransition(true);
        setTimeout(() => {
            onClose();
        }, 500);
    };

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setIsImageGalleryOpen(true);
    };

    function ListingNav() {
        return (
            <>
                {isEditing ? (
                    <></>
                ) : (
                    <>
                        <div className='listing-nav-container'>
                            <div className='listing-nav-item' onClick={(e) => setIsSelected(true)}>
                                <p className={`listing-nav-text ${isSelected ? 'listing-nav-header' : 'listing-nav-header-false'}`}>Listing Details</p>
                            </div>
                            <div className='listing-nav-item' onClick={(e) => setIsSelected(false)}>
                                <p className={`listing-nav-text ${isSelected ? 'listing-nav-header-false' : 'listing-nav-header'}`}>Matches</p>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }

    // Function to format prices
    const formatNumber = (value) => {
        const formattedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${formattedValue}`;
    };

    // Function to format phone numbers
    const formatPhoneNumber = (value) => {
        const cleaned = ('' + value).replace(/\D/g, ''); // Remove all non-digit characters
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return value; // Return the original value if it doesn't match the expected format
    };
    

    return createPortal(
        <div className={`listing-details-modal ${pageTransition ? 'leftClose' : 'left'}`}>
            <div className={isSelected ? 'main-section' : 'match-section'}>
                <div className={isSelected ? 'main-listing-content' : 'match-listing-content'}>
                    <div className='listing-details-arrow-container'>
                        <ChatBackArrow className='chatwindow-back' onClick={isEditing ? () => setIsEditing(false) : (e) => handlePageTransition()} />
                        {isEditing ? (
                            <>
                                <input
                                    type='number'
                                    name='asking_price'
                                    value={formData.asking_price}
                                    onChange={handleChange}
                                    className='asking-price-input'
                                />
                            </>
                        ) : (
                            <h1 className='modal-header'>${listing.asking_price}</h1>
                        )}
                        {isEditing ? (
                            <DeleteIcon className='edit-btn' onClick={handleDeleteClick} />
                        ) : (
                            <EditButton className='edit-btn' onClick={handleEditClick} />
                        )}
                    </div>
                    <ListingNav />
                    {isEditing ? (
                        <div className='listing-details-content-edit'>
                            <form>
                                <div className='listing-info-container'>
                                    <DragDropContext onDragEnd={handleImageReorder}>
                                        <Droppable droppableId="images">
                                            {(provided, snapshot) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef} className='listing-details-image-list-edit'>
                                                    {images.map((image, index) => (
                                                        <Draggable key={index} draggableId={`image-${index}`} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='uploaded-image-wrapper-edit'>
                                                                    <img src={image} alt={`Uploaded ${index}`} className='uploaded-image-edit' />
                                                                    <div className='image-actions'>
                                                                        {index === 0 && <span className='cover-photo-indicator'>Cover Photo</span>}
                                                                        <CancelIcon className='delete-icon' onClick={() => handleImageDelete(index)} />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <div className='img-upload-container'>
                                        <label className='listing-img-upload-btn'>
                                            Upload
                                            <input
                                                type='file'
                                                name='images'
                                                multiple
                                                onChange={handleUploadImages}
                                            />
                                        </label>
                                    </div>
                                    <div className='listing-details-container-modal'>
                                        <div className='description-title-container-edit'>
                                            <br></br>
                                            {/* HOME HIGHLIGHTS */}
                                            <p className='modal-section-header'>Home Highlights</p>
                                            <div className="profile-section-divider"></div>
                                            <div className='buyer-modal-form-group'>
                                                <label className='buyer-modal-title'>For Sale By Owner (FSBO):</label>
                                                <select
                                                    className='buyer-modal-input'
                                                    name='fsbo'
                                                    value={formData.fsbo}
                                                    onChange={handleChange}
                                                >
                                                    <option value={1}>Yes</option>
                                                    <option value={0}>No</option>
                                                </select>
                                            </div>
                                            <div className='buyer-modal-form-group'>
                                                <label className='buyer-modal-title'>Lot Size:</label>
                                                <input
                                                    className='buyer-modal-input'
                                                    type='text'
                                                    name='lot_size'
                                                    value={formData.lot_size}
                                                    onChange={handleChange}
                                                    min={0}
                                                    max={999999999}
                                                    maxLength={10}
                                                />
                                                <select
                                                    className='buyer-modal-input'
                                                    name="metric"
                                                    value={isSQFTMetric}
                                                    placeholder="Sqft lot"
                                                    onChange={(e) => setIsSQFTMetric(e.target.value)}
                                                >
                                                    <option value='sqft_lot'>sqft lot</option>
                                                    <option value="acre_lot">acre lot</option>
                                                </select>
                                            </div>
                                            <br />
                                            {/* BUY OR RENT */}
                                            <p className='modal-section-header'>Selling or Renting</p>
                                            <div className="profile-section-divider"></div>
                                            <div className='buyer-modal-form-group'>
                                                <label className='buyer-modal-title'>Listing Status:</label>
                                                <select
                                                    className='buyer-modal-input'
                                                    name='property_status'
                                                    value={formData.property_status}
                                                    onChange={handleChange}
                                                >
                                                    <option value='for_sale'>Selling</option>
                                                    <option value='for_rent'>Renting</option>
                                                </select>
                                            </div>
                                            <br />
                                            {/* DETAILS.*/}
                                            <p className='modal-section-header'>Details</p>
                                            <div className="profile-section-divider"></div>
                                            <div className='buyer-modal-input-container'>
                                                <label className='buyer-modal-title'>Description:</label>
                                                <input
                                                    className='setup-input-extended'
                                                    type='text'
                                                    name='description'
                                                    maxLength='750'
                                                    value={formData.description}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            { listing.pool ? (
                                                <>
                                                    <div className='buyer-modal-input-container'>
                                                        <label className='buyer-modal-title'>Pool Description:</label>
                                                        <input
                                                            className='setup-input-extended'
                                                            type='text'
                                                            name='pool_description'
                                                            maxLength='250'
                                                            value={formData.pool_description}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            { listing.spa ? (
                                                <>
                                                    <div className='buyer-modal-input-container'>
                                                        <label className='buyer-modal-title'>Spa Description:</label>
                                                        <input
                                                            className='setup-input-extended'
                                                            type='text'
                                                            name='spa_description'
                                                            maxLength='250'
                                                            value={formData.spa_description}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            { listing.waterfront ? (
                                                <>
                                                    <div className='buyer-modal-input-container'>
                                                        <label className='buyer-modal-title'>Waterfront Description:</label>
                                                        <input
                                                            className='setup-input-extended'
                                                            type='text'
                                                            name='waterfront_description'
                                                            maxLength='250'
                                                            value={formData.waterfront_description}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <br />
                                            {/* LISTED FEATURES */}
                                            <p className='modal-section-header'>Listed Features</p>
                                            <div className="profile-section-divider"></div>
                                            <div className='buyer-modal-dropdown-container'>
                                                <label className='buyer-modal-title'>Exterior Features:</label>
                                                <p className='setup-dropdown-selected-count'>{formData.exterior_features.length > 0 ? formData.exterior_features.length + ' selected' : ''}</p>
                                                {isExteriorOpen ? (
                                                    <>
                                                        <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsExteriorOpen(false)} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <ProfileArrow className='profile-arrow' onClick={() => setIsExteriorOpen(true)} />
                                                    </>
                                                )}
                                            </div>
                                            {isExteriorOpen && (
                                                <>
                                                    <div className='setup-dropdown-container'>
                                                        {[
                                                            'Barn', 'Gas Fire Pit', 'Courtyard', 'Deck', 'Fence', 'Fruit Trees', 'Gazebo', 'Grill', 'Outdoor Fireplace',
                                                            'Outdoor Kitchen', 'Outdoor Shower', 'Patio', 'Pond', 'Privacy Hedges', 'Room for Pool', 'Sprinkler system',
                                                            'Storage', 'Tennis Court', 'Warehouse'
                                                        ].map((feature, index) => (
                                                            <div
                                                                key={index}
                                                                className={`setup-dropdown-item ${formData.exterior_features.includes(feature) ? 'active' : ''}`}
                                                                onClick={() => handleMultiSelectChange(feature, 'exterior_features')}
                                                            >
                                                                {feature}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                            <div className='buyer-modal-dropdown-container'>
                                                <label className='buyer-modal-title'>Interior Features:</label>
                                                <p className='setup-dropdown-selected-count'>{formData.interior_features.length > 0 ? formData.interior_features.length + ' selected' : ''}</p>
                                                {isInteriorOpen ? (
                                                    <>
                                                        <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsInteriorOpen(false)} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <ProfileArrow className='profile-arrow' onClick={() => setIsInteriorOpen(true)} />
                                                    </>
                                                )}
                                            </div>
                                            {isInteriorOpen && (
                                                <>
                                                    <div className='setup-dropdown-container'>
                                                        {[
                                                            'Bar', 'Closets: Built in shelves', 'Custom Mirrors', 'Disability Equipped', 'Exclusions', 'Fireplace', 'Foyer',
                                                            'French Doors', 'Pantry', 'Pocket sliders', 'Surround Sound Wired', 'Volume Ceiling', 'Walk-In Closet', 'Wet Bar',
                                                            'Wheelchair Access', 'Window Coverings', 'Zero/Corner Door Sliders'
                                                        ].map((feature, index) => (
                                                            <div
                                                                key={index}
                                                                className={`setup-dropdown-item ${formData.interior_features.includes(feature) ? 'active' : ''}`}
                                                                onClick={() => handleMultiSelectChange(feature, 'interior_features')}
                                                            >
                                                                {feature}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                            <div className='buyer-modal-dropdown-container'>
                                                <label className='buyer-modal-title'>Flooring:</label>
                                                <p className='setup-dropdown-selected-count'>{formData.flooring.length > 0 ? formData.flooring.length + ' selected' : ''}</p>
                                                {isFlooringOpen ? (
                                                    <>
                                                        <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsFlooringOpen(false)} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <ProfileArrow className='profile-arrow' onClick={() => setIsFlooringOpen(true)} />
                                                    </>
                                                )}
                                            </div>
                                            {isFlooringOpen && (
                                                <>
                                                    <div className='setup-dropdown-container'>
                                                        {['Brick', 'Carpet', 'Concrete', 'Laminate', 'Marble', 'Tile', 'Vinyl', 'Wood', 'Other'].map((floor, index) => (
                                                            <div
                                                                key={index}
                                                                className={`setup-dropdown-item ${formData.flooring.includes(floor) ? 'active' : ''}`}
                                                                onClick={() => handleMultiSelectChange(floor, 'flooring')}
                                                            >
                                                                {floor}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                            <div className='buyer-modal-dropdown-container'>
                                                <label className='buyer-modal-title'>Appliances:</label>
                                                <p className='setup-dropdown-selected-count'>{formData.appliances.length > 0 ? formData.appliances.length + ' selected' : ''}</p>
                                                {isAppliancesOpen ? (
                                                    <>
                                                        <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsAppliancesOpen(false)} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <ProfileArrow className='profile-arrow' onClick={() => setIsAppliancesOpen(true)} />
                                                    </>
                                                )}
                                            </div>
                                            {isAppliancesOpen && (
                                                <>
                                                    <div className='setup-dropdown-container'>
                                                        {[
                                                            'Auto Garage Door', 'Cooktop - Electric', 'Cooktop - Gas', 'Dishwasher', 'Disposal', 'Double Oven', 'Dryer', 'Freezer',
                                                            'Generator', 'Grill - Gas', 'Home Automation', 'Ice Maker - Stand Alone', 'Microwave', 'Pot Filler', 'Range', 'Refrigerator',
                                                            'Refrigerator/Freezer', 'Reverse Osmosis', 'Security System', 'Solar Panels', 'Steam Oven', 'Tankless Water Heater', 'Wall Oven',
                                                            'Washer', 'Water Treatment', 'Wine Cooler', 'Other'
                                                        ].map((appliance, index) => (
                                                            <div
                                                                key={index}
                                                                className={`setup-dropdown-item ${formData.appliances.includes(appliance) ? 'active' : ''}`}
                                                                onClick={() => handleMultiSelectChange(appliance, 'appliances')}
                                                            >
                                                                {appliance}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                            <div className='buyer-modal-dropdown-container'>
                                                <label className='buyer-modal-title'>View:</label>
                                                <p className='setup-dropdown-selected-count'>{formData.view.length > 0 ? formData.view.length + ' selected' : ''}</p>
                                                {isViewOpen ? (
                                                    <>
                                                        <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsViewOpen(false)} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <ProfileArrow className='profile-arrow' onClick={() => setIsViewOpen(true)} />
                                                    </>
                                                )}
                                            </div>
                                            {isViewOpen && (
                                                <>
                                                    <div className='setup-dropdown-container'>
                                                        {[
                                                            'Bay', 'Canal', 'City', 'Creek', 'Golf Course', 'Intersecting Canal', 'Landscaped Area',
                                                            'Mangroves', 'Pond', 'Pool/Club', 'Preserve', 'Privacy Wall', 'River', 'Tennis Courts',
                                                            'Water', 'Water Feature', 'Wooded Area'
                                                        ].map((view, index) => (
                                                            <div
                                                                key={index}
                                                                className={`setup-dropdown-item ${formData.view.includes(view) ? 'active' : ''}`}
                                                                onClick={() => handleMultiSelectChange(view, 'view')}
                                                            >
                                                                {view}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                            <br />
                                            {/* HOME OWNERS ASSOCIATION */}
                                            <p className='modal-section-header'>Neighborhood Information</p>
                                            <div className="profile-section-divider"></div>
                                            <div className='buyer-modal-form-group'>
                                                <label className='buyer-modal-title'>HOA:</label>
                                                <select
                                                    className='buyer-modal-input'
                                                    name='hoa'
                                                    value={formData.hoa}
                                                    onChange={handleChange}
                                                >
                                                    <option value={1}>Yes</option>
                                                    <option value={0}>No</option>
                                                </select>
                                            </div>
                                            {formData.hoa ? (
                                                <>
                                                    <div className='buyer-modal-form-group'>
                                                        <label className='buyer-modal-title'>HOA Fee(s):</label>
                                                        <input
                                                            className='buyer-modal-input'
                                                            type='number'
                                                            name='hoa_fee'
                                                            value={formData.hoa_fee}
                                                            onChange={handleChange}
                                                            min={0}
                                                            max={999999}
                                                            maxLength={6}
                                                        />
                                                    </div>
                                                    <div className='buyer-modal-form-group'>
                                                        <label className='buyer-modal-title'>HOA Fee(s) Frequency:</label>
                                                        <select
                                                            className='buyer-modal-input'
                                                            name='fee_frequency'
                                                            value={formData.fee_frequency}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="None">None</option>
                                                            <option value="Quarterly">Quarterly</option>
                                                            <option value="Monthly">Monthly</option>
                                                            <option value="Bi-Annually">Bi-Annually</option>
                                                            <option value="Annually">Annually</option>
                                                        </select>
                                                    </div>
                                                    <div className='buyer-modal-form-group'>
                                                        <label className='buyer-modal-title'>HOA Master Fee(s):</label>
                                                        <input
                                                            className='buyer-modal-input'
                                                            type='number'
                                                            name='hoa_fee'
                                                            value={formData.hoa_fee_master}
                                                            onChange={handleChange}
                                                            min={0}
                                                            max={999999}
                                                            maxLength={6}
                                                        />
                                                    </div>
                                                    <div className='buyer-modal-form-group'>
                                                        <label className='buyer-modal-title'>HOA Master Fee(s) Frequency:</label>
                                                        <select
                                                            className='buyer-modal-input'
                                                            name='fee_frequency_master'
                                                            value={formData.fee_frequency_master}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="None">None</option>
                                                            <option value="Quarterly">Quarterly</option>
                                                            <option value="Monthly">Monthly</option>
                                                            <option value="Bi-Annually">Bi-Annually</option>
                                                            <option value="Annually">Annually</option>
                                                        </select>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            {formData.hoa ? (
                                                <>
                                                    <div className='buyer-modal-dropdown-container'>
                                                        <label className='buyer-modal-title'>HOA Amenities:</label>
                                                        <p className='setup-dropdown-selected-count'>{formData.hoa_amenities.length > 0 ? formData.hoa_amenities.length + ' selected' : ''}</p>
                                                        {isHOAAmenitiesOpen ? (
                                                            <>
                                                                <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsHOAAmenitiesOpen(false)} />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ProfileArrow className='profile-arrow' onClick={() => setIsHOAAmenitiesOpen(true)} />
                                                            </>
                                                        )}
                                                    </div>
                                                    {isHOAAmenitiesOpen && (
                                                        <div className='setup-dropdown-container'>
                                                            {[
                                                                'Assisted Living Available', 'Basketball', 'BBQ - Picnic', 'Beach Access', 'Beach Club Available', 'Beach Club Included',
                                                                'Beauty Salon', 'Bike And Jog Path', 'Bike Storage', 'Billiards', 'Boat Storage', 'Bocce Court', 'Business Center', 'Cabana',
                                                                'Clubhouse', 'Common Laundry', 'Community Boat Dock', 'Community Boat Lift', 'Community Boat Ramp', 'Community Boat Slip',
                                                                'Community Gulf Boat Access', 'Community Park', 'Community Pool', 'Community Spa/Hot tub', 'Concierge Services', 'Dog Park',
                                                                'Electric Vehicle Charging', 'Exercise Room', 'Extra Storage', 'Fish Cleaning Station', 'Fishing Pier', 'Fitness Center Attended',
                                                                'Full Service Spa', 'Golf Course', 'Guest Room', 'Hobby Room', 'Horses OK', 'Internet Access', 'Lakefront Beach', 'Lap Pool',
                                                                'Library', 'Marina', 'None', 'Other', 'Pickleball', 'Play Area', 'Private Beach Pavilion', 'Private Membership', 'Putting Green',
                                                                'Racquetball', 'Restaurant', 'Sauna', 'Shuffleboard', 'Sidewalk', 'Stable - Horse', 'Tennis Court', 'Theater', 'Trash Chute',
                                                                'Vehicle Wash Area', 'Volleyball', 'Water Skiing'
                                                            ].map((amenity, index) => (
                                                                <div
                                                                    key={index}
                                                                    className={`setup-dropdown-item ${formData.hoa_amenities.includes(amenity) ? 'active' : ''}`}
                                                                    onClick={() => handleMultiSelectChange(amenity, 'hoa_amenities')}
                                                                >
                                                                    {amenity}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <br />
                                            {/* PREFRENCES */}
                                            <p className='modal-section-header'>Legal and Compliance</p>
                                            <div className="profile-section-divider"></div>
                                            { formData.fsbo ? (
                                                <>
                                                    <div className='buyer-modal-form-group'>
                                                        <label className='buyer-modal-title'>Add a Listing Agent?</label>
                                                        <select
                                                            className='buyer-modal-input'
                                                            name='listing_agent'
                                                            value={formData.listing_agent}
                                                            onChange={handleChange}
                                                        >
                                                            <option value={1}>Yes</option>
                                                            <option value={0}>No</option>
                                                        </select>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className='buyer-modal-form-group'>
                                                        <label className='buyer-modal-title'>Listing Agent:</label>
                                                        <select
                                                            className='buyer-modal-input'
                                                            name='listing_agent'
                                                            value={formData.listing_agent}
                                                            onChange={handleChange}
                                                        >
                                                            <option value={1}>Yes</option>
                                                            <option value={0}>No</option>
                                                        </select>
                                                    </div>
                                                </>
                                            )}
                                            { listing.listing_agent  ? (
                                                <>
                                                    <div className='buyer-modal-form-group'>
                                                        <label className='buyer-modal-title'>Agent Name: </label>
                                                        <input
                                                            className='buyer-modal-input'
                                                            type='text'
                                                            name='agent_name'
                                                            value={formData.agent_name}
                                                            onChange={handleChange}
                                                            maxLength='100'
                                                        />
                                                    </div>
                                                    <div className='buyer-modal-form-group'>
                                                        <label className='buyer-modal-title'>Agent Phone Number: </label>
                                                        <input
                                                            className='buyer-modal-input'
                                                            type='text'
                                                            name='agent_phone_number'
                                                            value={formData.agent_phone_number}
                                                            onChange={handleChange}
                                                            maxLength='100'
                                                        />
                                                    </div>
                                                    <div className='buyer-modal-form-group'>
                                                        <label className='buyer-modal-title'>Agent Email: </label>
                                                        <input
                                                            className='buyer-modal-input'
                                                            type='text'
                                                            name='agent_email'
                                                            value={formData.agent_email}
                                                            onChange={handleChange}
                                                            maxLength='100'
                                                        />
                                                    </div>
                                                    <div className='buyer-modal-form-group'>
                                                        <label className='buyer-modal-title'>Brokerage Name: </label>
                                                        <input
                                                            className='buyer-modal-input'
                                                            type='text'
                                                            name='brokerage_name'
                                                            value={formData.brokerage_name}
                                                            onChange={handleChange}
                                                            maxLength='100'
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <>

                                                </>
                                            )}
                                            <br />
                                            {/* BUDGET AND FINANCING */}
                                            <p className='modal-section-header'>Financial Information</p>
                                            <div className="profile-section-divider"></div>
                                            <div className='buyer-modal-dropdown-container'>
                                                <label className='buyer-modal-title'>Purchase Terms:</label>
                                                <p className='setup-dropdown-selected-count'>{formData.purchase_terms.length > 0 ? formData.purchase_terms.length + ' selected' : ''}</p>
                                                {isPurchaseOpen ? (
                                                    <>
                                                        <ProfileArrow className='profile-arrow-collapsed' onClick={() => setIsPurchaseOpen(false)} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <ProfileArrow className='profile-arrow' onClick={() => setIsPurchaseOpen(true)} />
                                                    </>
                                                )}
                                            </div>
                                            { isPurchaseOpen && (
                                                <>
                                                    <div className='setup-dropdown-container'>
                                                        {[
                                                            'Cash Only', 'Financing/Cash', 'Seller Financing', 'FHA Mortgage', 'VA Mortgage', 'Other'
                                                        ].map((term, index) => (
                                                            <div
                                                                key={index}
                                                                className={`setup-dropdown-item ${formData.purchase_terms.includes(term) ? 'active' : ''}`}
                                                                onClick={() => handleMultiSelectChange(term, 'purchase_terms')}
                                                            >
                                                                {term}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                            <br />
                                            {/* PREFRENCES */}
                                            <p className='modal-section-header'>Preferences</p>
                                            <div className="profile-section-divider"></div>
                                            <div className='buyer-modal-form-group'>
                                                <label className='buyer-modal-title'>Move-Out Timeline:</label>
                                                <select
                                                    className='buyer-modal-input'
                                                    name='move_out_timeline'
                                                    value={formData.move_out_timeline}
                                                    onChange={handleChange}
                                                >
                                                    <option value='Ready'>Ready</option>
                                                    <option value='2-3 months'>2-3 months</option>
                                                    <option value='4-6 months'>4-6 months</option>
                                                    <option value='6-12 months'>6-12 months</option>
                                                    <option value='12+ months'>12+ months</option>
                                                </select>
                                            </div>
                                            <div className='buyer-modal-form-group-extended'>
                                                <label className='buyer-modal-title'>Unique Features:</label>
                                                <textarea
                                                    className='buyer-modal-input-extended'
                                                    name='unique_features'
                                                    value={formData.unique_features}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='delete-modal-button-container-edit'>
                                    <button type='button' className='buyer-modal-cancel-btn' onClick={() => setIsEditing(false)}>Cancel</button>
                                    <button type='button' className='buyer-modal-save-btn' onClick={handleSave}>Save</button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <>
                            {!isSelected ? (
                                <>
                                    <div className='matches-section-container'>
                                        <h2 className='dashboard-title'>Matching Buyers</h2>
                                        <div className='matches-container'>
                                            {matches.length > 0 ? (
                                                <MatchesContainer matches={matches} listingData={listing} />
                                            ) : (
                                                <p>No matching buyers found</p>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='listing-details-content'>
                                        <div className='listing-info-container'>
                                            <div className='listing-details-image-carousel-container'>
                                                <Carousel showThumbs={images.length > 0 ? true : false}>
                                                    {images.length > 0 ? (
                                                        images.map((image, index) => (
                                                            <div key={index} className='uploaded-image-wrapper' onClick={() => handleImageClick(index)}>
                                                                <img src={image} alt={`Uploaded ${index}`} className='uploaded-image' />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <>
                                                            <div className='uploaded-image-wrapper'>
                                                                <img src={home2} alt={'Upload home'} className='uploaded-image' />
                                                            </div>
                                                        </>
                                                    )}
                                                </Carousel>
                                            </div>
                                            <div className='listing-details-container-modal'>
                                                <div className='description-title-container'>
                                                    <p className='listing-details-address'>{listing.property_address},</p>
                                                    <p className='listing-details-address-alt'>{listing.city}, {listing.state} {listing.zipcode}</p>
                                                </div>
                                                <div className='property-swipe-icons-container'>
                                                    <BedIconBlack className='swipe-bed-icon-pd' />
                                                    <div className='property-details-icon-container'>
                                                        <p className='details-icon-title'>Bedrooms</p>
                                                        <p className='details-icon-details'>{listing.bedrooms} Rooms</p>
                                                    </div>
                                                    <ShowerIcon className='swipe-shower-icon-pd' fill='#000000' />
                                                    <div className='property-details-icon-container'>
                                                        <p className='details-icon-title'>Bathrooms</p>
                                                        <p className='details-icon-details'>{listing.bathrooms + listing.bathrooms_half} Rooms</p>
                                                    </div>
                                                    <SqftIconBlack className='swipe-sqft-icon-pd' />
                                                    <div className='property-details-icon-container'>
                                                        <p className='details-icon-title'>Square Footage</p>
                                                        <p className='details-icon-details'>{listing.sqft} sqft</p>
                                                    </div>
                                                </div>
                                                <br />
                                                <p className='modal-section-header'>Home Highlights</p>
                                                <div className="profile-section-divider"></div>
                                                <br></br>
                                                <div className='buyer-modal-boxes active'>
                                                    {(() => {
                                                        switch (listing.property_type) {
                                                            case 'Single Family':
                                                                return (
                                                                    <>
                                                                        <SingleFamilyIcon />
                                                                        <span className='setup-page3-boxes-title'>Single Family</span>
                                                                    </>
                                                                )
                                                            case 'Condo':
                                                                return (
                                                                    <>
                                                                        <CondoIcon />
                                                                        <span className='setup-page3-boxes-title'>Condo</span>
                                                                    </>
                                                                )
                                                            case 'Apartment':
                                                                return (
                                                                    <>
                                                                        <ApartmentIcon />
                                                                        <span className='setup-page3-boxes-title'>Apartment</span>
                                                                    </>
                                                                )
                                                            case 'TownHome':
                                                                return (
                                                                    <>
                                                                        <TownhouseIcon />
                                                                        <span className='setup-page3-boxes-title'>TownHome</span>
                                                                    </>
                                                                )
                                                            default:
                                                                return null; 
                                                        }
                                                    })()}
                                                </div>
                                                { listing.fsbo === true && (
                                                    <>
                                                        <p className='buyer-modal-title'>FSBO <span className='buyer-modal-text'>{!listing.fsbo ? 'No' : 'Yes'}</span></p>
                                                    </>
                                                )}
                                                { listing.bathrooms_half > 0 && (
                                                    <>
                                                        <p className='buyer-modal-title'>Half Bathrooms: <span className='buyer-modal-text'>{listing.bathrooms_half}</span></p>
                                                    </>
                                                )}
                                                <p className='buyer-modal-title'>Year Built: <span className='buyer-modal-text'>{listing.year_built}</span></p>
                                                <p className='buyer-modal-title'>Lot Size: <span className='buyer-modal-text'>{listing.lot_size} {listing.lot_size > 1000 ? 'sqft lot' : 'acre lot'}</span></p>
                                                <p className='buyer-modal-title'>HOA: <span className='buyer-modal-text'>{!listing.hoa ? 'No' : 'Yes'}</span></p>
                                                { listing.den > 0 && (
                                                    <>
                                                        <p className='buyer-modal-title'>Den: <span className='buyer-modal-text'>{listing.den}</span></p>
                                                    </>
                                                )}
                                                <p className='buyer-modal-title'>Move Out Timeframe: <span className='buyer-modal-text'>{listing.move_out_timeline}</span></p>
                                                <p className='buyer-modal-title'>Living Square Footage: <span className='buyer-modal-text'>{listing.sqft_living} sqft</span></p>
                                                <br />
                                                <p className='modal-section-header'>Details</p>
                                                <div className="profile-section-divider"></div>
                                                <p className='buyer-modal-title-extended'><span className='buyer-modal-text'>{!listing.description ? 'null' : listing.description}</span></p>
                                                <p className='buyer-modal-title'>Lot Description: </p>
                                                <br />
                                                <p className='modal-section-header'>Features</p>
                                                <div className="profile-section-divider"></div>
                                                <p className='buyer-modal-title'>Stories/Levels: <span className='buyer-modal-text'>{listing.stories_level}</span></p>
                                                { !listing.garage ? (
                                                    <>
                                                        <p className='buyer-modal-title'>Garage: <span className='buyer-modal-text'>No</span></p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className='buyer-modal-title'>Garage: <span className='buyer-modal-text'>Yes</span></p> 
                                                        <p className='buyer-modal-title'>Attached or Detached: <span className='buyer-modal-text'>{!listing.attached_garage ? 'Detached' : 'Attached'}</span></p>
                                                        <p className='buyer-modal-title'>Garage Spaces: <span className='buyer-modal-text'>{listing.garage_spaces}</span></p>
                                                        { !listing.carport ? (
                                                            <>

                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className='buyer-modal-title'>Carport: <span className='buyer-modal-text'>{listing.carport}</span></p>
                                                                <p className='buyer-modal-title'>Carport Spaces: <span className='buyer-modal-text'>{listing.carport_spaces}</span></p>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                <p className='buyer-modal-title'>Pool: <span className='buyer-modal-text'>{listing.pool  ? 'Yes' : 'No'}</span></p>
                                                { listing.pool === 1 && (
                                                    <>
                                                        <span className='buyer-modal-text'>{listing.pool_description}</span>
                                                    </>
                                                )}
                                                <p className='buyer-modal-title'>Spa: <span className='buyer-modal-text'>{listing.spa ? 'Yes' : 'No'}</span></p>
                                                { listing.spa === 1 && (
                                                    <>
                                                        <span className='buyer-modal-text'>{listing.spa_description}</span>
                                                    </>
                                                )}
                                                <p className='buyer-modal-title'>Waterfront: <span className='buyer-modal-text'>{listing.waterfront ? 'Yes' : 'No'}</span></p>
                                                { listing.waterfront === 1 && (
                                                    <>
                                                        <span className='buyer-modal-text'>{listing.waterfront_description}</span>
                                                    </>
                                                )}
                                                <p className='buyer-modal-title'>Gulf Access: <span className='buyer-modal-text'>{listing.gulf_access === 'any' ? 'Any' : listing.gulf_access === 'yes' ? 'Yes' : 'No'}</span></p>
                                                <br />
                                                <p className='modal-section-header'>Listed Features </p>
                                                <div className="profile-section-divider"></div>
                                                <div className='buyer-modal-title'>Exterior Features: 
                                                    <div className='buyer-modal-bubble-container'>
                                                        {listing.exterior_features.map((term, index) => (
                                                            <span key={index} className='term-bubble'>
                                                                {term}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className='buyer-modal-title'>Interior Features: 
                                                    <div className='buyer-modal-bubble-container'>
                                                        {listing.interior_features.map((term, index) => (
                                                            <span key={index} className='term-bubble'>
                                                                {term}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className='buyer-modal-title'>Flooring: 
                                                    <div className='buyer-modal-bubble-container'>
                                                        {listing.flooring.map((term, index) => (
                                                            <span key={index} className='term-bubble'>
                                                                {term}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className='buyer-modal-title'>Appliances: 
                                                    <div className='buyer-modal-bubble-container'>
                                                        {listing.appliances.map((term, index) => (
                                                            <span key={index} className='term-bubble'>
                                                                {term}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className='buyer-modal-title'>View: 
                                                    <div className='buyer-modal-bubble-container'>
                                                        {listing.view.map((term, index) => (
                                                            <span key={index} className='term-bubble'>
                                                                {term}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <br />
                                                <p className='modal-section-header'>Location</p>
                                                <div className="profile-section-divider"></div>
                                                <MapWithSchools address={`${listing.property_address}, ${listing.city}, ${listing.state}, ${listing.zipcode}`} />
                                                <br />
                                                <p className='modal-section-header'>Neighborhood Information</p>
                                                <div className="profile-section-divider"></div>
                                                <p className='buyer-modal-title'>Subdivision: <span className='buyer-modal-text'>{listing.subdivision ? 'Yes' : 'No'}</span></p>
                                                { listing.subdivision ? (
                                                    <>
                                                        <p className='buyer-modal-title'>Name: <span className='buyer-modal-text'>{listing.subdivision_name}</span></p>
                                                    </>
                                                ) : (
                                                    <>

                                                    </>
                                                )}
                                                <p className='buyer-modal-title'>HOA: <span className='buyer-modal-text'>{listing.hoa ? 'Yes' : 'No'}</span></p>
                                                {   listing.hoa ? (
                                                        <>
                                                            <p className='buyer-modal-title'>HOA Fee(s): <span className='buyer-modal-text'>${formatNumber(listing.hoa_fee)}</span></p>
                                                            <p className='buyer-modal-title'>Fee Frequency: <span className='buyer-modal-text'>{listing.fee_frequency}</span></p>
                                                            <p className='buyer-modal-title'>HOA Master Fee(s): <span className='buyer-modal-text'>${formatNumber(listing.hoa_fee_master)}</span></p>
                                                            {listing.fee_frequency_master === '' ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    <p className='buyer-modal-title'>Master Fee Frequency: <span className='buyer-modal-text'>{listing.fee_frequency_master}</span></p>
                                                                </>
                                                            )}
                
                                                            <p className='buyer-modal-title'>Application Fee: <span className='buyer-modal-text'>${formatNumber(listing.application_fee)}</span></p>
                                                            <p className='buyer-modal-title'>Transfer Fee: <span className='buyer-modal-text'>${formatNumber(listing.transfer_fee)}</span></p>
                                                            <p className='buyer-modal-title'>Other Fee(s): <span className='buyer-modal-text'>{listing.other_fee}</span></p>
                                                            <div className='buyer-modal-title'>HOA Amenities: 
                                                                <div className='buyer-modal-bubble-container'>
                                                                    {listing.hoa_amenities.map((term, index) => (
                                                                    <span key={index} className='term-bubble'>
                                                                        {term}
                                                                    </span>
                                                                ))}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>

                                                        </>
                                                    )
                                                }
                                                {/* <br />
                                                <p className='modal-section-header'>Legal and Compliance</p>
                                                <div className="profile-section-divider"></div> */}
                                                { listing.listing_agent ? (
                                                    <>
                                                        <br />
                                                        <p className='modal-section-header'>Legal and Compliance</p>
                                                        <div className="profile-section-divider"></div>
                                                        <p className='buyer-modal-title'>Listing Agent Info: </p>
                                                        <div className='listing-agent-container'>
                                                            <div className='listing-agent-info-container'>
                                                                <p className='agent-info-title'>Agent Name: <span className='agent-info-text'>{listing.agent_name}</span></p>
                                                                <p className='agent-info-title'>Phone Number: <span className='agent-info-text'>{formatPhoneNumber(listing.agent_phone_number)}</span></p>
                                                                <p className='agent-info-title'>Email: <span className='agent-info-text'>{listing.agent_email}</span></p>
                                                                <p className='agent-info-title'>Brokerage: <span className='agent-info-text'>{listing.brokerage_name}</span></p>
                                                            </div>
                                                        </div>
                                                        <p className='agent-setup-disclaimer'>Report an error? Please email: support@havyn.com</p>
                                                    </>
                                                ) : (
                                                    <>

                                                    </>
                                                )}
                                                <br />
                                                <p className='modal-section-header'>Financial Information</p>
                                                <div className="profile-section-divider"></div>
                                                <div className='buyer-modal-title'>Purchase Terms: 
                                                    <div className='buyer-modal-bubble-container'>
                                                        {listing.purchase_terms.map((term, index) => (
                                                            <span key={index} className='term-bubble'>
                                                                {term}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isImageGalleryOpen && createPortal(
                                        <ImageGallery
                                            images={images}
                                            startIndex={currentImageIndex}
                                            onClose={() => setIsImageGalleryOpen(false)}
                                        />,
                                        document.getElementById('modal-root')
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {isDeleting && createPortal(
                        <div className='delete-confirmation-modal'>
                            <div className='delete-confirmation-content'>
                                <p className='dashboard-title'>Are you sure you want to <strong>delete</strong> this listing?</p>
                                <div className='delete-modal-button-container'>
                                    <button className='delete-cancel-btn' onClick={handleCancelDelete}>Cancel</button>
                                    <button className='delete-confirm-btn' onClick={handleDeleteConfirm}>Yes, Delete</button>
                                </div>
                            </div>
                        </div>,
                        document.getElementById('modal-root')
                    )}
                </div>
            </div>
        </div>,
        document.getElementById('modal-root')
    );
}
