import React, {useEffect} from 'react';
import './style.css';
import { AppleLogo, GoogleLogo, FacebookLogo, HavynLogo } from '../../assets/icons/icons';
import { motion as m } from 'framer-motion';


export default function Login(props) {
    //////////////////////////////////////////////////////
    const yourClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const yourRedirectUri = process.env.REACT_APP_REDIRECT_URI
    //////////////////////////////////////////////////////
    useEffect(() => {
        // Check if the Notification API is supported
        if ("Notification" in window) {
            // Request permission to use the Notification API
            Notification.requestPermission().then(permission => {
            if (permission === "granted") {
                // Use the Notification API
                const notification = new Notification("Welcome!", {
                body: "You have successfully logged in."
                });
            } else {
                // console.log("Notification permission denied.");
            }
            });
        } else {
            // console.log("This browser does not support desktop notifications.");
        }  
    }, []);
    
    
    const handleTroubleSignIn = () => {
        // console.log('this code is running');
        fetch(`${process.env.REACT_APP_API_URL}/api/test`, {
          method: 'GET',
        })
          .then(response => response.json())
          .then(data => {
            // console.log(data.message);
          })
          .catch(error => {
            console.error('Error:', error);
          });
    };
      

    const googleLoginUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${yourClientId}&redirect_uri=${yourRedirectUri}&scope=profile email&response_type=code`;
    return(
        <main className='login-main'>
            <div className='background'>
                <m.div initial={{ y: '20%' , opacity:0 }} animate={{ y: 0 , opacity:1 }} transition={{ delay: .5, duration:0.5}} className='center-block'>
                    <m.div initial={{ y: '100%', opacity:0 }} animate={{ y: 0, opacity:1 }} transition={{ delay: 1, duration:0.5}} className='logo-container'>
                        <div className= 'login-havyn-logo'>
                            <HavynLogo/>
                        </div>
                    </m.div>
                    <a href={googleLoginUrl} className='sign-on-container'>
                        <div className='login-logo-base'>
                            <GoogleLogo/>
                        </div>
                        <p className='sign-on-text'>Sign In with Google</p>
                    </a>
                    {/* <a href='/loading' className='sign-on-container'>
                        <div className='login-logo-base'>
                            <AppleLogo/>
                        </div>
                        <p className='sign-on-text'>Sign In with Apple</p>
                    </a>
                    <a href='/loading' className='sign-on-container'>
                        <div className='login-logo-base'>
                            <FacebookLogo/>
                        </div>
                        <p className='sign-on-text'>Sign In with Facebook</p>
                    </a> */}
                    <button onClick={event => handleTroubleSignIn()} className='trouble-signing-in'>Trouble signing in?</button>
                </m.div>
            </div>
        </main>
    );
}