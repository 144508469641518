import React, { useState } from 'react';
import { ChatBackArrow } from '../../assets/icons/icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './style.css';

export default function ImageGallery({ images, startIndex, onClose }) {
    const [pageTransition, setPageTransition] = useState(false);

    const handlePageTransition = () => {
        setPageTransition(true);
        setTimeout(() => {
            onClose();
        }, 500);
    };

    return (
        <div className={`fullscreen-gallery-overlay ${pageTransition ? 'fade-out' : 'fade-in'}`}>
            <div className='fullscreen-gallery-container'>
                <div className='fullscreen-gallery-header'>
                    <ChatBackArrow className='fullscreen-gallery-back' onClick={handlePageTransition} />
                </div>
                <div className='fullscreen-gallery-content'>
                    <Carousel selectedItem={startIndex} useKeyboardArrows infiniteLoop>
                        {images.map((image, index) => (
                            <div key={index} className='fullscreen-image-wrapper'>
                                <img src={image} alt={`Uploaded ${index}`} className='fullscreen-image'/>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
}
