import React from 'react';
import Settings from '../components/Settings/Settings';
import NavHub from '../components/NavHub/NavHub';

export default function SettingsPage(props) {
    return (
        <>
            <div className='main-section'>
                <Settings/>
                <NavHub/>
            </div>
        </>
    );
}