import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import WelcomePage from './pages/WelcomePage';
import BuyerSetupPage from './pages/BuyerSetupPage';
import SellerSetupPage from './pages/SellerSetupPage';
import PropertyMatchePage from './pages/PropertyMatchePage';
import LoginPage from './pages/LoginPage';
import UserDashboard from './pages/UserDashboard';
import SettingsPage from './pages/SettingsPage';
import ChatLog from './pages/ChatLog';
import PaymentRouting from './pages/PaymentRouting';
import PaymentSelectionBuyer from './components/PaymentSelection/PaymentSelectionBuyer';
import PaymentSelectionSeller from './components/PaymentSelection/PaymentSelectionSeller';
import Loading from './components/Loading/Loading';
import AgentSetupPage from './pages/AgentSetupPage';
import initializeGA from './ga4';
import usePageTracking from './usePageTracking';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [canAddBuyerListing, setCanAddBuyerListing] = useState(false);
  const [canAddHomeListing, setCanAddHomeListing] = useState(false);

  // Initialize GA4
  useEffect(() => {
    initializeGA();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-profile`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setUser(data);
      } else {
        setUser(null);
      }
    };

    const checkListingStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/check-listing-status`, {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setCanAddBuyerListing(data.canAddBuyerListing);
          setCanAddHomeListing(data.canAddHomeListing);
        } else {
          setCanAddBuyerListing(false);
          setCanAddHomeListing(false);
        }
      } catch (error) {
        console.error('Error checking listing status:', error);
        setCanAddBuyerListing(false);
        setCanAddHomeListing(false);
      }
      setLoading(false);
    };

    fetchUser();
    checkListingStatus();
  }, []);

  if (loading) {
    return <Loading/>; // Replace with loading component
  }

  return (
    <Router>
      {/* Initialize GA4 Page Tracking */}
      <PageTracker />
      <Routes>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/' element={<Navigate replace to='/login' />} />
        <Route path='/loading' element={<Loading/>}/>
        <Route path='/welcome' element={
          user && user.role ?
            <UserDashboard/> :
            (user ? <WelcomePage /> : <Navigate replace to='/login' />)
        }/>
        <Route path='/buyer-setup' element={
          user && canAddBuyerListing ? 
            <BuyerSetupPage /> : 
            (user ? <Navigate replace to='/payment-selection-buyer' /> : <Navigate replace to='/login' />)
        } />
        <Route path='/seller-setup' element={
          user && canAddHomeListing ?
            <SellerSetupPage /> : 
            (user ? <Navigate replace to='/payment-selection-seller' /> : <Navigate replace to='/login' />)
        }/>
        {/* ADD THIS COME TO CONDITION BELOW WHEN DONE  THERE IS A SPECIAL CASE HERE BELOW*/}
        {/* user && user.role === 'agent' */}
        <Route path='/agent-setup' element={
          user ?
            <AgentSetupPage /> : 
            (user ? <Navigate replace to='/dashboard' /> : <Navigate replace to='/login' />)
        }/>
        <Route path='/dashboard' element={user ? <UserDashboard /> : <Navigate replace to='/login' />} />
        <Route path='/property/matches' element={user ? <PropertyMatchePage /> : <Navigate replace to='/login' />} />
        <Route path='/settings' element={user ? <SettingsPage /> : <Navigate replace to='/login' />} />
        <Route path='/chat' element={user ? <ChatLog /> : <Navigate replace to='/login' />} />
        <Route path='/payment-buyer' element={
          user ? <PaymentRouting listingType='buyer' /> : <Navigate replace to='/login' />
        } />
        <Route path='/payment-buyer-pro' element={
          user ? <PaymentRouting listingType='buyer_pro' /> : <Navigate replace to='/login' />
        } />
        <Route path='/payment-seller' element={
          user ? <PaymentRouting listingType='seller' /> : <Navigate replace to='/login' />
        } />
        <Route path='/payment-selection-buyer' element={
          user ? <PaymentSelectionBuyer /> : <Navigate replace to='/login' />}
        />
        <Route path='/payment-selection-seller' element={
          user ? <PaymentSelectionSeller /> : <Navigate replace to='/login' />}     
        />
      </Routes>
    </Router>
  );
}

// Initialize GA4 Page Tracking
function PageTracker() {
  usePageTracking();
  return null;
}

export default App;
