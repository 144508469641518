import React from 'react';
import Payment from '../components/Payment/Payment';

export default function PaymentRouting(props) {
    switch (props.listingType) {
        case ('buyer')  :
            // console.log('routing buyer working')
            return  <Payment listingType={props.listingType}/>
        case ('buyer_pro') :
            // console.log('routing buyer pro working')
            return  <Payment listingType={props.listingType}/>
        case ('seller') :
            // console.log('routing seller working')
            return <Payment listingType={props.listingType}/>
        default:
            console.error('Listing type not support');
            break;
    }
}