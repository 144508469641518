import React from 'react';
import SellerSetup from '../components/SellerSetup/SellerSetup';


export default function SellerSetupPage(props) {
    return (
        <>
            <div className='seller-setup-main-section'>
                <SellerSetup/>
            </div>
        </>
    );
}