import ReactGA from 'react-ga4';

export default function initializeGA() {
  const measurementId = process.env.REACT_APP_GA4_MEASUREMENT_ID;
  if (measurementId) {
    // Dynamically add the Google Analytics script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.head.appendChild(script);

    // Initialize GA once the script is loaded
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', measurementId);
    };

    ReactGA.initialize(measurementId);
  } else {
    console.error('GA4 Measurement ID is not set');
  }
};
