import React, { useEffect, useState, useRef, useCallback } from 'react';
import "./style.css";
import { createPortal } from 'react-dom';
import { LocationIconOutline, SearchIcon, ChatBackArrow, Calendar, DotsIcon, DeleteIcon, AgentIcon, PreApprovedIcon, SendIcon } from '../../assets/icons/icons';
import { google, CalendarEvent } from "calendar-link";
import defaultProfileImg from '../../assets/images/default-profile.png';
import BuyerModal from '../BuyerModal/BuyerModal'

// WebSocket Connection File
import socket from '../../socket'; // Path to your socket file
import ListingModal from '../ListingModal/ListingModal';

export function ChatWindow({ onClose, chatName, chatImage, chatAddress, chatZipcode, chatEmail, senderId, recipientId, onMessageSent, agentValidated, agentName, agentBrokerage, agentLicense, isPreApproved, userId }) {
    const [isListingOpen, setIsListingOpen] = useState(false);
    const [isBuyerModalOpen, setIsBuyerModalOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [showInvite, setShowInvite] = useState(false);
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [isListingOwner, setIsListingOwner] = useState(false);
    const [listingDetails, setListingDetails] = useState(null);
    const messagesEndRef = useRef(null);
    const [pageTransition, setPageTransition] = useState(false)

    // Buyer Profile Data
    const [buyerProfiles, setBuyerProfiles] = useState([]);

    useEffect(() => {
        const fetchChat = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chats?senderId=${senderId}&recipientId=${recipientId}&inquiryAddress=${encodeURIComponent(chatAddress)}&inquiryZipcode=${chatZipcode}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    // console.log('Fetched chat messages:', data);
                    setMessages(data);
                    markMessagesAsSeen();
                } else {
                    throw new Error('Failed to fetch chat messages');
                }
            } catch (error) {
                console.error('Error fetching chat messages:', error);
            }
        };

        const fetchBuyerDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-buyer-profiles`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include', // Needed to send the cookie with the request
                });
                if (response.ok) {
                    const data = await response.json();
                    // console.log(`This is the Buyer Profile Data: ${data}`)
                    setBuyerProfiles(data);
                } else {
                    throw new Error('Failed to fetch or No Buyer Profiles');
                }
            } catch (error) {
                console.error('Error fetching listings:', error);
            }
        }

        const markMessagesAsSeen = async () => {
            try {
                await fetch(`${process.env.REACT_APP_API_URL}/api/mark-messages-seen`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        senderId: recipientId,  // Messages from recipient to the current user should be marked as seen
                        recipientId: senderId,
                        inquiryAddress: chatAddress,
                        inquiryZipcode: chatZipcode
                    }),
                    credentials: 'include',
                });
            } catch (error) {
                console.error('Error marking messages as seen:', error);
            }
        };

        const checkListingOwner = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/listing-details`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ property_address: chatAddress, zipcode: chatZipcode }),
                });
                if (response.ok) {
                    const data = await response.json();
                    setListingDetails(data);
                    setIsListingOwner(data.user_id === senderId);
                } else {
                    throw new Error('Failed to check listing owner');
                }
            } catch (error) {
                console.error('Error checking listing owner:', error);
            }
        };

        fetchChat();
        fetchBuyerDetails()
        checkListingOwner();

        socket.on('receiveMessage', (message) => {
            if ((message.senderId === recipientId && message.recipientId === senderId) ||
                (message.senderId === senderId && message.recipientId === recipientId)) {
                setMessages(prevMessages => [...prevMessages, message]);
                onMessageSent(); // Notify parent component to refresh chat list
            }
        });

        return () => {
            socket.off('receiveMessage');
        };
    }, [senderId, recipientId, onMessageSent, chatAddress, chatZipcode, userId]);

    // Fetches Users Emails
    const fetchUserProfileById = async (userId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-profile/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
    
            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                throw new Error('Failed to fetch user profile');
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    };
    
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const formatDate = (date) => {
        const dateObj = new Date(date);
        const day = String(dateObj.getUTCDate()).padStart(2, '0');
        const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
        const year = dateObj.getUTCFullYear();
        return `${month}/${day}/${year}`;
    };

    const formatTime = (time) => {
        const [hour, minute] = time.split(':');
        const suffix = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}:${minute} ${suffix}`;
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;

        const message = {
            senderId,
            recipientId,
            message: newMessage,
            inquiryAddress: chatAddress, // This should be set to the property address being inquired about
            inquiryZipcode: chatZipcode  // Ensure to include the zipcode for accurate identification
        };

        try {
            socket.emit('sendMessage', message);
            setNewMessage('');
            onMessageSent(); // Notify parent component to refresh chat list
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const handleSendInvite = async () => {
        // console.log('handleSendInvite called');
        // console.log('date:', date);
        // console.log('time:', time);
        // console.log(`formatted Date: ${formatDate(date)}`);

        const inviteMessage = {
            senderId,
            recipientId,
            message: `Showing Request Sent`,
            inquiryAddress: chatAddress,
            inquiryZipcode: chatZipcode,
            inviteDetails: { date: formatDate(date), time: formatTime(time) }
        };

        // console.log('inviteMessage:', inviteMessage);

        try {
            socket.emit('sendMessage', inviteMessage);
            setDate('');
            setTime('');
            setShowInvite(false);
            onMessageSent(); // Notify parent component to refresh chat list
        } catch (error) {
            console.error('Error sending invite message:', error);
        }
    };

    const handleDeclineInvite = (inviteDetails) => {
        // Hide buttons on decline
        setMessages(prevMessages =>
            prevMessages.map(msg =>
                msg.inviteDetails === inviteDetails
                    ? { ...msg, accepted: true }
                    : msg
            )
        );
    };

    const handleAcceptInvite = async (inviteDetails) => {
        const { date, time } = inviteDetails;
    
        try {
            const senderProfile = await fetchUserProfileById(senderId);
            const recipientProfile = await fetchUserProfileById(recipientId);
    
            const newEvent = {
                title: "Home Tour",
                description: "This is a showing event created using Havyn",
                start: `${formatDate(date)} ${time}`,
                location: `${chatAddress}, ${listingDetails.city}, ${listingDetails.state} ${chatZipcode}`,
                duration: [1, "hour"],
                guests: [senderProfile.email, recipientProfile.email], // Add both user emails
            };
    
            const googleUrl = google(newEvent);
            window.open(googleUrl, '_self');
    
            // Hide buttons on accept
            setMessages(prevMessages =>
                prevMessages.map(msg =>
                    msg.inviteDetails === inviteDetails
                        ? { ...msg, accepted: true }
                        : msg
                )
            );
        } catch (error) {
            console.error('Error creating calendar event:', error);
        }
    };
    

    const handleListingOpen = () => {
        setIsListingOpen(false);
    };

    const handleBuyerModalOpen = () => {
        if (recipientId !== senderId && isListingOwner ) {
            setIsBuyerModalOpen(true);
        }
    };

    const handleChatWindowClose = () => {
        setPageTransition(true)
        setTimeout(() => {
            onClose()
        },500)
    }

    return (
        <>
            <div className={`listing-details-modal ${pageTransition ? 'leftClose' : 'left'}`}>
                <div className='chatwindow-bg'>
                    <div className='chat-window-container'>
                        <div className='chatwindow-hd'>
                            <ChatBackArrow className='chatwindow-back' onClick={(e) => handleChatWindowClose()}/>
                            <div className='chatwindow-userinfo'>
                                <div className='match-details-image-container' onClick={handleBuyerModalOpen}>
                                    { agentValidated ? (
                                        <>
                                            <AgentIcon className='agent-icon'/>
                                        </>
                                        ) : (<></>
                                    )}
                                    <img src={chatImage || defaultProfileImg} className='match-img' alt='chat-img' />
                                </div>
                                <h2 className='chat-name' onClick={handleBuyerModalOpen}>{agentName ? agentName : chatName}
                                    <span className='match-details-approve-icon'>    
                                        {isPreApproved ? <PreApprovedIcon className='pre-approved-icon'/>: <></>}
                                    </span>
                                </h2>
                                <p className='chatwindow-address' onClick={() => setIsListingOpen(true)}>{chatAddress}</p>
                                { isListingOpen ? (
                                    <>
                                        <ListingModal onClose={handleListingOpen} chatAddress={chatAddress} chatZipcode={chatZipcode} isPreApproved={isPreApproved} agentValidated={agentValidated}/>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                            {isListingOwner && (
                                <Calendar className='chatwindow-calendar' onClick={() => setShowInvite(true)} />
                            )}
                        </div>
                        <div className='chatwindow-msgs-container'>
                            {messages.map((msg, index) => (
                                <div key={index} className={msg.senderId === senderId ? 'seller-txt-container' : 'buyer-txt-container'}>
                                    <div className={msg.senderId === senderId ? 'seller-setright' : 'buyer-setleft'}>
                                        <p id={msg.senderId === senderId ? 'seller' : 'buyer'}>{msg.message}</p>
                                        {msg.inviteDetails && !msg.accepted && msg.senderId !== senderId ? (
                                            <>
                                                <div className="invite-card">
                                                    <p className='chat-text'>Showing invite:</p>
                                                    <p className='chat-text'>Where: <span className='chat-subtext'>{chatAddress}</span></p>
                                                    <p className='chat-text'>Date: <span className='chat-subtext'>{msg.inviteDetails.date}</span></p>
                                                    <p className='chat-text'>Time: <span className='chat-subtext'>{msg.inviteDetails.time}</span></p>
                                                    <div className='delete-modal-button-container'>
                                                        <button className="accept-btn" onClick={(e) => handleAcceptInvite(msg.inviteDetails)}>Accept</button>
                                                        <button className="decline-btn" onClick={(e) => handleDeclineInvite(msg.inviteDetails)}>Decline</button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>
                        <div className='chatwindow-btn-container'>
                            <input 
                                className='chatwindow-txt-bar'
                                placeholder='Type Here'
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                            />
                            <button type='button' className='send-chat-btn' onClick={(event) => handleSendMessage()}><SendIcon className='send-icon' stroke='#FFFFFF'/></button>
                        </div>
                    </div>
                </div>
                {showInvite && (
                    <div className='chat-cal-bg'>
                        <div className='chat-cal-popup-cont'>
                            <div className='chat-cal-popup-slider'>
                                <p className='chat-cal-cancel' onClick={() => setShowInvite(false)}>Cancel</p>
                            </div>
                            <div className='chat-cal-popup-time-sel'>
                                <div className='chat-cal-time-sel-title'>Set a viewing appointment for<br/>{chatAddress}</div>
                                <div className='chat-cal-time-selection'>
                                    <div className='invite-selection-container'>
                                        <h2>Date:</h2>
                                        <input type='date' className='chat-cal-date-input' name="date" value={date} onChange={(e) => setDate(e.target.value)}></input>
                                    </div>
                                    <div className='invite-selection-container'>
                                        <h2 className='invite-selection-title'>Time:</h2>
                                        <input type='time' className='chat-cal-time-input' name="time" step="900" value={time} onChange={(e) => setTime(e.target.value)}></input>
                                    </div>
                                </div>
                            </div>
                            <div className='chat-cal-popup-foot'>
                                <button className='chat-cal-inv-btn' onClick={handleSendInvite}>Send Invite</button>
                            </div>
                        </div>
                    </div>
                )}
                {isBuyerModalOpen && createPortal(
                    <BuyerModal
                        recipientId={recipientId}
                        onClose={() => setIsBuyerModalOpen(false)}
                    />,
                    document.getElementById('modal-root')
                )}
            </div>
        </>
    );
}

export function ChatItem(props) {
    const [isOpen, setIsOpen] = useState(false);

    if (isOpen) {
        document.body.style.removeProperty('overflow');
    } else {
        document.body.style.overflow = 'hidden';
    }

    const { 
        chatImage, 
        chatName, 
        chatPreviewText, 
        missedCount, 
        chatTime, 
        chatAddress,
        chatZipcode, 
        chatEmail, 
        senderId, 
        recipientId, 
        onChatOpen, 
        isDeleteMode, 
        handleSelectChat, 
        isSelected, 
        agentValidated,
        agentName,
        agentBrokerage,
        agentLicense, 
        isPreApproved
    } = props;

    const chatId = `${recipientId}-${chatAddress}-${chatZipcode}`;

    return (
        <>
            <div 
                className={`chat-item ${isDeleteMode && isSelected ? 'selected' : ''}`}
                onClick={() => {
                    if (isDeleteMode) {
                        handleSelectChat(chatId);
                    } else {
                        setIsOpen(true);
                        onChatOpen(chatId);
                    }
                }}
            >
                { isDeleteMode ?
                    <>
                        <div className={isSelected ? 'radial-dot-selected':'radial-dot'}></div>
                    </> : 
                    <>

                    </>
                }
                <div className='chat-img-container'>
                    <div className='match-details-image-container' >
                        { agentValidated ? (
                            <>
                                <AgentIcon className='agent-icon'/>
                            </>
                            ) : (<></>
                        )}
                        <img src={chatImage || defaultProfileImg} className='match-img' alt='chat-img' />
                    </div>
                </div>
                <div className='chat-details-container'>
                    <div className='chat-details'>
                        <h2 className='chat-name'>{props.agentName ? props.agentName : chatName}
                            <span className='match-details-approve-icon'>    
                                {props.isPreApproved ? <PreApprovedIcon className='pre-approved-icon'/>: <></>}
                            </span>
                        </h2>
                        <p className='chat-agent'><LocationIconOutline className='location-icon'/> {chatAddress}</p>
                        <p className='chat-preview-text'>{chatPreviewText}</p>
                    </div>
                    <div className='chat-meta-data'>
                        <p className='chat-time'>{chatTime}</p>
                        <p className='chat-missed-count'>{missedCount > 0 ? missedCount : ''}</p>
                    </div>
                </div>
            </div>
            {isOpen && createPortal(
                <ChatWindow 
                    chatImage={chatImage} 
                    chatName={chatName} 
                    chatAddress={chatAddress}
                    chatZipcode={chatZipcode}
                    chatEmail={chatEmail}
                    senderId={senderId}
                    agentValidated={agentValidated}
                    agentName={agentName}
                    agentBrokerage={agentBrokerage}
                    agentLicense={agentLicense}
                    isPreApproved={isPreApproved} 
                    recipientId={recipientId}
                    onClose={() => setIsOpen(false)} 
                    onClick={() => setIsOpen(false)}
                    onMessageSent={onChatOpen} // Notify parent component to refresh chat list
                    userId={senderId}
                />,
                document.getElementById('chat-window')
            )}
        </>
    );
}

export default function ChatSelection(props) {
    const [buyerChats, setBuyerChats] = useState([]);
    const [propertyChats, setPropertyChats] = useState([]);
    const [userId, setUserId] = useState(null);
    const [isDeleteMode, setIsDeleteMode] = useState(false);
    const [selectedChats, setSelectedChats] = useState([]);
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);
    const [isSelected, setIsSelected] = useState(true);

    const fetchChatUsers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chat-users`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                // console.log('Fetched chat users:', data);
                setBuyerChats(data.buyerChats);
                setPropertyChats(data.propertyChats);
            } else {
                throw new Error('Failed to fetch chat users');
            }
        } catch (error) {
            console.error('Error fetching chat users:', error);
        }
    };

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-profile`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    // console.log(data.userId);
                    setUserId(data.userId);
                } else {
                    throw new Error('Failed to fetch user profile');
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
        fetchChatUsers();
    }, []);

    useEffect(() => {
        if (Notification.permission !== "granted" && Notification.permission !== "denied") {
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                    // console.log("Notification permission granted.");
                }
            });
        }
    }, []);

    const handleChatOpen = useCallback(async (chatId) => {
        // console.log('handleChatOpen called with chatId:', chatId);
        if (!chatId) {
            console.error('chatId is undefined');
            return;
        }

        const [recipientId, chatAddress, chatZipcode] = chatId.split('-');
        // console.log('Parsed chatId values:', recipientId, chatAddress, chatZipcode);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/mark-messages-seen`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    senderId: recipientId,
                    recipientId: userId,
                    inquiryAddress: chatAddress,
                    inquiryZipcode: chatZipcode
                }),
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to mark messages as seen');
            }

            fetchChatUsers();
        } catch (error) {
            console.error('Error marking messages as seen:', error);
        }
    }, [userId]);

    const toggleDeleteMode = () => {
        setIsDeleteMode(!isDeleteMode);
        setSelectedChats([]);
    };

    const handleSelectChat = (chatId) => {
        setSelectedChats(prevSelected => 
            prevSelected.includes(chatId)
                ? prevSelected.filter(id => id !== chatId)
                : [...prevSelected, chatId]
        );
    };

    const handleDeleteIconClick = () => {
        if (selectedChats.length > 0) {
            // console.log(selectedChats)
            setIsConfirmDelete(true);
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/delete-chats`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ chatIds: selectedChats }),
            });

            if (response.ok) {
                // console.log('Chats deleted successfully');
                setIsConfirmDelete(false);
                toggleDeleteMode();
                fetchChatUsers();
            } else {
                throw new Error('Failed to delete chats');
            }
        } catch (error) {
            console.error('Error deleting chats:', error);
        }
    };

    const handleCancelDelete = () => {
        setIsConfirmDelete(false);
    };

    function ChatNav() {
        return (
            <>
                <div className='chat-nav-container'>
                    <div className='chat-nav-item' onClick={() => setIsSelected(true)}>
                        <p className={`listing-nav-text ${ isSelected ? 'listing-nav-header' : 'listing-nav-header-false'}`}>Sellers</p>
                    </div>
                    <div className='chat-nav-item' onClick={() => setIsSelected(false)}>
                        <p className={`listing-nav-text ${ isSelected ? 'listing-nav-header-false' : 'listing-nav-header'}`}>My Listings</p>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className='chat-screen-container'>
            <div className='chat-info-container'>
                <div className='chat-info'>
                    <h1 className='chat-title'>Inbox</h1>
                    <div className='chat-icons-container'>
                        {!isDeleteMode && <DotsIcon className='dots-icon' fill={'#6246EA'} onClick={toggleDeleteMode}/>}
                        {isDeleteMode && <p className='cancel-icon' onClick={toggleDeleteMode}>Cancel</p>}
                        {isDeleteMode && <DeleteIcon className='delete-icon' onClick={handleDeleteIconClick}/>}
                    </div>
                </div>
                {/* <div className='chat-actions'>
                    <SearchIcon className="chat-search-icon" fill="none"/>
                    <input name="myinput" placeholder="Search" className="chat-search"></input>
                </div> */}
                <div className='profile-section-divider'></div>
            </div>
            <ChatNav/>
            { isSelected ? (
                <>
                    <div className='chat-logs-container'>
                        {buyerChats.length > 0 ? (
                            buyerChats.map((chat, index) => (
                                <ChatItem 
                                    key={index}
                                    chatImage={chat.profilePicture || defaultProfileImg} 
                                    chatName={chat.displayName.split(' ')[0]} 
                                    chatAddress={chat.inquiry_address}
                                    chatZipcode={chat.inquiry_zipcode}
                                    chatPreviewText={chat.lastMessage}
                                    isPreApproved={chat.pre_approved} 
                                    agentValidated={chat.validated}
                                    agentName={chat.name}
                                    agentBrokerage={chat.brokerage}
                                    agentLicense={chat.credential_number}
                                    missedCount={chat.unreadCount} 
                                    chatTime={''}
                                    chatEmail={chat.email}
                                    senderId={userId}
                                    recipientId={chat.userId}
                                    onChatOpen={handleChatOpen}
                                    isDeleteMode={isDeleteMode}
                                    handleSelectChat={handleSelectChat}
                                    isSelected={selectedChats.includes(`${chat.userId}-${chat.inquiry_address}-${chat.inquiry_zipcode}`)}
                                />
                            ))
                        ) : (
                            <p>No chats available</p>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className='chat-logs-container'>
                        {propertyChats.length > 0 ? (
                            propertyChats.map((chat, index) => (
                                <ChatItem 
                                    key={index}
                                    chatImage={chat.profilePicture || defaultProfileImg} 
                                    chatName={chat.displayName.split(' ')[0]} 
                                    chatAddress={chat.inquiry_address}
                                    chatZipcode={chat.inquiry_zipcode}
                                    chatPreviewText={chat.lastMessage}
                                    isPreApproved={chat.pre_approved} 
                                    agentValidated={chat.validated}
                                    agentName={chat.name}
                                    agentBrokerage={chat.brokerage}
                                    agentLicense={chat.credential_number}
                                    missedCount={chat.unreadCount} 
                                    chatTime={''}
                                    chatEmail={chat.email}
                                    senderId={userId}
                                    recipientId={chat.userId}
                                    onChatOpen={handleChatOpen}
                                    isDeleteMode={isDeleteMode}
                                    handleSelectChat={handleSelectChat}
                                    isSelected={selectedChats.includes(`${chat.userId}-${chat.inquiry_address}-${chat.inquiry_zipcode}`)}
                                />
                            ))
                        ) : (
                            <p>No chats available</p>
                        )}
                    </div>
                </>
            )}
            {isConfirmDelete && createPortal(
                <div className='delete-confirmation-modal'>
                    <div className='delete-confirmation-content'>
                        <p className='dashboard-title'>Are you sure you want to <strong>delete</strong> {selectedChats.length} conversations?</p>
                        <div className='delete-modal-button-container'>
                            <button className='delete-cancel-btn' onClick={handleCancelDelete}>Cancel</button>
                            <button className='delete-confirm-btn' onClick={handleDeleteConfirm}>Yes, Delete</button>
                        </div>
                    </div>
                </div>,
                document.getElementById('modal-root')
            )}
        </div>
    );
}

