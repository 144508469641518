import React from 'react'
import Login from '../components/Login/Login';

export default function LoginPage(props){
    return (
        <main>
            <>
                <Login/>
            </>
        </main>
    );
}