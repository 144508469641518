import React, { useEffect, useState } from 'react';
import { ChatBackArrow, PreApprovedIcon, ReportIcon, AgentIcon, SingleFamilyIcon, CondoIcon, ApartmentIcon, TownhouseIcon } from '../../assets/icons/icons';
import defaultProfileImg from '../../assets/images/default-profile.png';
import './style.css'

// WebSocket Connection File
import socket from '../../socket'; // Path to your socket file

export default function MatchDetails({match, onClose, agentData, listingData}) {
    const [pageTransition, setPageTransition] = useState(false)
    const [isOpen, SetIsOpen] = useState(false)
    const [newMessage, setNewMessage] = useState('');
    const [user, setUser] = useState({});
    const [lotSizeMin, setLotSizeMin] = useState('');
    const [lotSizeMax, setLotSizeMax] = useState('');
    // console.log(`This is the match data ${JSON.stringify(match)}`)
    
    const fallbackName = "error"; // Fallback name for testing or if user name is not available

    const parsedMatch = {
        ...match,
        view: JSON.parse(match.view || "[]"),
        exterior_features: JSON.parse(match.exterior_features || "[]"),
        interior_features: JSON.parse(match.interior_features || "[]"),
        flooring: JSON.parse(match.flooring || "[]"),
        appliances: JSON.parse(match.appliances || "[]"),
        hoa_amenities: JSON.parse(match.hoa_amenities || "[]"),
        kitchen: JSON.parse(match.kitchen || "[]"),
        utilities: JSON.parse(match.utilities || "[]"),
        purchase_terms: JSON.parse(match.purchase_terms || "[]"),
    };

    useEffect(() => {
        // console.log(`This is the last stop for ${JSON.stringify(listingData)}`)
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-profile`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    // console.log(data)
                    setUser(data);
                } else {
                    throw new Error('Failed to fetch');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchUserProfile()
    },[])

    useEffect(()=>{
        setLotSizeMin(() =>{
            switch (match.min_lot_size) {
            case 0:
                return 'No Min';
            case 10890:
                return '1/4 acre';
            case 21780:
                return '1/2 acre';
            case 43560:
                return '1 acre';
            case 87120:
                return '2 acres';
            case 217800:
                return '5 acres';
            case 435600:
                return '10 acres';
            case 871200:
                return '20 acres';
            case 2178000:
                return '50 acres';
            default:
                return ;
            }
        })
        setLotSizeMax(() =>{
            switch (match.max_lot_size) {
            case 0:
                return 'No Max';
            case 10890:
                return '1/4 acre';
            case 21780:
                return '1/2 acre';
            case 43560:
                return '1 acre';
            case 87120:
                return '2 acres';
            case 217800:
                return '5 acres';
            case 435600:
                return '10 acres';
            case 871200:
                return '20 acres';
            case 2178000:
                return '50+ acres';
            default:
                return ;
            }
        })
    }, [match.min_lot_size, match.max_lot_size])

    // Function to format numbers with commas and add "+" if it's the maximum value
    const formatNumber = (value, max = false, suffix = '') => {
        const formattedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return max && value >= 20000000 ? `$${formattedValue}+` : `$${formattedValue}${suffix}`;
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;

        const message = {
            senderId: user.userId,
            recipientId: match.user_id,
            message: newMessage,
            inquiryAddress: listingData.property_address, // Sets property address being inquired about
            inquiryZipcode: listingData.zipcode // Sets zipcode of address being inquired about
        };

        try {
            socket.emit('sendMessage', message);
            onClose()
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const handlePageTransition = () => {
        setPageTransition(true)
        setTimeout(() => {
            onClose()
        },500)
    }

    return (
        <>
            <div className={`match-details-modal-overlay ${pageTransition ? 'leftClose': 'left'}`}>
                <div className='chatwindow-bg'>
                    <div className='chat-window-container'>
                        <div className='chatwindow-hd'>
                            <ChatBackArrow className='chatwindow-back' onClick={(e) => handlePageTransition()}/>
                            <div className='chatwindow-userinfo'>
                                <div className='match-details-image-container' >
                                    { match.role === 'agent' ? (
                                        <>
                                            <AgentIcon className='agent-icon'/>
                                        </>
                                        ) : (<></>
                                    )}
                                    <img src={match.profilePicture || defaultProfileImg} className='match-img' alt='chat-img' />
                                </div>
                                <h1 className='match-detials-name'>
                                    { match.role === 'agent' ? match.name : match.displayName.split(' ')[0] || fallbackName }
                                    <span className='match-details-approve-icon'>    
                                        {match.pre_approved ? <PreApprovedIcon className='pre-approved-icon'/>: <></>}
                                    </span>
                                </h1>
                            </div>
                            {/* ADD THE REPORT FUNCTIONALITY HERE */}
                            {/* <ReportIcon/> */}
                        </div>
                        <div className='buyer-modal-content'>
                            { match.role === 'agent' ? (
                                <>
                                    <h2 className='buyer-modal-title'>This user is an Agent</h2>
                                    <div className='transaction-container'>
                                        <p className='agent-info-title'>Agent Name: <span className='agent-info-text'>{match.name}</span></p>
                                        <p className='agent-info-title'>License Number: <span className='agent-info-text'>{match.credential_number}</span></p>
                                        <p className='agent-info-title'>Brokerage: <span className='agent-info-text'>{match.brokerage}</span></p>
                                    </div>
                                </>
                                ) : (<></>
                            )}
                            <p className='modal-section-header'>Property Types</p>
                            <div className="profile-section-divider"></div>
                            <div className='buyer-modal-title'> 
                                <div className='buyer-modal-bubble-container'>
                                {JSON.parse(match.property_type).map((term, index) => (
                                    <span key={index} className='buyer-modal-boxes active'>
                                        {(() => {
                                            switch (term) {
                                                case 'Single Family':
                                                    return (
                                                        <>
                                                            <SingleFamilyIcon />
                                                            <span className='setup-page3-boxes-title'>Single Family</span>
                                                        </>
                                                    )
                                                case 'Condo':
                                                    return (
                                                        <>
                                                            <CondoIcon />
                                                            <span className='setup-page3-boxes-title'>Condo</span>
                                                        </>
                                                    )
                                                case 'Apartment':
                                                    return (
                                                        <>
                                                            <ApartmentIcon />
                                                            <span className='setup-page3-boxes-title'>Apartment</span>
                                                        </>
                                                    )
                                                case 'TownHome':
                                                    return (
                                                        <>
                                                            <TownhouseIcon />
                                                            <span className='setup-page3-boxes-title'>TownHome</span>
                                                        </>
                                                    )
                                                default:
                                                    return null; 
                                            }
                                        })()}
                                    </span>
                                ))}
                                </div>
                            </div>
                            <br />
                            <p className='modal-section-header'>Location</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>City: <span className='buyer-modal-text'>{match.city}</span></p>
                            <p className='buyer-modal-title'>State: <span className='buyer-modal-text'>{match.state}</span></p>
                            <p className='buyer-modal-title'>ZIP Code: <span className='buyer-modal-text'>{match.zipcode}</span></p>
                            <br />
                            <p className='modal-section-header'>Buying or Renting</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'><span className='term-bubble'>{match.listing_status === 'for_sale' ? 'Buying' : 'Renting'}</span></p>
                            <br />
                            <p className='modal-section-header'>Home Criteria</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>Bedrooms: <span className='buyer-modal-text'>{match.bedrooms}+</span></p>
                            <p className='buyer-modal-title'>Bathrooms: <span className='buyer-modal-text'>{match.bathrooms}+</span></p>
                            <p className='buyer-modal-title'>Min Square Footage: <span className='buyer-modal-text'>{match.min_sqft} sqft</span></p>
                            <p className='buyer-modal-title'>Max Square Footage: <span className='buyer-modal-text'>{match.max_sqft} sqft</span></p>
                            <br />
                            <p className='modal-section-header'>Home Criteria cont.</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>Den: <span className='buyer-modal-text'>{match.den === 0 ? 'any' : match.den}+</span></p>
                            <p className='buyer-modal-title'>Stories/Levels: <span className='buyer-modal-text'>{match.stories_level === 0 ? 'any' : match.stories_level}+</span></p>
                            <p className='buyer-modal-title'>Garage: <span className='buyer-modal-text'>{match.garage === 'any' ? 'Any' : match.garage === 'yes' ? 'Yes' : 'No'}</span></p>
                            {match.garage === 'yes' && <p className='buyer-modal-title'>Garage Spaces: <span className='buyer-modal-text'>{match.garage_spaces}+</span></p>}
                            <br />
                            <p className='modal-section-header'>Property Features</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>Min Lot Size: <span className='buyer-modal-text'>{lotSizeMin}</span></p>
                            <p className='buyer-modal-title'>Max Lot Size: <span className='buyer-modal-text'>{lotSizeMax}</span></p>
                            <p className='buyer-modal-title'>Pool: <span className='buyer-modal-text'>{match.pool === 'any' ? 'Any' : match.pool === 'yes' ? 'Yes' : 'No'}</span></p>
                            <p className='buyer-modal-title'>Waterfront: <span className='buyer-modal-text'>{match.waterfront === 'any' ? 'Any' : match.waterfront === 'yes' ? 'Yes' : 'No'}</span></p>
                            <p className='buyer-modal-title'>Gulf Access: <span className='buyer-modal-text'>{match.gulf_access === 'any' ? 'Any' : match.gulf_access === 'yes' ? 'Yes' : 'No'}</span></p>
                            <p className='buyer-modal-title'>Spa: <span className='buyer-modal-text'>{match.spa === 'any' ? 'Any' : match.spa === 'yes' ? 'Yes' : 'No'}</span></p>
                            <p className='buyer-modal-title'>Min Year: <span className='buyer-modal-text'>{match.min_year}</span></p>
                            <p className='buyer-modal-title'>Max Year: <span className='buyer-modal-text'>{match.max_year}</span></p>
                            <div className='buyer-modal-title'>View: 
                                <div className='buyer-modal-bubble-container'>
                                    {   match.view === null ? '' : 
                                        parsedMatch.view.map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <br />
                            <p className='modal-section-header'>Property Features cont.</p>
                            <div className="profile-section-divider"></div>
                            <div className='buyer-modal-title'>Exterior Features: 
                                <div className='buyer-modal-bubble-container'>
                                    {   match.exterior_features === null ? '' : 
                                        parsedMatch.exterior_features.map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className='buyer-modal-title'>Interior Features: 
                                <div className='buyer-modal-bubble-container'>
                                    {   match.interior_features === null ? '' : 
                                        parsedMatch.interior_features.map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className='buyer-modal-title'>Flooring: 
                                <div className='buyer-modal-bubble-container'>
                                    {   match.flooring === null ? '' : 
                                        parsedMatch.flooring.map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className='buyer-modal-title'>Appliances: 
                                <div className='buyer-modal-bubble-container'>
                                    {   match.appliances === null ? '' : 
                                        parsedMatch.appliances.map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <br />
                            <p className='modal-section-header'>Home Owners Association</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>HOA: <span className='buyer-modal-text'>{match.hoa === 'any' ? 'Any' : match.hoa === 'yes' ? 'Yes' : 'No'}</span></p>
                            {   match.hoa === 'yes' && <p className='buyer-modal-title'>Max HOA Fee(s): <span className='buyer-modal-text'>${match.max_hoa}</span></p>}
                            {   match.hoa === 'yes' && 
                                <div className='buyer-modal-title'>HOA Amenities: 
                                    <div className='buyer-modal-bubble-container'>
                                        {   match.hoa_amenities === null ? '' : 
                                            parsedMatch.hoa_amenities.map((term, index) => (
                                            <span key={index} className='term-bubble'>
                                                {term}
                                            </span>
                                    ))}
                                    </div>
                                </div>
                            }
                            <br />
                            <p className='modal-section-header'>Budget and Financing</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>Min Budget: <span className='buyer-modal-text'>{formatNumber(match.min_budget)}</span></p>
                            <p className='buyer-modal-title'>Max Budget: <span className='buyer-modal-text'>{formatNumber(match.max_budget)}</span></p>
                            <div className='buyer-modal-title'>Purchase Terms: 
                                <div className='buyer-modal-bubble-container'>
                                    {   match.purchase_terms === null ? '' : 
                                        parsedMatch.purchase_terms.map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <p className='buyer-modal-title'>Pre-Approved: <span className='buyer-modal-text'>{match.pre_approved ? 'Yes' : 'No'}</span></p>
                            <p className='buyer-modal-title'>Proof of Funds: <span className='buyer-modal-text'>{match.proof_of_funds ? 'Yes' : 'No'}</span></p>
                            <br />
                            <p className='modal-section-header'>Preferences</p>
                            <div className="profile-section-divider"></div>
                            <p className='buyer-modal-title'>Move-in Timeline: <span className='buyer-modal-text'>{match.move_in_timeline}</span></p>
                            <p className='buyer-modal-title'>Other Preferences: <span className='buyer-modal-text'><br />{match.other_preferences}</span></p>
                            <div className={ !isOpen ? 'match-details-modal-button-container' : 'match-details-modal-button-container-selected' }>
                                {isOpen ? 
                                    <>
                                        <input 
                                            className='match-txt-bar'
                                            placeholder='Type Here'
                                            value={newMessage}
                                            onChange={(e) => setNewMessage(e.target.value)}
                                            onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                                        />
                                        <button type='button' className='buyer-modal-save-btn' onClick={(event) => handleSendMessage()}>Send</button>
                                    </> : 
                                    <>
                                        <button type='button' className='buyer-modal-save-btn' onClick={(event) => SetIsOpen(true)}>Send a Message</button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}