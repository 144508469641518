import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { createPortal } from 'react-dom';
import './styles.css';
import { AgentIcon, BedIconBlack, ProfileArrow, ShowerIcon, SqftIconBlack } from '../../assets/icons/icons';
import BuyerProfileModal from '../BuyerProfileModal/BuyerProfileModal';
import ListingDetailsModal from '../ListingDetailsModal/ListingDetailsModal';
import home2 from '../../assets/images/dd-home-2.png';
import Pfp from '../../assets/images/EmilyCarter.png';

export default function Dashboard(props) {
    const [pageTransition, setPageTransition] = useState(false)
    const [user, setUser] = useState({});
    const [role, setRole] = useState('');
    const [agentData, setAgentData] = useState(null);
    const [listings, setListings] = useState([]);
    const [buyerProfiles, setBuyerProfiles] = useState([]);
    const [selectedBuyerProfile, setSelectedBuyerProfile] = useState(null);
    const [selectedListing, setSelectedListing] = useState(null); // State to track selected listing
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-profile`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include', // Needed to send the cookie with the request
                });
                if (response.ok) {
                    const data = await response.json();
                    // console.log(`THIS IS THE USER DATA RETURN: ${JSON.stringify(data)}`)
                    if (data.role === "agent") {
                        setAgentData(data.agentData);
                    }
                    setRole(data.role)
                    setUser(data);
                } else {
                    throw new Error('Failed to fetch');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        const fetchBuyerProfiles = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-buyer-profiles`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include', // Needed to send the cookie with the request
                });
                if (response.ok) {
                    const data = await response.json();
                    // console.log(`This is the Buyer Profile Data: ${data}`)
                    setBuyerProfiles(data);
                } else {
                    throw new Error('Failed to fetch or No Buyer Profiles');
                }
            } catch (error) {
                console.error('Error fetching listings:', error);
            }
        };

        const fetchListings = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user-listings`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include', // Needed to send the cookie with the request
                });
                if (response.ok) {
                    const data = await response.json();
                    setListings(data);
                } else {
                    throw new Error('Failed to fetch or No Listings');
                }
            } catch (error) {
                console.error('Error fetching listings:', error);
            }
        };

        fetchListings();
        fetchUserProfile();
        fetchBuyerProfiles();
    }, []);

    const handleAddListing = () => {
        navigate('/seller-setup');
    };

    const handleAddBuyer = () => {
        navigate('/buyer-setup');
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'Unknown'; // Handle undefined or null values
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
    };

    // Functions for Modal
    const handleBuyerClick = (buyerProfile) => {
        setSelectedBuyerProfile(buyerProfile);
    };

    const closeBuyerProfileModal = () => {
        setSelectedBuyerProfile(null);
    };

    const handlePageTransition = () => {
        setSelectedListing(null)
        setPageTransition(true)
    }
    // console.log(`THIS IS THE LISTING OUTPUT ${JSON.stringify(listings)}`)
    return (
        <>
            <div className='dashboard-info-container'>
                <div className='dashboard-image-container' onClick={(e) => window.location.href='/settings'}>
                    { !agentData === null ? (
                        <>
                            { agentData.valdiated === true ? (
                                <>
                                    <AgentIcon className='agent-icon'/>
                                </>
                                ) : (<></>)}
                        </>
                    ) : (<></>)
                    }
                    <img 
                        className='dashboard-img' 
                        alt='Profile' 
                        src={user.profilePicture || Pfp} 
                        // onLoad={() => console.log('Image loaded successfully')}
                        onError={(e) => {
                            e.target.onerror = null; 
                            e.target.src = Pfp;}}
                    />
                </div>
                <h1 className='dashboard-name' onClick={(e) => window.location.href='/settings'}>{user.displayName || 'User Name'}</h1>
                <p className='dashboard-name-subtext'>User Since {user.createdAt ? formatDate(user.createdAt) : 'Jan 24, 2024'}</p>
            </div>
            <div className='dashboard-buyer-container'>
                <h1 className='dashboard-title'>Buyer Preferences</h1>
                <div className='listings-container'>
                    { buyerProfiles.length > 0 ? 
                        ( buyerProfiles.map(buyerProfile => (
                            <div key={buyerProfile.id} className='buyer-list-view-container' onClick={() => handleBuyerClick(buyerProfile)}>
                                <div className='buyer-details-container'>
                                    <h1 className='buyer-approx-address'>{buyerProfile.city}, {buyerProfile.state} {buyerProfile.zipcode}</h1>
                                    <div className='property-swipe-icons-container'>
                                        <BedIconBlack className='swipe-bed-icon' />
                                        <p className='swipe-icon-details'>{buyerProfile.bedrooms}</p>
                                        <ShowerIcon className='swipe-shower-icon' />
                                        <p className='swipe-icon-details'>{buyerProfile.bathrooms}</p>
                                        <SqftIconBlack className='swipe-sqft-icon' />
                                        <p className='swipe-sqft-details'>{buyerProfile.min_sqft} - {buyerProfile.max_sqft === 0 ? 'No Max sqft' : buyerProfile.max_sqft + ' sqft'}</p>
                                    </div>
                                </div>
                                <div className='buyer-details-alt-container'>
                                    <div className='buyer-details-budget-container'>
                                        <p className='buyer-details-price'>Budget</p>
                                        <p className='buyer-details-price'>Min: {buyerProfile.min_budget === 0 ? 'No Min' : `$ ${buyerProfile.min_budget}`}</p>
                                        <p className='buyer-details-price'>Max: {buyerProfile.max_budget === 0 ? 'No Max' : `$ ${buyerProfile.max_budget}`}</p>
                                    </div>
                                    <ProfileArrow className='profile-arrow' id='buyer-arrow'/>
                                </div>
                            </div>
                        ))
                    ) : ( 
                        <></>
                    )}
                    {buyerProfiles.length > 0 ? (
                        <button className='edit-buyer-btn' onClick={handleAddBuyer}>
                            {role === 'agent' ? 'Add Another Buyer Listing' : 'Looking for Another Home?'}
                        </button>
                    ):(
                        <button className='edit-buyer-btn' onClick={handleAddBuyer}>
                            {role === 'agent' ? '+ Add a Buyer Listing' : 'Looking to Buy a Home?'}
                        </button>
                    )}
                </div>
            </div>
            <div className='dashboard-listing-container'>
                <h1 className='dashboard-title'>Listings</h1>
                <div className='listings-container'>
                    { listings.length > 0 ? 
                        ( listings.map(listing => (
                            <div key={listing.property_id} className='listing-list-view-container' onClick={() => setSelectedListing(listing)}>
                                <div className='listing-img-container'>
                                    <img className='listing-img' src={listing.coverPhoto || home2} alt='Home' />
                                </div>
                                <div className='listing-details-container'>
                                    <h1 className='property-approx-address'>{listing.property_address}</h1>
                                    <h1 id='approx-address-city' className='property-approx-address'>{listing.city}, {listing.state} {listing.zipcode}</h1>
                                    <div className='property-swipe-icons-container'>
                                        <BedIconBlack className='swipe-bed-icon' />
                                        <p className='swipe-icon-details'>{listing.bedrooms}</p>
                                        <ShowerIcon className='swipe-shower-icon' />
                                        <p className='swipe-icon-details'>{listing.bathrooms}</p>
                                        <SqftIconBlack className='swipe-sqft-icon' />
                                        <p className='swipe-sqft-details'>{listing.sqft} sqft</p>
                                    </div>
                                </div>
                                <div className='listing-details-price-container'>
                                    <p className='listing-details-price'>${listing.asking_price}</p>
                                </div>
                                <ProfileArrow className='profile-arrow' />
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                    {listings.length > 0 ? (
                        <button className='edit-buyer-btn' onClick={handleAddListing}>
                            + Add Another Listing
                        </button>
                    ):(
                        <button className='edit-buyer-btn' onClick={handleAddListing}>
                            + Add a Listing 
                        </button>
                    )}
                </div>
            </div>
            {selectedBuyerProfile && (
                <BuyerProfileModal buyerProfile={selectedBuyerProfile} onClose={closeBuyerProfileModal} />
            )}
            {selectedListing && (
                <ListingDetailsModal 
                    listing={selectedListing} 
                    onClose={() => handlePageTransition()} // Clear selected listing on close
                />
            )}
        </>
    );
}
