import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ChatBackArrow, PreApprovedIcon, AgentIcon, SingleFamilyIcon, CondoIcon, ApartmentIcon, TownhouseIcon } from '../../assets/icons/icons';
import './style.css';
import defaultProfileImg from '../../assets/images/default-profile.png';

export default function BuyerModal({ onClose, recipientId }) {
    const [pageTransition, setPageTransition] = useState(false);
    const [buyerDetails, setBuyerDetails] = useState(null);
    const [lotSizeMin, setLotSizeMin] = useState(null);
    const [lotSizeMax, setLotSizeMax] = useState(null);

    useEffect(() => {
        const fetchBuyerDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/buyer-details`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ userId: recipientId }),
                });
                if (response.ok) {
                    const data = await response.json();
                    // console.log(data);
                    setBuyerDetails(data);
                    setLotSizeMin(() =>{
                        switch (data.min_lot_size) {
                            case 0:
                                return 'No Min';
                            case 10890:
                                return '1/4 acre';
                            case 21780:
                                return '1/2 acre';
                            case 43560:
                                return '1 acre';
                            case 87120:
                                return '2 acres';
                            case 217800:
                                return '5 acres';
                            case 435600:
                                return '10 acres';
                            case 871200:
                                return '20 acres';
                            case 2178000:
                                return '50 acres';
                            default:
                                return ;
                            }
                    })
                    setLotSizeMax(() =>{
                        switch (data.max_lot_size) {
                            case null:
                                return '';
                            case 0:
                                return 'No Max';
                            case 10890:
                                return '1/4 acre';
                            case 21780:
                                return '1/2 acre';
                            case 43560:
                                return '1 acre';
                            case 87120:
                                return '2 acres';
                            case 217800:
                                return '5 acres';
                            case 435600:
                                return '10 acres';
                            case 871200:
                                return '20 acres';
                            case 2178000:
                                return '50+ acres';
                            default:
                                return ;
                            }
                    })
                } else {
                    throw new Error('Failed to fetch buyer details');
                }
            } catch (error) {
                console.error('Error fetching buyer details:', error);
            }
        };
        fetchBuyerDetails();
    }, [recipientId]);

    // Function to format numbers with commas and add "+" if it's the maximum value
    const formatNumber = (value, max = false, suffix = '') => {
        const formattedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return max && value >= 20000000 ? `$${formattedValue}+` : `$${formattedValue}${suffix}`;
    };

    const handlePageTransition = () => {
        setPageTransition(true);
        setTimeout(() => {
            onClose();
        }, 500);
    };

    if (!buyerDetails) {
        return <></>;
    }

    const { displayName, profilePicture, role, pre_approved, credential_number, brokerage, name } = buyerDetails;

    return createPortal(
        <div className={`match-details-modal-overlay ${pageTransition ? 'leftClose' : 'left'}`}>
            <div className='chatwindow-bg'>
                <div className='chat-window-container'>
                    <div className='chatwindow-hd'>
                        <ChatBackArrow className='chatwindow-back' onClick={handlePageTransition} />
                        <div className='chatwindow-userinfo'>
                            <div className='match-details-image-container'>
                                {role === 'agent' && <AgentIcon className='agent-icon' />}
                                <img src={profilePicture || defaultProfileImg} className='match-img' alt='chat-img' />
                            </div>
                            <h1 className='match-detials-name'>
                                {role === 'agent' ? name : displayName.split(' ')[0]}
                                <span className='match-details-approve-icon'>
                                    {pre_approved ? <PreApprovedIcon className='pre-approved-icon' /> : <></>}
                                </span>
                            </h1>
                        </div>
                    </div>
                    <div className='buyer-modal-content'>
                        <p className='modal-section-header'>Property Types</p>
                        <div className="profile-section-divider"></div>
                        <div className='buyer-modal-title'> 
                            <div className='buyer-modal-bubble-container'>
                            {JSON.parse(buyerDetails.property_type).map((term, index) => (
                                <span key={index} className='buyer-modal-boxes active'>
                                    {(() => {
                                        switch (term) {
                                            case 'Single Family':
                                                return (
                                                    <>
                                                        <SingleFamilyIcon />
                                                        <span className='setup-page3-boxes-title'>Single Family</span>
                                                    </>
                                                )
                                            case 'Condo':
                                                return (
                                                    <>
                                                        <CondoIcon />
                                                        <span className='setup-page3-boxes-title'>Condo</span>
                                                    </>
                                                )
                                            case 'Apartment':
                                                return (
                                                    <>
                                                        <ApartmentIcon />
                                                        <span className='setup-page3-boxes-title'>Apartment</span>
                                                    </>
                                                )
                                            case 'TownHome':
                                                return (
                                                    <>
                                                        <TownhouseIcon />
                                                        <span className='setup-page3-boxes-title'>TownHome</span>
                                                    </>
                                                )
                                            default:
                                                return null; 
                                        }
                                    })()}
                                </span>
                            ))}
                            </div>
                        </div>
                        <br />
                        <p className='modal-section-header'>Location</p>
                        <div className="profile-section-divider"></div>
                        <p className='buyer-modal-title'>City: <span className='buyer-modal-text'>{buyerDetails.city}</span></p>
                        <p className='buyer-modal-title'>State: <span className='buyer-modal-text'>{buyerDetails.state}</span></p>
                        <p className='buyer-modal-title'>ZIP Code: <span className='buyer-modal-text'>{buyerDetails.zipcode}</span></p>
                        <br />
                        <p className='modal-section-header'>Buying or Renting</p>
                        <div className="profile-section-divider"></div>
                        <p className='buyer-modal-title'><span className='term-bubble'>{buyerDetails.listing_status === 'for_sale' ? 'Buying' : 'Renting'}</span></p>
                        <br />
                        <p className='modal-section-header'>Home Criteria</p>
                        <div className="profile-section-divider"></div>
                        <p className='buyer-modal-title'>Bedrooms: <span className='buyer-modal-text'>{buyerDetails.bedrooms}+</span></p>
                        <p className='buyer-modal-title'>Bathrooms: <span className='buyer-modal-text'>{buyerDetails.bathrooms}+</span></p>
                        <p className='buyer-modal-title'>Min Square Footage: <span className='buyer-modal-text'>{buyerDetails.min_sqft} sqft</span></p>
                        <p className='buyer-modal-title'>Max Square Footage: <span className='buyer-modal-text'>{buyerDetails.max_sqft} sqft</span></p>
                        <br />
                        <p className='modal-section-header'>Home Criteria cont.</p>
                        <div className="profile-section-divider"></div>
                        <p className='buyer-modal-title'>Den: <span className='buyer-modal-text'>{buyerDetails.den === 0 ? 'any' : buyerDetails.den}+</span></p>
                        <p className='buyer-modal-title'>Stories/Levels: <span className='buyer-modal-text'>{buyerDetails.stories_level === 0 ? 'any' : buyerDetails.stories_level}+</span></p>
                        <p className='buyer-modal-title'>Garage: <span className='buyer-modal-text'>{buyerDetails.garage === 'any' ? 'Any' : buyerDetails.garage === 'yes' ? 'Yes' : 'No'}</span></p>
                        {buyerDetails.garage === 'yes' && <p className='buyer-modal-title'>Garage Spaces: <span className='buyer-modal-text'>{buyerDetails.garage_spaces}+</span></p>}
                        <br />
                        <p className='modal-section-header'>Property Features</p>
                        <div className="profile-section-divider"></div>
                        <p className='buyer-modal-title'>Min Lot Size: <span className='buyer-modal-text'>{lotSizeMin}</span></p>
                        <p className='buyer-modal-title'>Max Lot Size: <span className='buyer-modal-text'>{lotSizeMax}</span></p>
                        <p className='buyer-modal-title'>Pool: <span className='buyer-modal-text'>{buyerDetails.pool === 'any' ? 'Any' : buyerDetails.pool === 'yes' ? 'Yes' : 'No'}</span></p>
                        <p className='buyer-modal-title'>Waterfront: <span className='buyer-modal-text'>{buyerDetails.waterfront === 'any' ? 'Any' : buyerDetails.waterfront === 'yes' ? 'Yes' : 'No'}</span></p>
                        <p className='buyer-modal-title'>Gulf Access: <span className='buyer-modal-text'>{buyerDetails.gulf_access === 'any' ? 'Any' : buyerDetails.gulf_access === 'yes' ? 'Yes' : 'No'}</span></p>
                        <p className='buyer-modal-title'>Spa: <span className='buyer-modal-text'>{buyerDetails.spa === 'any' ? 'Any' : buyerDetails.spa === 'yes' ? 'Yes' : 'No'}</span></p>
                        <p className='buyer-modal-title'>Min Year: <span className='buyer-modal-text'>{buyerDetails.min_year}</span></p>
                        <p className='buyer-modal-title'>Max Year: <span className='buyer-modal-text'>{buyerDetails.max_year}</span></p>
                        <br />
                        <p className='modal-section-header'>Property Features cont.</p>
                        <div className="profile-section-divider"></div>
                        <div className='buyer-modal-title'>Exterior Features: 
                            <div className='buyer-modal-bubble-container'>
                                {   buyerDetails.exterior_features === null ? '' : 
                                    JSON.parse(buyerDetails.exterior_features).map((term, index) => (
                                    <span key={index} className='term-bubble'>
                                        {term}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <div className='buyer-modal-title'>Interior Features: 
                            <div className='buyer-modal-bubble-container'>
                                {   buyerDetails.interior_features === null ? '' : 
                                    JSON.parse(buyerDetails.interior_features).map((term, index) => (
                                    <span key={index} className='term-bubble'>
                                        {term}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <div className='buyer-modal-title'>Flooring: 
                            <div className='buyer-modal-bubble-container'>
                                {   buyerDetails.flooring === null ? '' : 
                                    JSON.parse(buyerDetails.flooring).map((term, index) => (
                                    <span key={index} className='term-bubble'>
                                        {term}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <div className='buyer-modal-title'>Appliances: 
                            <div className='buyer-modal-bubble-container'>
                                {   buyerDetails.appliances === null ? '' : 
                                    JSON.parse(buyerDetails.appliances).map((term, index) => (
                                    <span key={index} className='term-bubble'>
                                        {term}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <div className='buyer-modal-title'>View: 
                            <div className='buyer-modal-bubble-container'>
                                {   buyerDetails.view === null ? '' : 
                                    JSON.parse(buyerDetails.view).map((term, index) => (
                                    <span key={index} className='term-bubble'>
                                        {term}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <br />
                        <p className='modal-section-header'>Home Owners Association</p>
                        <div className="profile-section-divider"></div>
                        <p className='buyer-modal-title'>HOA: <span className='buyer-modal-text'>{buyerDetails.hoa === 'any' ? 'Any' : buyerDetails.hoa === 'yes' ? 'Yes' : 'No'}</span></p>
                        {   buyerDetails.hoa === 'yes' && (
                            <>
                                <p className='buyer-modal-title'>Max HOA Fee(s): <span className='buyer-modal-text'>${buyerDetails.max_hoa}</span></p>
                                <p className='buyer-modal-title'>Fee Frequency: <span className='buyer-modal-text'>${buyerDetails.fee_frequency}</span></p>
                            </>
                        )} 
                        {   buyerDetails.hoa === 'yes' && 
                            <div className='buyer-modal-title'>HOA Amenities: 
                                <div className='buyer-modal-bubble-container'>
                                    {   buyerDetails.hoa_amenities === null ? '' : 
                                        JSON.parse(buyerDetails.hoa_amenities).map((term, index) => (
                                        <span key={index} className='term-bubble'>
                                            {term}
                                        </span>
                                ))}
                                </div>
                            </div>
                        }
                        <br />
                        <p className='modal-section-header'>Budget and Financing</p>
                        <div className="profile-section-divider"></div>
                        <p className='buyer-modal-title'>Min Budget: <span className='buyer-modal-text'>{formatNumber(buyerDetails.min_budget)}</span></p>
                        <p className='buyer-modal-title'>Max Budget: <span className='buyer-modal-text'>{formatNumber(buyerDetails.max_budget)}</span></p>
                        <div className='buyer-modal-title'>Purchase Terms: 
                            <div className='buyer-modal-bubble-container'>
                                {   buyerDetails.purchase_terms === null ? '' : 
                                    JSON.parse(buyerDetails.purchase_terms).map((term, index) => (
                                    <span key={index} className='term-bubble'>
                                        {term}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <p className='buyer-modal-title'>Pre-Approved: <span className='buyer-modal-text'>{buyerDetails.pre_approved ? 'Yes' : 'No'}</span></p>
                        <p className='buyer-modal-title'>Proof of Funds: <span className='buyer-modal-text'>{buyerDetails.proof_of_funds ? 'Yes' : 'No'}</span></p>
                        <br />
                        <p className='modal-section-header'>Preferences</p>
                        <div className="profile-section-divider"></div>
                        <p className='buyer-modal-title'>Move-in Timeline: <span className='buyer-modal-text'>{buyerDetails.move_in_timeline}</span></p>
                        <p className='buyer-modal-title'>Other Preferences: <span className='buyer-modal-text'><br />{buyerDetails.other_preferences}</span></p>
                        {role === 'agent' && (
                            <>  
                                <div className='agent-info-container'>
                                    <h2 className='buyer-modal-title'>This user is an Agent</h2>
                                    <div className='transaction-container'>
                                        <p className='agent-info-title'>Agent Name: <span className='agent-info-text'>{name}</span></p>
                                        <p className='agent-info-title'>License Number: <span className='agent-info-text'>{credential_number}</span></p>
                                        <p className='agent-info-title'>Brokerage: <span className='agent-info-text'>{brokerage}</span></p>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById('modal-root')
    );
}
