import React, { useEffect, useState } from 'react';

export default function PropertyMatches(props) {
    const [matches, setMatches] = useState([]);

    useEffect(() => {
        fetchMatches();
    }, []);

    const fetchMatches = async () => {
        // Fetch request to backend to get matches based on the seller's last submission
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/matches`); // Adjust this endpoint as necessary
            const data = await response.json();
            if (response.ok) {
                setMatches(data.matches);
            } else {
                throw new Error('Failed to fetch matches');
            }
        } catch (error) {
            console.error('Error fetching matches:', error);
        }
    };

    return (
        <>
            <h1>Buyer Matches</h1>
            <ul>
                {matches.length > 0 ? matches.map(match => (
                    <li key={match.id}>
                        {match.city}, {match.state} - Budget: {match.min_budget} to {match.max_budget}
                    </li>
                )) : <p>No matches found.</p>}
            </ul>
        </>
    );
}