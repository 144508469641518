import React, {useEffect, useState} from 'react';
// import { HomeIcon, HomeIconFilled, ChatIcon, ChatIconFilled, NavBarProfileIcon, NavBarProfileIconFilled, DiscoverIcon, DiscoverIconFilled } from '../../assets/icons/icons';
import { HomeIcon, HomeIconFilled, ChatIcon, ChatIconFilled, NavBarProfileIcon, NavBarProfileIconFilled } from '../../assets/icons/icons';
import './style.css'

export default function NavHub(props) {
   
    let [ Home, setHome ] = useState(false)
    // let [ Discover, setDiscover ] = useState(false)
    let [ Chat, setChat ] = useState(false)
    let [ Profile, setProfile ] = useState(false)

    // useEffect (() => { 
    //     if ( window.location.pathname==='/' ) {
    //         setHome(true)
    //     } else if ( window.location.pathname==='/chat') {
    //         setChat(true)
    //     } else if ( window.location.pathname==='/profile') {
    //         setProfile(true)
    //     } else if ( window.location.pathname==='/discover') {
    //         setDiscover(true)
    //     }
    // }, [])
    useEffect (() => { 
        if ( window.location.pathname==='/dashboard' ) {
            setHome(true)
        } else if ( window.location.pathname==='/chat') {
            setChat(true)
        } else if ( window.location.pathname==='/settings') {
            setProfile(true)
        }
    }, [])

    return (
        <div className='navhub-container'>
            <div className='navhub-icon' onClick={event => window.location.href='/dashboard'}>
                {Home ?  <HomeIconFilled className='navhub-home-icon-filled' onClick={event => window.location.href='/dashboard'}/> : <HomeIcon className='navhub-home-icon' onClick={event => window.location.href='/dashboard'}/>}
            </div>
            {/* {Discover ? <DiscoverIconFilled className='' fill="6246EA" stroke="6246EA" onClick={event => window.location.href='/'}/> : <DiscoverIcon className='' fill="none" onClick={event => window.location.href='/discover'}/>} */}
            <div className='navhub-icon' onClick={event => window.location.href='/chat'}>
                {Chat ? <ChatIconFilled className='navhub-chat-icon-filled' onClick={event => window.location.href='/chat'}/> : <ChatIcon className='navhub-chat-icon' onClick={event => window.location.href='/chat'}/>}
            </div>
            {/* Change /profile path to username or first and last name later from db */}
            {/* {Profile ? <NavBarProfileIconFilled className='navhub-profile-icon-filled' onClick={event => window.location.href='/profile'}/> : <NavBarProfileIcon className='navhub-profile-icon' onClick={event => window.location.href='/profile'}/>} */}
            <div className='navhub-icon' onClick={event => window.location.href='/settings'}>
                {Profile ? <NavBarProfileIconFilled className='navhub-profile-icon-filled' onClick={event => window.location.href='/settings'}/> : <NavBarProfileIcon className='navhub-profile-icon' onClick={event => window.location.href='/settings'}/>}
            </div>
        </div>
        // Need to add dot logic for active pages
    );
}