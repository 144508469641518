import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackIcon, ChatBackArrow, PreApprovedIcon, ProfileArrow, SetupPage2Image } from '../../assets/icons/icons';
// import premiumImage from '../../assets/images/premiumImage.png';
import './style.css';

export default function PaymentSelectionSeller(props) {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('seller');

    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const handleSubmit = () => {
        // console.log(selectedOption)
        navigate(`/payment-${selectedOption}`)
    }

    return (
        <div className='payment-content'>
            <div className='main-content'>
                <div className="payment-container">
                    <header className="header">
                        <ChatBackArrow className="chatwindow-back" onClick={handleBackClick} />
                        <h1 className="header-title">Listing Pricing</h1>
                    </header>
                    {/* <img src={premiumImage} alt="Premium" className="premium-image" /> */}
                    <SetupPage2Image className='premium-image'/>
                    <div className="premium-description">
                        { selectedOption === 'seller' ? (
                            <>
                                <h2 className="premium-title">Havyn Basic Listing</h2>
                                <div className='premium-info'>
                                    <p className='premium-price' id='bold'>$99.99</p>
                                    <span className='premium-price-divider'>|</span>
                                    <div className='premium-info-stack'>
                                        <p className='premium-subtext' id='bold'>12</p>
                                        <p className='premium-subtext'>MONTHS</p>
                                    </div>
                                </div>
                                <div className='premium-highlights'>
                                    <p className="premium-text">Find Ideal Buyers For Your Listing</p>
                                    <div className='premium-divider'></div>
                                    <p className="premium-text">Access to Message Matched Buyers Directly</p>
                                    <div className='premium-divider'></div>
                                    <p className="premium-text">Fast, Easy, and Efficent</p>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="purchase-button" onClick={() => handleSubmit(selectedOption)}>
                        <button className="purchase-button-text">BUY NOW</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
