import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './style.css';
import { BedIconBlack, ShowerIcon, SqftIconBlack, ChatBackArrow, PreApprovedIcon, AgentIcon, ReportIcon, SingleFamilyIcon, ApartmentIcon, CondoIcon, TownhouseIcon } from '../../assets/icons/icons';
// Google Maps API
import MapWithSchools from '../MapWithSchools.js/MapWithSchools';
import ImageGallery from '../ImageGallery/ImageGallery';

export default function ListingModal({ onClose, chatAddress, chatZipcode, isPreApproved, agentValidated }) {
    const [pageTransition, setPageTransition] = useState(false);
    const [listingDetails, setListingDetails] = useState(null);

    const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        // console.log(`THIS IS THE PASSED IN INQUIRY ADDRESS: ${chatAddress + chatZipcode}`);
        const fetchListingDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/listing-details`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ property_address: chatAddress, zipcode: chatZipcode }),
                });
                if (response.ok) {
                    const data = await response.json();
                    // console.log(`This is the listingDetails: ${JSON.stringify(data)}`);
                    // setListingDetails(data);
                    const parsedListing = {
                        ...data,
                        view: JSON.parse(data.view || "[]"),
                        exterior_features: JSON.parse(data.exterior_features || "[]"),
                        interior_features: JSON.parse(data.interior_features || "[]"),
                        flooring: JSON.parse(data.flooring || "[]"),
                        appliances: JSON.parse(data.appliances || "[]"),
                        hoa_amenities: JSON.parse(data.hoa_amenities || "[]"),
                        kitchen: JSON.parse(data.kitchen || "[]"),
                        utilities: JSON.parse(data.utilities || "[]"),
                        local_amenities: JSON.parse(data.local_amenities || "[]"),
                        purchase_terms: JSON.parse(data.purchase_terms || "[]"),
                    };
                    setListingDetails(parsedListing);
                } else {
                    throw new Error('Failed to fetch listing details');
                }
            } catch (error) {
                console.error('Error fetching listing details:', error);
            }
        };
        fetchListingDetails();
    }, [chatAddress, chatZipcode]);

    const handlePageTransition = () => {
        setPageTransition(true);
        setTimeout(() => {
            onClose();
        }, 500);
    };

    if (!listingDetails) {
        return <></>;
    }

    const { asking_price, property_address, city, state, zipcode, bedrooms, bathrooms, sqft, unique_features, cover_photo, images, role, credential_number, brokerage, name, validated, description } = listingDetails;

    const parsedImages = images ? JSON.parse(images) : [];

    // Function to format prices
    const formatNumber = (value) => {
        const formattedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${formattedValue}`;
    };

    // Function to format phone numbers
    const formatPhoneNumber = (value) => {
        const cleaned = ('' + value).replace(/\D/g, ''); // Remove all non-digit characters
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return value; // Return the original value if it doesn't match the expected format
    };

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setIsImageGalleryOpen(true);
    };

    return createPortal(
        <div className={`listing-details-modal ${pageTransition ? 'leftClose' : 'left'}`}>
            <div className='main-section'>
                <div className='main-listing-content'>
                    <div className='listing-details-arrow-container'>
                        <ChatBackArrow className='chatwindow-back' onClick={handlePageTransition} />
                        <h1 className='modal-header'>${asking_price.toLocaleString()}</h1>
                        <ReportIcon className='report-icon'/>
                    </div>
                    <div className='listing-details-content'>
                        <div className='listing-info-container'>
                            <div className='listing-details-image-carousel-container'>
                                <Carousel>
                                    {parsedImages.map((image, index) => (
                                        <div key={index} className='uploaded-image-wrapper' onClick={() => handleImageClick(index)}>
                                            <img src={image} alt={`Uploaded ${index}`} className='uploaded-image' />
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                            {isImageGalleryOpen && createPortal(
                                <ImageGallery
                                    images={parsedImages}
                                    startIndex={currentImageIndex}
                                    onClose={() => setIsImageGalleryOpen(false)}
                                />,
                                document.getElementById('modal-root')
                            )}
                            <div className='listing-details-container-modal'>
                                <div className='description-title-container'>
                                    <p className='listing-details-address'>{property_address},</p>
                                    <p className='listing-details-address-alt'>{city}, {state} {zipcode}</p>
                                </div>
                                <div className='property-swipe-icons-container'>
                                    <BedIconBlack className='swipe-bed-icon-pd' />
                                    <div className='property-details-icon-container'>
                                        <p className='details-icon-title'>Bedrooms</p>
                                        <p className='details-icon-details'>{bedrooms} Rooms</p>
                                    </div>
                                    <ShowerIcon className='swipe-shower-icon-pd' fill='#000000' />
                                    <div className='property-details-icon-container'>
                                        <p className='details-icon-title'>Bathrooms</p>
                                        <p className='details-icon-details'>{bathrooms + listingDetails.bathrooms_half} Rooms</p>
                                    </div>
                                    <SqftIconBlack className='swipe-sqft-icon-pd' />
                                    <div className='property-details-icon-container'>
                                        <p className='details-icon-title'>Square Footage</p>
                                        <p className='details-icon-details'>{sqft} sqft</p>
                                    </div>
                                </div>
                                <br />
                                <p className='modal-section-header'>Home Highlights</p>
                                <div className="profile-section-divider"></div>
                                <br></br>
                                <div className='buyer-modal-boxes active'>
                                    {(() => {
                                        switch (listingDetails.property_type) {
                                            case 'Single Family':
                                                return (
                                                    <>
                                                        <SingleFamilyIcon />
                                                        <span className='setup-page3-boxes-title'>Single Family</span>
                                                    </>
                                                )
                                            case 'Condo':
                                                return (
                                                    <>
                                                        <CondoIcon />
                                                        <span className='setup-page3-boxes-title'>Condo</span>
                                                    </>
                                                )
                                            case 'Apartment':
                                                return (
                                                    <>
                                                        <ApartmentIcon />
                                                        <span className='setup-page3-boxes-title'>Apartment</span>
                                                    </>
                                                )
                                            case 'TownHome':
                                                return (
                                                    <>
                                                        <TownhouseIcon />
                                                        <span className='setup-page3-boxes-title'>TownHome</span>
                                                    </>
                                                )
                                            default:
                                                return null; 
                                        }
                                    })()}
                                </div>
                                { listingDetails.fsbo === true && (
                                    <>
                                        <p className='buyer-modal-title'>FSBO <span className='buyer-modal-text'>{!listingDetails.fsbo ? 'No' : 'Yes'}</span></p>
                                    </>
                                )}
                                { listingDetails.bathrooms_half > 0 && (
                                    <>
                                        <p className='buyer-modal-title'>Half Bathrooms: <span className='buyer-modal-text'>{listingDetails.bathrooms_half}</span></p>
                                    </>
                                )}
                                <p className='buyer-modal-title'>Year Built: <span className='buyer-modal-text'>{listingDetails.year_built}</span></p>
                                <p className='buyer-modal-title'>Lot Size: <span className='buyer-modal-text'>{listingDetails.lot_size} {listingDetails.lot_size > 1000 ? 'sqft lot' : 'acre lot'}</span></p>
                                <p className='buyer-modal-title'>HOA: <span className='buyer-modal-text'>{!listingDetails.hoa ? 'No' : 'Yes'}</span></p>
                                <p className='buyer-modal-title'>Home Type: <span className='buyer-modal-text'>{listingDetails.property_type}</span></p>
                                { listingDetails.den > 0 && (
                                    <>
                                        <p className='buyer-modal-title'>Den: <span className='buyer-modal-text'>{listingDetails.den}</span></p>
                                    </>
                                )}
                                <p className='buyer-modal-title'>Move Out Timeframe: <span className='buyer-modal-text'>{listingDetails.move_out_timeline}</span></p>
                                <p className='buyer-modal-title'>Living Square Footage: <span className='buyer-modal-text'>{listingDetails.sqft_living} sqft</span></p>
                                <br />
                                <p className='modal-section-header'>Details</p>
                                <div className="profile-section-divider"></div>
                                <p className='buyer-modal-title'><span className='buyer-modal-text'>{!listingDetails.description ? 'null' : listingDetails.description}</span></p>
                                <p className='buyer-modal-title'>Lot Description: </p>
                                <br />
                                <p className='modal-section-header'>Features</p>
                                <div className="profile-section-divider"></div>
                                <p className='buyer-modal-title'>Stories/Levels: <span className='buyer-modal-text'>{listingDetails.stories_level}</span></p>
                                { !listingDetails.garage ? (
                                    <>
                                        <p className='buyer-modal-title'>Garage: <span className='buyer-modal-text'>No</span></p>
                                    </>
                                ) : (
                                    <>
                                        <p className='buyer-modal-title'>Garage: <span className='buyer-modal-text'>Yes</span></p> 
                                        <p className='buyer-modal-title'>Attached or Detached: <span className='buyer-modal-text'>{!listingDetails.attached_garage ? 'Detached' : 'Attached'}</span></p>
                                        <p className='buyer-modal-title'>Garage Spaces: <span className='buyer-modal-text'>{listingDetails.garage_spaces}</span></p>
                                        { !listingDetails.carport ? (
                                            <>

                                            </>
                                        ) : (
                                            <>
                                                <p className='buyer-modal-title'>Carport: <span className='buyer-modal-text'>{listingDetails.carport}</span></p>
                                                <p className='buyer-modal-title'>Carport Spaces: <span className='buyer-modal-text'>{listingDetails.carport_spaces}</span></p>
                                            </>
                                        )}
                                    </>
                                )}
                                <p className='buyer-modal-title'>Pool: <span className='buyer-modal-text'>{listingDetails.pool  ? 'Yes' : 'No'}</span></p>
                                { listingDetails.pool === 1 && (
                                    <>
                                        <span className='buyer-modal-text'>{listingDetails.pool_description}</span>
                                    </>
                                )}
                                <p className='buyer-modal-title'>Spa: <span className='buyer-modal-text'>{listingDetails.spa ? 'Yes' : 'No'}</span></p>
                                { listingDetails.spa === 1 && (
                                    <>
                                        <span className='buyer-modal-text'>{listingDetails.spa_description}</span>
                                    </>
                                )}
                                <p className='buyer-modal-title'>Waterfront: <span className='buyer-modal-text'>{listingDetails.waterfront ? 'Yes' : 'No'}</span></p>
                                { listingDetails.waterfront === 1 && (
                                    <>
                                        <span className='buyer-modal-text'>{listingDetails.waterfront_description}</span>
                                    </>
                                )}
                                <p className='buyer-modal-title'>Gulf Access: <span className='buyer-modal-text'>{listingDetails.gulf_access === 'any' ? 'Any' : listingDetails.gulf_access === 'yes' ? 'Yes' : 'No'}</span></p>
                                <br />
                                <p className='modal-section-header'>Listed Features </p>
                                <div className="profile-section-divider"></div>
                                <div className='buyer-modal-title'>Exterior Features: 
                                    <div className='buyer-modal-bubble-container'>
                                        {listingDetails.exterior_features.map((term, index) => (
                                            <span key={index} className='term-bubble'>
                                                {term}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className='buyer-modal-title'>Interior Features: 
                                    <div className='buyer-modal-bubble-container'>
                                        {listingDetails.interior_features.map((term, index) => (
                                            <span key={index} className='term-bubble'>
                                                {term}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className='buyer-modal-title'>Flooring: 
                                    <div className='buyer-modal-bubble-container'>
                                        {listingDetails.flooring.map((term, index) => (
                                            <span key={index} className='term-bubble'>
                                                {term}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className='buyer-modal-title'>Appliances: 
                                    <div className='buyer-modal-bubble-container'>
                                        {listingDetails.appliances.map((term, index) => (
                                            <span key={index} className='term-bubble'>
                                                {term}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className='buyer-modal-title'>View: 
                                    <div className='buyer-modal-bubble-container'>
                                        {listingDetails.view.map((term, index) => (
                                            <span key={index} className='term-bubble'>
                                                {term}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <br />
                                <p className='modal-section-header'>Location</p>
                                <div className="profile-section-divider"></div>
                                <MapWithSchools address={`${listingDetails.property_address}, ${listingDetails.city}, ${listingDetails.state}, ${listingDetails.zipcode}`} />
                                <br />
                                <p className='modal-section-header'>Neighborhood Information</p>
                                <div className="profile-section-divider"></div>
                                <p className='buyer-modal-title'>Subdivision: <span className='buyer-modal-text'>{listingDetails.subdivision ? 'Yes' : 'No'}</span></p>
                                { listingDetails.subdivision ? (
                                    <>
                                        <p className='buyer-modal-title'>Name: <span className='buyer-modal-text'>{listingDetails.subdivision_name}</span></p>
                                    </>
                                ) : (
                                    <>

                                    </>
                                )}
                                <p className='buyer-modal-title'>HOA: <span className='buyer-modal-text'>{listingDetails.hoa ? 'Yes' : 'No'}</span></p>
                                {   listingDetails.hoa ? (
                                        <>
                                            <p className='buyer-modal-title'>HOA Fee(s): <span className='buyer-modal-text'>${formatNumber(listingDetails.hoa_fee)}</span></p>
                                            <p className='buyer-modal-title'>Fee Frequency: <span className='buyer-modal-text'>{listingDetails.fee_frequency}</span></p>
                                            <p className='buyer-modal-title'>HOA Master Fee(s): <span className='buyer-modal-text'>${formatNumber(listingDetails.hoa_fee_master)}</span></p>
                                            {listingDetails.fee_frequency_master === '' ? (
                                                <></>
                                            ) : (
                                                <>
                                                    <p className='buyer-modal-title'>Master Fee Frequency: <span className='buyer-modal-text'>{listingDetails.fee_frequency_master}</span></p>
                                                </>
                                            )}

                                            <p className='buyer-modal-title'>Application Fee: <span className='buyer-modal-text'>${formatNumber(listingDetails.application_fee)}</span></p>
                                            <p className='buyer-modal-title'>Transfer Fee: <span className='buyer-modal-text'>${formatNumber(listingDetails.transfer_fee)}</span></p>
                                            <p className='buyer-modal-title'>Other Fee(s): <span className='buyer-modal-text'>{listingDetails.other_fee}</span></p>
                                            <div className='buyer-modal-title'>HOA Amenities: 
                                                <div className='buyer-modal-bubble-container'>
                                                    {listingDetails.hoa_amenities.map((term, index) => (
                                                    <span key={index} className='term-bubble'>
                                                        {term}
                                                    </span>
                                                ))}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>

                                        </>
                                    )
                                }
                                <br />
                                <p className='modal-section-header'>Legal and Compliance</p>
                                <div className="profile-section-divider"></div>
                                { listingDetails.listing_agent ? (
                                    <>
                                        <p className='buyer-modal-title'>Listing Agent Info: </p>
                                        <div className='listing-agent-container'>
                                            <div className='listing-agent-info-container'>
                                                <p className='agent-info-title'>Agent Name: <span className='agent-info-text'>{listingDetails.agent_name}</span></p>
                                                <p className='agent-info-title'>Phone Number: <span className='agent-info-text'>{formatPhoneNumber(listingDetails.agent_phone_number)}</span></p>
                                                <p className='agent-info-title'>Email: <span className='agent-info-text'>{listingDetails.agent_email}</span></p>
                                                <p className='agent-info-title'>Brokerage: <span className='agent-info-text'>{listingDetails.brokerage_name}</span></p>
                                            </div>
                                        </div>
                                        <p className='agent-setup-disclaimer'>Report an error? Please email: support@havyn.com</p>
                                    </>
                                ) : (
                                    <>

                                    </>
                                )}
                                <br />
                                <p className='modal-section-header'>Financial Information</p>
                                <div className="profile-section-divider"></div>
                                <div className='buyer-modal-title'>Purchase Terms: 
                                    <div className='buyer-modal-bubble-container'>
                                        {listingDetails.purchase_terms.map((term, index) => (
                                            <span key={index} className='term-bubble'>
                                                {term}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById('modal-root')
    );
}
