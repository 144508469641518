import React from 'react';
import './style.css';
import { HavynH } from '../../assets/icons/icons'



export default function Loading(props) {

  return(
    <>
      <div className='loading-container'>
        <HavynH className="loading-logo"/>
      </div>
    </>
  )
}